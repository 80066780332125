import { ROLE_ADMIN, ROLE_COMPANY, ROLE_RESTAURANT } from '@/enum/roles'
import routeType from './routeType'

import users from './routes/users'
import companies from './routes/companies'
import restaurants from './routes/restaurants'
import promotions from './routes/promotions'
import orders from './routes/orders'
import translations from './routes/translations'
import tags from './routes/tags'
import tagGroups from './routes/tagGroups'
import tablets from './routes/tablets'
import deliveries from './routes/deliveries'
import invoices from './routes/invoices'
import restaurantInvoices from './routes/restaurant_invoices'
import residentialCanteens from './routes/residential_canteens'
import customExport from './routes/customExport'
import customExportCategory from './routes/customExportCategory'
import coupons from './routes/coupons'
import registrationCodes from './routes/registration_codes'
import settings from './routes/settings'
import audit from './routes/audit'
import promoBanners from './routes/promo_banners'
import todos from './routes/todos'
import brands from './routes/brands'

const routes = [
    // HOME
    {
        path: '/',
        name: routeType.HOME,
        component: () => import(/* webpackChunkName: "home" */ '@/pages/Home'),
        meta: {
            requiresAuth: true,
            roles: [ROLE_ADMIN, ROLE_COMPANY, ROLE_RESTAURANT],
            layout: 'ordit-authenticated-layout',
        },
    },
    // USERS
    ...users,
    // COMPANIES
    ...companies,
    // PROMOTION
    ...promotions,
    // RESTAURANTS
    ...restaurants,
    // ORDERS
    ...orders,
    // TRANSLATIONS
    ...translations,
    // TAGS
    ...tags,
    // TAG GROUPS
    ...tagGroups,
    // TABLETS
    ...tablets,
    // DELIVERIES
    ...deliveries,
    // INVOICES
    ...invoices,
    // RESTAURANT INVOICES
    ...restaurantInvoices,
    // RESIDENTIAL CANTEENS
    ...residentialCanteens,
    // CUSTOM EXPORT
    ...customExport,
    // CUSTOM EXPORT CATEGORY
    ...customExportCategory,
    // COUPONS
    ...coupons,
    // REGISTRATION CODES
    ...registrationCodes,
    // PROMO BANNERS
    ...promoBanners,
    // SETTINGS
    ...settings,
    // AUDIT LOG
    ...audit,
    // TODOS
    ...todos,
    // BRANDS
    ...brands,
    // ALL CANTEENS
    {
        path: '/canteens',
        name: routeType.CANTEEN_LIST,
        component: () =>
            import(/* webpackChunkName: "canteens" */ '@/pages/canteens/List'),
        meta: {
            requiresAuth: true,
            roles: [ROLE_ADMIN],
            layout: 'ordit-authenticated-layout',
        },
    },
    // AUTH
    {
        path: '/login',
        name: routeType.LOGIN,
        component: () =>
            import(/* webpackChunkName: "auth" */ '@/pages/login/Login'),
        meta: {
            layout: 'ordit-layout',
        },
    },
    {
        path: '/login-token',
        name: routeType.LOGIN_TOKEN,
        component: () =>
            import(/* webpackChunkName: "auth" */ '@/pages/login/LoginToken'),
        meta: {
            layout: 'ordit-layout',
        },
    },
    {
        path: '/password-reset/request',
        name: routeType.PASSWORD_RESET_REQUEST,
        component: () =>
            import(
                /* webpackChunkName: "auth" */ '@/pages/login/PasswordResetRequest'
            ),
        meta: {
            layout: 'ordit-layout',
        },
    },
    {
        path: '/password-reset/:token',
        name: routeType.PASSWORD_RESET,
        component: () =>
            import(
                /* webpackChunkName: "auth" */ '@/pages/login/PasswordReset'
            ),
        meta: {
            layout: 'ordit-layout',
        },
    },
    {
        path: '/order-software-confirm/:id',
        redirect: { name: routeType.ORDER_CONFIRM },
    },
    {
        path: '/order-confirm/:id',
        name: routeType.ORDER_CONFIRM,
        component: () =>
            import(
                /* webpackChunkName: "confirm" */ '@/pages/restaurantAdmin/OrderConfirm'
            ),
        meta: {
            layout: 'ordit-layout',
        },
    },
    {
        path: '/cloudcanteen/label/view/:CcId/:restaurantId',
        name: routeType.RESTAURANT_CLOUDCANTEEN_PUBLIC_PRINT_LABEL,
        component: () =>
            import(
                /* webpackChunkName: "cc-publicprint" */ '@/pages/orders/CloudcanteenPublicPrint'
            ),
        meta: {
            layout: 'ordit-layout',
        },
    },
    {
        path: '/cloudcanteen/summary/:ccIdentifier',
        name: routeType.RESTAURANT_CLOUDCANTEEN_PUBLIC_PRINT_SUMMARY,
        component: () =>
            import(
                /* webpackChunkName: "cc-publicprint" */ '@/pages/orders/CloudcanteenPublicSummary'
            ),
        meta: {
            layout: 'ordit-layout',
        },
        props: true,
    },
    {
        path: '/403',
        name: 'unauthorized',
        component: () =>
            import(/* webpackChunkName: "auth" */ '@/pages/40x/Unauthorized'),
        meta: {
            requiresAuth: true,
            layout: 'ordit-authenticated-layout',
        },
    },
    {
        path: '/501',
        name: 'notimplemented',
        component: () =>
            import(/* webpackChunkName: "auth" */ '@/pages/40x/NotImplemented'),
        meta: {
            requiresAuth: true,
            layout: 'ordit-authenticated-layout',
        },
    },
    {
        path: '*',
        name: 'not-found',
        component: () =>
            import(/* webpackChunkName: "auth" */ '@/pages/40x/NotFound'),
        meta: {
            requiresAuth: true,
            layout: 'ordit-authenticated-layout',
        },
    },
]

export default routes
