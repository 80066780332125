import cloneDeep from 'lodash/cloneDeep'

class TagGroupFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)
        return entity
    }

    fromEntity(entity) {
        const formData = cloneDeep(entity)
        // translation obj
        if (
            !formData['translations'] ||
            Array.isArray(formData['translations'])
        ) {
            formData['translations'] = {
                en: {
                    name: '',
                },
                hu: {
                    name: '',
                },
            }
        }

        return formData
    }
}

export default new TagGroupFormMapper()
