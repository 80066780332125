<template>
    <v-row class="table-filter-row">
        <v-col v-if="!hideFilterBox" cols="auto">
            <filter-box
                v-model="tableFilters"
                :fields="fields"
                :add-id-filter="addIdFilter"
            ></filter-box>
        </v-col>
        <v-col class="input-col">
            <search-box
                v-model="tableFilters"
                :label="$t('labels.search')"
            ></search-box>
        </v-col>
        <slot></slot>
    </v-row>
</template>

<script>
import SearchBox from './SearchBox'
import FilterBox from './FilterBox'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

export default {
    components: {
        SearchBox,
        FilterBox,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        busy: {
            type: Boolean,
        },
        hideFilterBox: {
            type: Boolean,
        },
        addIdFilter: {
            type: Boolean,
        },
    },
    data() {
        return {
            tableFilters: {},
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                if (!isEqual(value, this.tableFilters)) {
                    this.tableFilters = cloneDeep(value)
                }
            },
        },
        tableFilters: {
            deep: true,
            handler(value) {
                this.$emit('input', cloneDeep(value))
            },
        },
    },
}
</script>

<style lang="scss">
.table-filter-row {
    .col {
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-col {
        max-width: 300px;
    }
}
</style>
