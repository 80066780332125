import cloneDeep from 'lodash/cloneDeep'

class CouponsFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)
        entity['value'] = entity['value'].toString()
        if (entity['minimumPrice'] === '' || entity['minimumPrice'] === 0) {
            entity['minimumPrice'] = null
        }
        if (entity['maximumPrice'] === '' || entity['maximumPrice'] === 0) {
            entity['maximumPrice'] = null
        }

        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)

        values['restaurants'] = []
        for (const item of values['restaurant']) {
            values['restaurants'].push(item['@id'])
        }
        values['restaurant'] = values['restaurants']
        delete values['restaurants']
        values['value'] = parseFloat(values['value'])
        if (values['minimumPrice'] !== null) {
            values['minimumPrice'] = parseFloat(
                values['minimumPrice']
            ).toString()
        }
        if (values['maximumPrice'] !== null) {
            values['maximumPrice'] = parseFloat(
                values['maximumPrice']
            ).toString()
        }

        return values
    }
}

export default new CouponsFormMapper()
