import cloneDeep from 'lodash/cloneDeep'

export const personalPickupInformation = {
    longitude: null,
    latitude: null,
    postCode: null,
    country: null,
    city: null,
    street: null,
    houseNumber: null,
    fullAddress: null,
    personalCollectionEnabled: false,
    contactPhone: null,
    minimumOrderValue: 0,
    photoOfThePlace: null,
}

export function isPPIfilled(personalPickupInfo) {
    return !!(
        personalPickupInfo?.longitude ||
        personalPickupInfo?.latitude ||
        personalPickupInfo?.postCode ||
        personalPickupInfo?.country ||
        personalPickupInfo?.city ||
        personalPickupInfo?.street ||
        personalPickupInfo?.houseNumber ||
        personalPickupInfo?.fullAddress ||
        personalPickupInfo?.contactPhone ||
        personalPickupInfo?.minimumOrderValue ||
        personalPickupInfo?.photoOfThePlace
    )
}

export function isPPINullable(personalPickupInfo) {
    return (
        !personalPickupInfo.personalCollectionEnabled &&
        !isPPIfilled(personalPickupInfo)
    )
}

export function getPPiClone() {
    return cloneDeep(personalPickupInformation)
}
