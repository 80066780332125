<template>
    <v-btn
        :color="buttonColor"
        class="white--text"
        depressed
        :disabled="disabled"
        :block="block"
        :loading="loading"
        @click="$emit('ok')"
    >
        {{ label }}
    </v-btn>
</template>
<script>
export default {
    props: {
        disabled: {
            type: Boolean,
        },
        loading: {
            type: Boolean,
        },
        color: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        block: Boolean,
    },
    computed: {
        label() {
            return this.text ?? this.$t('actions.ok')
        },
        buttonColor() {
            return this.color ?? 'primary'
        },
    },
}
</script>
