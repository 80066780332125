import apiClient from '@/api/ApiClient'
import ResponseHandler from '@/api/response/ResponseHandler'

const baseUrl = '/api/v1/dynamic_settings'

class Settings {
    get() {
        return ResponseHandler.handle(apiClient.get(baseUrl))
    }

    update(entity) {
        return ResponseHandler.handle(apiClient.put(baseUrl, entity))
    }
}

export default new Settings()
