<script>
export default {
    props: {
        active: Boolean,
        activeText: null,
        activeIcon: {
            type: String,
            default: 'done',
        },
        inactiveText: null,
        inactiveIcon: {
            type: String,
            default: 'close',
        },
    },
}
</script>

<template lang="pug">
v-tooltip(v-if="active" :disabled="!activeText" bottom)
    template(v-slot:activator="{ on }")
        v-icon(v-on="on") {{activeIcon}}
    | {{activeText}}
v-tooltip(:disabled="!inactiveText" v-else bottom)
    template(v-slot:activator="{ on }")
        v-icon(v-on="on") {{inactiveIcon}}
    | {{inactiveText}}
</template>

<style scoped lang="scss"></style>
