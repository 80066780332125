import Event from '@/mixins/Event'
import Store from '@/store/store'
import orderType from '@/store/type/orderType'
import authType from '@/store/type/authType'
import canteenType from '@/store/type/canteenType'
import { mapGetters } from 'vuex'
import IdFromIri from '@/services/IdFromIri'
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import throttle from 'lodash/throttle'

export default {
    mixins: [Event],
    data() {
        return {
            throttleCanteen: null,
            channelOrder: null,
            logToConsole: false,
            counter: 0,
            deliveryJobsChannel: null,
            orderChannels: [],
            canteenChannels: [],
            resiCanteenChannels: [],
        }
    },
    computed: {
        ...mapGetters({
            newList: orderType.getters.NEW_ORDERS_LIST,
            confirmedList: orderType.getters.CONFIRMED_ORDERS_LIST,
            approvedList: orderType.getters.APPROVED_ORDERS_LIST,
            managedRestarants: authType.getters.MANAGED_RESTAURANTS,
        }),
        restaurants() {
            return this.managedRestarants.map((iri) => IdFromIri.getId(iri))
        },
    },
    mounted() {
        this.restaurants.forEach((id, index) => {
            this.orderChannels.push(
                this.pusher.subscribe(`private-orders-${id}`)
            )
            this.canteenChannels.push(
                this.pusher.subscribe(`private-cloudcanteens-${id}`)
            )
            this.resiCanteenChannels.push(
                this.pusher.subscribe(`private-residentialcanteens-${id}`)
            )
            this.orderChannels[index].bind('new-order', this.newOrder)
            this.orderChannels[index].bind('update-order', this.updateOrder)
            this.canteenChannels[index].bind(
                'create-cloudcanteen',
                throttle((canteen) => this.updateCanteen(canteen, id), 5000)
            )
            this.canteenChannels[index].bind(
                'update-cloudcanteen',
                throttle((canteen) => this.updateCanteen(canteen, id), 5000)
            )
            this.canteenChannels[index].bind(
                'delete-cloudcanteen',
                this.deleteCanteen
            )
            this.resiCanteenChannels[index].bind(
                'create-residential-canteen',
                (canteen) => this.updateCanteen(canteen, id)
            )
            this.resiCanteenChannels[index].bind(
                'update-residential-canteen',
                (canteen) => this.updateCanteen(canteen, id)
            )
            this.resiCanteenChannels[index].bind(
                'delete-residential-canteen',
                this.deleteCanteen
            )
        })

        this.deliveryJobsChannel = this.pusher.subscribe('delivery-jobs')
        this.deliveryJobsChannel.bind(
            'create-delivery-job',
            this.updateDelivery
        )
        this.deliveryJobsChannel.bind(
            'update-delivery-job',
            this.updateDelivery
        )
    },
    beforeDestroy() {
        if (this.pusher) {
            this.pusher.disconnect()
        }
    },
    methods: {
        updateDelivery(event) {
            if (event) {
                const orders = this.findOrdersViaDeliveryJob(event.id)
                if (orders?.length) {
                    orders.forEach((order) => {
                        this.updateLists({
                            ...order,
                            ...{
                                deliveryJob: this.objectMapper(
                                    order.deliveryJob,
                                    event.data,
                                    this.mapDeliveryJob
                                ),
                            },
                        })
                    })
                }
            }
        },
        async updateOrder(event) {
            if (event) {
                const orders = this.findOrders(event.identifier)
                if (
                    this.isApprovedBySuperAdmin(event) ||
                    (this.isApprovedByRestaurantAdmin(event) &&
                        this.isInNewOrderList(event.identifier))
                ) {
                    const orderById = Store.getters[
                        orderType.getters.ORDER_BY_ID
                    ](event.id)
                    if (!orderById) return
                    const updatedOrder = this.objectMapper(
                        orderById,
                        event.data,
                        this.mapOrder
                    )
                    await Store.dispatch(
                        orderType.actions.UPDATE_NEW_ORDERS_LIST,
                        updatedOrder
                    )
                    await Store.dispatch(orderType.actions.APPROVE_ORDER, [
                        event.id,
                    ])
                } else if (!orders.length && this.isReapproved(event.data)) {
                    Store.dispatch(orderType.actions.GET_ACTIVE_ORDERS)
                } else {
                    orders.forEach((order) => {
                        if (order) {
                            this.updateLists(
                                this.objectMapper(
                                    order,
                                    event.data,
                                    this.mapOrder
                                )
                            )
                        }
                    })
                }
                // if updated order is canteen order and it is open at the canteen tab => clear the canteen order from view
                if (this.isActiveCanteen(event.identifier)) {
                    Store.commit(canteenType.mutations.SET_CANTEEN, null)
                }
            }
        },
        newOrder(order) {
            Store.dispatch(orderType.actions.GET_ORDERS_EVENT, [order.id])
        },
        updateLists(updatedOrder) {
            Store.commit(
                orderType.mutations.UPDATE_ACTIVE_ORDERS_LIST,
                updatedOrder
            )
            Store.commit(orderType.mutations.ADD_SUBORDERS, [updatedOrder])
            Store.commit(orderType.mutations.ADD_BASE_ORDER, updatedOrder)
            Store.commit(orderType.mutations.UPDATE_ACTIVE_ORDER, updatedOrder)
        },
        async updateCanteen(canteen, restaurantId) {
            if (canteen.identifier) {
                const activeCanteen = Store.getters[canteenType.getters.CANTEEN]
                const result = await RestApiCollection.get(
                    RestApiType.RESTAURANT_ORDER_CANTEENS
                ).get(
                    restaurantId,
                    canteen.identifier,
                    canteen.identifier === activeCanteen?.identifier &&
                        restaurantId === activeCanteen?.restaurantId
                )
                Store.commit(canteenType.mutations.UPDATE_CANTEEN_LIST, result)
            }
        },
        deleteCanteen(event) {
            if (event.id)
                Store.dispatch(canteenType.actions.DELETE_CANTEEN, event.id)
        },
        isReapproved(data) {
            return (
                data.status?.old?.includes('cancelled') &&
                data.status?.new?.includes('approved')
            )
        },
        isApprovedBySuperAdmin(event) {
            return !!(
                event.data.status?.old?.includes('new') &&
                event.data.status?.new?.includes('approved') &&
                event.approve_source === 'admin'
            )
        },
        isApprovedByRestaurantAdmin(event) {
            return !!(
                event.data.status?.old?.includes('new') &&
                event.data.status?.new?.includes('approved') &&
                event.approve_source === 'restaurant_admin'
            )
        },
        findOrders(identifier) {
            return [
                ...this.confirmedList?.filter(
                    (order) => order.identifier === identifier
                ),
                ...this.approvedList?.filter(
                    (order) => order.identifier === identifier
                ),
            ]
        },
        isInNewOrderList(identifier) {
            return !!this.newList?.find(
                (order) => order.identifier === identifier
            )
        },
        findOrdersViaDeliveryJob(id) {
            return this.confirmedList?.filter((order) => {
                return order.deliveryJob?.id === id
            })
        },
        isActiveCanteen(identifier) {
            const activeCanteen = Store.getters[canteenType.getters.CANTEEN]
            return activeCanteen
                ? `${activeCanteen.identifier}-${activeCanteen.restaurantId}` ===
                      identifier
                : undefined
        },
    },
}
