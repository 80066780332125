import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
import 'firebase/auth'
import 'firebase/database'

const firebaseConfig = {
    apiKey: 'AIzaSyBeoillAiWXoya02Uji6P8aFu-Jh5PbShw',
    authDomain: 'halogen-emblem-191119.firebaseapp.com',
    projectId: 'halogen-emblem-191119',
    storageBucket: 'halogen-emblem-191119.appspot.com',
    databaseURL:
        'https://halogen-emblem-191119-default-rtdb.europe-west1.firebasedatabase.app',
    messagingSenderId: '153218582821',
    appId: '1:153218582821:web:4c66e3b544d7beebf572cc',
    measurementId: 'G-9QTH7ENQLY',
}
try {
    const app = firebase.initializeApp(firebaseConfig)
    // const analytics = firebase.analytics()
    Vue.prototype.$fbauth = app.auth()
    Vue.prototype.$fbdb = app.database()
    Vue.prototype.$messaging = app.messaging()
} catch (e) {
    // eslint-disable-next-line no-console
    console.log(`Firebase error: ${e}`)
}
