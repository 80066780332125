import configType from './type/deliveryType'
export default {
    state: {
        deliveryJob: {},
        providerJobs: [],
    },
    getters: {
        [configType.getters.DELIVERY_JOB](state) {
            return state.deliveryJob
        },
        [configType.getters.PROVIDER_JOBS](state) {
            return state.providerJobs
        },
    },
    mutations: {
        [configType.mutations.SET_DELIVERY_JOB](state, payload) {
            state.deliveryJob = payload
        },
        [configType.mutations.SET_PROVIDER_JOBS](state, payload) {
            state.providerJobs = payload
        },
        [configType.mutations.SET_PROVIDER_JOB_STATUS](state, index) {
            state.providerJobs[index].status = 'cancelled'
        },
    },
    actions: {
        [configType.actions.CANCEL_PROVIDER_JOB]({ commit, state }, id) {
            let index = state.providerJobs.findIndex(
                (providerJob) => providerJob.id === id
            )
            commit(configType.mutations.SET_PROVIDER_JOB_STATUS, index)
        },
    },
}
