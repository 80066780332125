<script>
export default {
    props: {
        order: null,
    },
}
</script>

<template lang="pug">
.delivery-fee(v-if="order && order.priceDelivery")
    v-row
        v-col(align-self="baseline" offset="1")
            .font-weight-bold {{ $t('orders.delivery_fee') }}
        v-col.text-right(cols="2" align-self="baseline") {{ order.priceDelivery | currency }}
</template>

<style lang="scss">
.delivery-fee {
    border-top: 1px solid var(--v-g-e8-base);
}
</style>
