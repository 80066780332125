export default {
    props: {
        value: {
            type: String,
            default: null,
        },
        latitude: {
            type: Number,
            default: null,
        },
        longitude: {
            type: Number,
            default: null,
        },
        country: {
            type: String,
            default: null,
        },
        countryCode: {
            type: String,
            default: null,
        },
        zipCode: {
            type: String,
            default: null,
        },
        town: {
            type: String,
            default: null,
        },
        street: {
            type: String,
            default: null,
        },
        houseNumber: {
            type: String,
            default: null,
        },
        sublocality: {
            type: String,
            default: null,
        },
        administrativeAreaLevel1: {
            type: String,
            default: null,
        },
        administrativeAreaLevel2: {
            type: String,
            default: null,
        },
        administrativeAreaLevel3: {
            type: String,
            default: null,
        },
        administrativeAreaLevel4: {
            type: String,
            default: null,
        },
        placeId: {
            type: String,
            default: null,
        },
    },

    methods: {
        updateAddressFields(address) {
            this.$emit('input', address.full_address)
            this.$emit('update:latitude', address.latitude)
            this.$emit('update:longitude', address.longitude)
            this.$emit('update:country', address.country)
            this.$emit('update:countryCode', address.country_code)
            this.$emit('update:zipCode', address.zip_code)
            this.$emit('update:town', address.town)
            this.$emit('update:street', address.street)
            this.$emit('update:houseNumber', address.house_number)
            this.$emit('update:placeId', address.place_id)
        },

        convertAddress(address) {
            return {
                id: null,
                full_address: address.formatted_address,
                country: address?.country || null,
                longitude: address.longitude,
                latitude: address.latitude,
                town: address?.locality || address?.postal_town || null,
                zip_code: address?.postal_code || null,
                street: address?.route || null,
                house_number: address?.street_number || null,
                country_code:
                    address.political || this.currentCountry.toUpperCase(),
                place_id: address?.place_id,
            }
        },

        parseAddress(addressObj, isMap = true) {
            const addressComponents = {
                street_number: 'short_name',
                route: 'long_name',
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                country: 'long_name',
                political: 'short_name',
                postal_code: 'short_name',
                postal_town: 'short_name',
            }
            const data = {}
            if (addressObj.address_components !== undefined) {
                const country = addressObj.address_components.reduce(
                    (result, component) => {
                        if (!result && component.types.includes('country')) {
                            result = component.long_name
                        }
                        return result
                    },
                    undefined
                )
                let premise = undefined
                let shortRoute = undefined

                addressObj.address_components.forEach((el) => {
                    el.types.forEach((type) => {
                        if (addressComponents[type]) {
                            if (
                                type === 'political' &&
                                country === 'United Kingdom'
                            ) {
                                data[type] = 'UK'
                            } else data[type] = el[addressComponents[type]]
                        }
                        if (type === 'premise') premise = el.short_name
                        if (type === 'route') shortRoute = el.short_name
                    })
                })

                if (premise) {
                    data.route = `${premise}, ${shortRoute}`
                }

                if (isMap) {
                    data.latitude = addressObj.geometry.location.lat()
                    data.longitude = addressObj.geometry.location.lng()
                } else {
                    data.latitude = addressObj.geometry.location.lat
                    data.longitude = addressObj.geometry.location.lng
                }
                data.formatted_address = addressObj.formatted_address
                data.place_id = addressObj.place_id
            }
            return data
        },
    },
}
