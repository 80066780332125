import Vue from 'vue'
import VeeValidate from 'vee-validate'
import VeeValidateEnMessages from 'vee-validate/dist/locale/en'
import VeeValidateHuMessages from 'vee-validate/dist/locale/hu'
import { i18n } from '@/plugins/i18n'
import '@/validators/Validators'

Vue.use(VeeValidate, {
    fieldsBagName: 'veeFields',
    i18n,
    i18nRootKey: 'validations',
    dictionary: {
        en: VeeValidateEnMessages,
        hu: VeeValidateHuMessages,
    },
    useConstraintAttrs: false,
    delay: 300,
})
