import clientTypes from '@/api/SelectApiClientType'

const NSBASE = 'selectapi'
const getModuleName = (type) => `${NSBASE}-${type}`

export default {
    NSBASE,
    getters: {
        VALUES: 'values',
    },
    mutations: {
        VALUES: 'values',
        LOADER: 'loader',
        INVALIDATE: 'invalidate',
    },
    actions: {
        LOAD: 'load',
        REFRESH: 'refresh',
    },
    clientTypes,
    getModuleName,
    getActionName: (type, action) => `${getModuleName(type)}/${action}`,
}
