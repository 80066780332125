import { ROLE_RESTAURANT } from '@/enum/roles'
import routeType from '@/router/routeType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_RESTAURANT],
    layout: 'ordit-authenticated-layout',
}

export default [
    {
        path: '/restaurant-invoices',
        name: routeType.RESTAURANT_INVOICES,
        component: () =>
            import(
                /* webpackChunkName: "delivery-list" */ '@/pages/invoices/Restaurant.vue'
            ),
        meta,
    },
]
