import { ROLE_ADMIN } from '@/enum/roles'
import routeType from '@/router/routeType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

const breadCrumbs = {
    deliveryList: {
        to: {
            name: routeType.DELIVERY_LIST,
        },
        label: 'menu.deliveries',
    },
    deliveryEdit: {
        to: {
            name: routeType.DELIVERY_EDIT,
            query: { tab: 0 },
        },
        label: 'labels.delivery_edit',
    },
}

const passthroughRender = {
    render: (c) => c('router-view'),
}

export default [
    {
        path: '/deliveries',
        component: passthroughRender,
        meta,
        children: [
            {
                path: '',
                name: routeType.DELIVERY_LIST,
                component: () =>
                    import(
                        /* webpackChunkName: "delivery-list" */ '@/pages/deliveries/List.vue'
                    ),
                meta,
            },
            {
                path: 'create',
                name: routeType.DELIVERY_CREATE,
                component: () =>
                    import(
                        /* webpackChunkName: "delivery-edit" */ '@/pages/deliveries/Create.vue'
                    ),
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumbs.deliveryList],
                },
            },
            {
                path: ':id/create-provider-job',
                name: routeType.DELIVERY_CREATE_PROVIDER_JOB,
                component: () =>
                    import(
                        /* webpackChunkName: "delivery-edit" */ '@/pages/deliveries/CreateNewProviderJobForm.vue'
                    ),
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumbs.deliveryList],
                },
            },
            {
                path: ':id',
                name: routeType.DELIVERY_EDIT,
                component: () =>
                    import(
                        /* webpackChunkName: "delivery-edit" */ '@/pages/deliveries/Edit.vue'
                    ),
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumbs.deliveryEdit],
                },
            },
        ],
    },
]
