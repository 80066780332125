import Vue from 'vue'
import store from '@/store/store'
import configType from '@/store/type/configType'

Vue.filter('currency', (value, code, locale) => {
    if (value === undefined) return
    value = typeof value === 'string' ? parseFloat(value) : value
    const currencyCode = code || store.getters[configType.getters.CURRENCY_CODE]
    const currencyLocale =
        locale || store.getters[configType.getters.CURRENCY_LOCALE]

    const formatter = new Intl.NumberFormat(currencyLocale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: currencyCode === 'HUF' ? 0 : 2,
    })

    return formatter.format(value)
})
