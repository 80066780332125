export default {
    getters: {
        DELIVERY_METHODS: 'deliveryJob',
        DELIVERY_JOB: 'deliveryJob',
        PROVIDER_JOBS: 'providerJobs',
    },
    mutations: {
        SET_DELIVERY_METHODS: 'setDeliveryMethods',
        SET_DELIVERY_JOB: 'setDeliveryJob',
        SET_PROVIDER_JOBS: 'setProviderJobs',
        SET_PROVIDER_JOB_STATUS: 'setProviderJobStatus',
    },
    actions: {
        CANCEL_PROVIDER_JOB: 'cancelProviderJob',
    },
}
