import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import dayjs from '@/plugins/date'
import RestaurantSoftwareType from '@/enum/RestaurantSoftwareType'
import PeriodMapper from '@/services/mapper/PeriodMapper'
import { defaultCutoff } from '@/components/pages/canteen/MultiCutoffFormPart'
import {
    TIME_FORMAT,
    DATE_FORMAT,
    DATE_TIME_FORMAT,
} from '@/const/DateTimeFormats'
import {
    personalPickupInformation,
    isPPINullable,
} from '@/services/Restaurants/PersonalPickupInformation'
import {
    getPiClone,
    isPIfilled,
} from '@/services/Restaurants/PickupInformation'

class RestaurantFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        // convert to ids
        if (entity['coverImageMedia'] && entity['coverImageMedia']['@id']) {
            entity['coverImageMedia'] = entity['coverImageMedia']['@id']
        }

        if (entity['cardImageMedia'] && entity['cardImageMedia']['@id']) {
            entity['cardImageMedia'] = entity['cardImageMedia']['@id']
        }

        // opening days
        entity['openingDays'] = PeriodMapper.toEntity(entity['openingDays'])

        entity['specialClosures'].forEach((holiday) => {
            entity['openingDays'].push(holiday)
        })

        delete entity['specialClosures']

        entity.personalPickupInformation.minimumOrderValue = parseFloat(
            entity.personalPickupInformation.minimumOrderValue
        )
        if (
            entity.personalPickupInformation?.photoOfThePlace &&
            entity.personalPickupInformation?.photoOfThePlace['@id']
        ) {
            entity.personalPickupInformation.photoOfThePlace =
                entity.personalPickupInformation.photoOfThePlace['@id']
        }
        if (isPPINullable(entity.personalPickupInformation)) {
            entity.personalPickupInformation = null
        }

        // country settings
        // entity['country'] = store.getters[configType.getters.COUNTRY]

        // software settings
        if (!entity['softwareSettings']) {
            entity['softwareSettings'] = []
        }

        entity['softwareSettings'] = entity['softwareSettings'].filter(
            (softwareSetting) => {
                return softwareSetting.software !== RestaurantSoftwareType.ordit
            }
        )

        if (
            !entity['deliverySetting'].deliveryRules.length &&
            typeof entity['deliverySetting'].defaultPreparationTime !== 'number'
        ) {
            entity['deliverySetting'] = null
        }

        if (entity.deliverySetting?.deliveryRules?.length) {
            entity.deliverySetting.deliveryRules.forEach((rule) => {
                if (!rule.distanceTo) {
                    rule.distanceTo = null
                }
            })
        }

        if (!isPIfilled(entity.pickupInformation)) {
            entity.pickupInformation = null
        }

        if (entity['orditSoftwareSetting']) {
            entity['softwareSettings'].push(entity['orditSoftwareSetting'])
        }

        delete entity['orditSoftwareSetting']

        entity['softwareSettings'].forEach((softwareSetting) => {
            if (!softwareSetting || !softwareSetting['softwareLimits']) {
                return
            }

            softwareSetting['softwareLimits'].forEach((softwareLimit) => {
                softwareLimit['minOrder'] = parseFloat(
                    softwareLimit['minOrder']
                )
                softwareLimit['maxOrder'] = parseFloat(
                    softwareLimit['maxOrder']
                )
                softwareLimit['timeBeforeInMinute'] = parseInt(
                    softwareLimit['timeBeforeInMinute']
                )
            })
        })

        this.toEntityAreas(entity)

        if (entity.areas?.length) {
            entity.areas.forEach((restArea) => {
                if (restArea.area && restArea.area.length) {
                    restArea.area[0].push(restArea.area[0][0])
                }
            })
        }

        for (let field of [
            'cardTransactionFee',
            'commOnDelivery',
            'commOnFood',
        ]) {
            if (!isNaN(entity['billingInfo'][field])) {
                entity['billingInfo'][field] = (
                    entity['billingInfo'][field] / 100
                ).toString()
            }
        }

        entity['priceCategory'] = 'average'
        entity['isCatering'] = null
        entity['hiddenCompanies'] = []
        entity['preorderDayUntil'] = 30
        entity['billingInfo']['pickupAddress'] = entity['location']

        if (!entity.deliverooUrl) {
            entity.deliverooUrl = null
        }

        // int fiesta
        for (let field of [
            'fhrsId',
            'canteenSmallOrderLimit',
            'canteenSmallCutoffNotice',
            'canteenLargeCutoffNotice',
        ]) {
            entity[field] =
                entity[field] !== null && entity[field] !== ''
                    ? parseInt(entity[field])
                    : null
        }

        // null when empty
        for (let field of [
            'tabletSn',
            'preorderBorderTime',
            'canteenSmallEarliestPickup',
            'canteenSmallLatestPickup',
            'canteenSmallLatestCutoff',
            'canteenLargeEarliestPickup',
            'canteenLargeLatestPickup',
            'canteenLargeLatestCutoff',
            'thirdPartyType',
        ]) {
            if (!entity[field]) {
                entity[field] = null
            }
        }

        this.toEntityRules(entity)

        return entity
    }

    fromEntity(entity) {
        const formData = cloneDeep(entity)

        // openingDays
        const openingDays = []
        const specialClosures = []

        formData['openingDays'].forEach((value) => {
            if (value.date == null) {
                openingDays.push(value)
            } else {
                specialClosures.push(value)
            }
        })

        specialClosures.forEach((day) => {
            const intervals = []
            if (day.timeIntervals && Array.isArray(day.timeIntervals)) {
                day.timeIntervals.forEach((hour) => {
                    intervals.push({
                        openTime: PeriodMapper.getHour(hour.openTime),
                        closeTime: PeriodMapper.getHour(hour.closeTime),
                    })
                })
            }

            day.timeIntervals = intervals
            day.date = PeriodMapper.getDate(day.date)
        })

        formData['openingDays'] = openingDays
        formData['specialClosures'] = specialClosures

        formData['openingDays'] = PeriodMapper.fromEntity(
            formData['openingDays']
        )

        if (!formData.pickupInformation) {
            formData.pickupInformation = getPiClone()
        }

        if (!formData['billingInfo']) {
            formData['billingInfo'] = {}
        }

        if (!formData['personalPickupInformation']) {
            formData.personalPickupInformation = cloneDeep(
                personalPickupInformation
            )
        }

        if (!formData['deliverySetting']) {
            formData['deliverySetting'] = {
                defaultPreparationTime: null,
            }
            if (!formData['deliverySetting']['deliveryRules']) {
                formData['deliverySetting']['deliveryRules'] = []
            }
        }

        if (!formData['timezoneRegion']) {
            formData['timezoneRegion'] = null
        }

        // time fields from datetime
        for (let field of [
            'preorderBorderTime',
            'canteenSmallEarliestPickup',
            'canteenSmallLatestPickup',
            'canteenSmallLatestCutoff',
            'canteenLargeEarliestPickup',
            'canteenLargeLatestPickup',
            'canteenLargeLatestCutoff',
        ]) {
            if (formData[field]) {
                formData[field] = formData[field].substr(11, 5)
            }
        }

        // translation obj
        if (
            !formData['translations'] ||
            Array.isArray(formData['translations'])
        ) {
            formData['translations'] = {
                en: {
                    badge: '',
                    banner: '',
                    description: '',
                    search: '',
                },
                hu: {
                    badge: '',
                    banner: '',
                    description: '',
                    search: '',
                },
            }
        }

        // software settings
        if (formData['softwareSettings']) {
            let orditSoftware = {}
            formData['softwareSettings'].forEach((softwareSetting) => {
                if (softwareSetting.software === RestaurantSoftwareType.ordit) {
                    orditSoftware = cloneDeep(softwareSetting)
                }
            })

            formData['orditSoftwareSetting'] = orditSoftware
        }

        formData.areas.forEach((restArea) => {
            if (restArea.area) {
                restArea.area[0].pop()
            }
        })

        this.fromEntityAreas(formData)

        for (let field of [
            'cardTransactionFee',
            'commOnDelivery',
            'commOnFood',
        ]) {
            if (formData['billingInfo'][field]) {
                formData['billingInfo'][field] = parseFloat(
                    (formData['billingInfo'][field] * 100).toFixed(2)
                )
            }
        }

        if (!formData['restaurantDiscount']) {
            formData['restaurantDiscount'] = {
                discountPriceEnabled: false,
                discountPriceOnlyForCanteen: false,
            }
        }

        this.fromEntityRules(formData, defaultCutoff)

        return formData
    }

    toEntityAreas(entity) {
        const areas = []

        entity.variantAreas.forEach((variantArea) => {
            variantArea.variants.forEach((variant) => {
                const areaClone = cloneDeep(variantArea.area)
                const area = {
                    '@type': 'RestaurantArea',
                    area: areaClone,
                    deliveryDelay: variantArea.deliveryDelay,
                    deliveryPriceLimit: variantArea.deliveryPriceLimit,
                    deliveryRadius: variantArea.deliveryRadius,
                    isActive: variantArea.isActive,
                    name: variantArea.name,
                    minimumOrder: String(variant.minimumOrder),
                    deliveryPrice: String(variant.deliveryPrice),
                    deliveryMinTime: variant.deliveryMinTime,
                    deliveryMaxTime: variant.deliveryMaxTime,
                    isPreOrder: variant.isPreOrder,
                    preOrderBorderTime: variant.preOrderBorderTime,
                    preOrderDayBefore: variant.preOrderDayBefore,
                }

                if (variant['@id']) {
                    area['@id'] = variant['@id']
                }

                areas.push(area)
            })
        })

        delete entity.variantAreas
        entity.areas = areas
    }

    fromEntityAreas(formData) {
        const areas = []

        formData.areas.forEach((modelArea) => {
            const copy = cloneDeep(modelArea)
            if (copy.preOrderBorderTime) {
                copy.preOrderBorderTime = copy.preOrderBorderTime.substr(11, 5)
            }
            let found = null

            for (const area of areas) {
                if (
                    isEqual(area.area, copy.area) &&
                    area.deliveryRadius === copy.deliveryRadius &&
                    area.name === copy.name
                ) {
                    found = area
                    break
                }
            }

            const variant = {
                '@id': copy['@id'],
                minimumOrder: copy.minimumOrder,
                deliveryPrice: copy.deliveryPrice,
                deliveryMinTime: copy.deliveryMinTime,
                deliveryMaxTime: copy.deliveryMaxTime,
                isPreOrder: copy.isPreOrder,
                preOrderBorderTime: copy.preOrderBorderTime,
                preOrderDayBefore: copy.preOrderDayBefore,
            }

            if (found) {
                found.variants.push(variant)
            } else {
                copy.variants = [variant]

                areas.push(copy)
            }
        })

        formData.variantAreas = areas
    }

    toEntityRules(entity) {
        if (!entity.defaultCanteenRules) return

        const defaultRules = entity.defaultCanteenRules.map((rule) => {
            const day = dayjs(rule.cutoffTime)
            return {
                cutoffTimeOverrideDay: dayjs(day.format(DATE_FORMAT)).diff(
                    defaultCutoff,
                    'd'
                ),
                cutoffTimeOverrideTime: day.format(TIME_FORMAT),
                orderLimit: rule.limit,
            }
        })
        entity.defaultCanteenRules = defaultRules
    }

    fromEntityRules(formData, cutoffTime) {
        formData.defaultCanteenRules = fromEntityRules(formData, cutoffTime)
    }
}

export function fromEntityRules(formData, cutoffTime) {
    if (!formData?.defaultCanteenRules) return

    const hourOffset = dayjs(cutoffTime).hour() - dayjs(defaultCutoff).hour()
    const minuteOffset =
        dayjs(cutoffTime).minute() - dayjs(defaultCutoff).minute()

    const defaultRules = formData.defaultCanteenRules.map((rule) => {
        const sameDay = rule.cutoffTimeOverrideDay === 0
        const time = dayjs(rule.cutoffTimeOverrideTime)
        let cutoff = dayjs(cutoffTime)
            .add(rule.cutoffTimeOverrideDay, 'd')
            .hour(time.hour() + (sameDay ? hourOffset : 0))
            .minute(time.minute() + (sameDay ? minuteOffset : 0))

        return {
            cutoffTime: cutoff.format(DATE_TIME_FORMAT),
            limit: rule.orderLimit,
        }
    })
    return defaultRules
}

export default new RestaurantFormMapper()
