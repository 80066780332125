<template lang="pug">
    component(
        :is="autocomplete ? 'v-autocomplete' : 'v-select'"
        :value="value"
        :label="labelText"
        :data-vv-as="$t(label)"
        :name="name"
        :disabled="disabled"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :item-disabled="itemDisabled"
        :error-messages="errorMessages"
        :clearable="clearable"
        :placeholder="placeholder"
        :persistent-placeholder="persistentPlaceholder"
        :hint="hint"
        :hide-details="hideDetails"
        :persistent-hint="!!hint"
        @input="$emit('input', $event)")
</template>

<script>
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'
import { VSelect, VAutocomplete } from 'vuetify/lib'

export default {
    components: { VSelect, VAutocomplete },
    mixins: [ErrorMsgInputMixin],
    inject: {
        $validator: '$validator',
    },
    props: {
        value: {
            type: null,
            default: null,
        },
        items: {
            type: Array,
            default: () => [],
        },
        itemText: {
            type: String,
            default: 'title',
        },
        itemValue: {
            type: String,
            default: 'value',
        },
        hint: {
            type: String,
            default: undefined,
        },
        hideDetails: {
            type: [Boolean, String],
            default: false,
        },
        disabled: Boolean,
        clearable: Boolean,
        persistentPlaceholder: Boolean,
        autocomplete: Boolean,
        placeholder: String,
        itemDisabled: [String, Array, Function],
    },
}
</script>
