export function getId(iri) {
    return iri && (typeof iri === 'string' || iri instanceof String)
        ? parseInt(iri.substring(iri.lastIndexOf('/') + 1))
        : null
}

export function getIdFromIri(item, key, rt, paramKey) {
    if (!item) return {}
    const id = getId(item[key])
    let params = {}
    params[paramKey] = id

    return id
        ? {
              name: rt,
              params: params,
          }
        : {}
}

export default {
    getId,
    getIdFromIri,
}
