import cloneDeep from 'lodash/cloneDeep'

class MealOptionFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        if (entity['discountPrice'] == '') {
            entity['discountPrice'] = null
        }

        if (entity?.supplierPrice == '') {
            entity.supplierPrice = null
        }

        entity.kcal = entity.kcal ? parseInt(entity.kcal) : null

        return entity
    }

    fromEntity(entity) {
        const formData = cloneDeep(entity)

        return formData
    }
}

export default new MealOptionFormMapper()
