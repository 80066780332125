import cloneDeep from 'lodash/cloneDeep'
import CommonCanteen from './CanteenCommonMapper'

class CompanyCloudcanteenFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        for (let override of entity.budgetOverrides) {
            if (typeof override['@id'] === 'number') {
                delete override['@id']
            }
        }

        CommonCanteen.toEntity(entity)
        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)

        CommonCanteen.fromEntity(entity, values)
        return values
    }
}

export default new CompanyCloudcanteenFormMapper()
