import cloneDeep from 'lodash/cloneDeep'

class TodoFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        return entity
    }

    fromEntity(entity) {
        const formData = cloneDeep(entity)

        const keys = ['user', 'ordering', 'company', 'restaurant']
        keys.forEach((key) => {
            if (formData[key]) {
                formData[key] = formData[key]['@id']
            }
        })

        return formData
    }
}

export default new TodoFormMapper()
