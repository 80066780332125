<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" text v-on="on">
                {{ currentLocale }}
            </v-btn>
        </template>
        <v-list dense class="text-center">
            <v-list-item
                v-for="(locale, index) in locales"
                :key="`locale-${index}`"
                @click.prevent="switchTo(locale)"
            >
                <v-list-item-content>
                    <v-list-item-title class="text-uppercase">
                        {{ locale }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import { setI18nLanguage } from '@/plugins/i18n'

export default {
    computed: {
        ...mapGetters({
            currentLocale: configType.getters.CURRENT_LOCALE,
            locales: configType.getters.LOCALES,
        }),
    },
    watch: {
        currentLocale: {
            immediate: true,
            handler(currentLocale) {
                setI18nLanguage(currentLocale)
                this.$date.locale(currentLocale)
            },
        },
    },
    methods: {
        switchTo(locale) {
            this.$store.commit(configType.mutations.CURRENT_LOCALE, locale)
        },
    },
}
</script>
