import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import { EventBus } from '@/plugins/events'

export default {
    data() {
        return {
            loading: false,
            notificationBlocked: false,
            helperImage: null,
        }
    },
    computed: {
        ...mapGetters({
            notificationRegistered: authType.getters.GET_NOTIFICATION_STATE,
            notificationPermissionGranted:
                authType.getters.NOTIFICATION_PERMISSION_GRANTED,
            notificationOffByUser: authType.getters.NOTIFICATION_OFF_BY_USER,
        }),
        notificationsOn() {
            return (
                this.notificationPermissionGranted &&
                this.notificationRegistered
            )
        },
    },
    mounted() {
        this.getImage()
    },
    methods: {
        getImage() {
            const test = (regexp) => {
                return regexp.test(navigator.userAgent)
            }
            if (test(/opr\//i) || !!window.opr) {
                // return 'Opera'
                this.helperImage = null
            } else if (test(/edg/i)) {
                // return 'Microsoft Edge'
                this.helperImage = null
            } else if (test(/chrome|chromium|crios/i)) {
                // return 'Google Chrome'
                this.helperImage = ['/img/noti_help/chrome_1.png']
            } else if (test(/firefox|fxios/i)) {
                // return 'Mozilla Firefox'
                this.helperImage = [
                    '/img/noti_help/firefox_1.png',
                    '/img/noti_help/firefox_2.png',
                ]
            } else if (test(/safari/i)) {
                // return 'Apple Safari'
                this.helperImage = [
                    '/img/noti_help/safari_1.png',
                    '/img/noti_help/safari_2.png',
                ]
            } else if (test(/trident/i)) {
                // return 'Microsoft Internet Explorer'
                this.helperImage = null
            } else if (test(/ucbrowser/i)) {
                // return 'UC Browser'
                this.helperImage = null
            } else if (test(/samsungbrowser/i)) {
                // return 'Samsung Browser'
                this.helperImage = null
            } else {
                this.helperImage = null
            }
        },
        async turnOnNotification() {
            this.loading = true
            try {
                const permission = await Notification.requestPermission()
                if (permission === 'denied') {
                    this.notificationBlocked = true
                } else if (permission === 'granted') {
                    await this.$store.dispatch(
                        authType.actions.SET_NOTIFICATION_PERMISSION,
                        true
                    )
                    await this.$store.dispatch(
                        authType.actions.REGISTER_NOTIFICATIONS
                    )
                    this.$store.dispatch(
                        authType.actions.SET_NOTIFICATIONS,
                        false
                    )
                    EventBus.$emit('notificationsOn')
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e)
            } finally {
                this.loading = false
            }
        },
        async turnOffNotification() {
            this.loading = true
            try {
                await this.$store.dispatch(
                    authType.actions.UNREGISTER_NOTIFICATIONS
                )
                this.$store.dispatch(authType.actions.SET_NOTIFICATIONS, true)
                console.log(this.$cookies.get('notificationOffByUser'))
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e)
            } finally {
                this.loading = false
            }
        },
        setPermission() {
            if (!this.loading) {
                if (this.notificationsOn) this.turnOffNotification()
                else this.turnOnNotification()
            }
        },
    },
}
