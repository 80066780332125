<script>
export default {
    data() {
        return {
            footerLeft: false,
            footerRight: false,
        }
    },
    computed: {
        fixed() {
            return this.footerLeft || this.footerRight
        },
    },
    methods: {
        setPortalLeft(val) {
            this.footerLeft = val
        },
        setPortalRight(val) {
            this.footerRight = val
        },
    },
}
</script>

<template lang="pug">
    v-footer.footer-bar(:fixed="fixed" :absolute="!fixed" :height='75' app inset outlined)
        v-container.px-md-6.py-2.py-md-3.d-flex
            portal-target(name='footer' @change="setPortalLeft" multiple)
            v-spacer
            portal-target(name='footer-right' @change="setPortalRight")
</template>

<style scoped lang="scss">
.footer-bar {
    background-color: #fbfbfb;

    &.v-sheet--outlined {
        border-width: 1px 0 0;
        border-style: solid;
        border-color: #88959f;
    }
}
</style>
