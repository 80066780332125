import Vue from 'vue'
import { i18n } from '@/plugins/i18n'

Vue.filter('toSchedule', (value) => {
    if (!value) return ''
    if (typeof value !== 'string') return 'String value only!'
    value = Vue.prototype.$generalizeDate(value)
    const now = Vue.prototype.$date()
    const date = Vue.prototype.$date(value)
    if (date.isBefore(now, 'day'))
        return `<span class="text-no-wrap">${date.format(
            'D MMM YYYY'
        )},</span>&nbsp;${date.format('HH:mm')}`
    else if (date.isSame(now, 'day')) {
        const today = i18n.t('labels.today')
        return `<span class="text-no-wrap">${today}</span>&nbsp;${date.format(
            'HH:mm'
        )}`
    } else if (date.isSame(now.add(1, 'days'), 'day')) {
        const tomorrow = i18n.t('labels.tomorrow')
        return `<span class="text-no-wrap">${tomorrow}</span>&nbsp;${date.format(
            'HH:mm'
        )}`
    } else if (date.isSame(now, 'year'))
        return `<span class="text-no-wrap">${date.format(
            'D MMM'
        )}</span>&nbsp;${date.format('HH:mm')}`
    return `<span class="text-no-wrap">${date.format(
        'D MMM YYYY'
    )}</span>&nbsp;${date.format('HH:mm')}`
})
