class PeriodMapper {
    toEntity(periods) {
        const days = []

        Object.keys(periods).forEach((day) => {
            const timeIntervals = []

            periods[day].forEach((period) => {
                timeIntervals.push({
                    openTime: period.from,
                    closeTime: period.to,
                })
            })

            if (timeIntervals.length > 0) {
                days.push({ day: Number.parseInt(day) + 1, timeIntervals })
            }
        })

        return days
    }

    fromEntity(periods) {
        const days = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] }

        periods.forEach((day) => {
            if (day.timeIntervals && Array.isArray(day.timeIntervals)) {
                day.timeIntervals.forEach((hour) => {
                    days[day.day - 1].push(this.timeIntervalToPeriod(hour))
                })
            }
        })

        return days
    }

    timeIntervalToPeriod(interval) {
        return {
            from: this.getHour(interval.openTime),
            to: this.getHour(interval.closeTime),
        }
    }

    getHour(dateString) {
        const regex = /(?<hour>[0-9][0-9]:[0-9][0-9]):/gm
        const res = regex.exec(dateString)

        return res && res.groups && res.groups.hour ? res.groups.hour : ''
    }

    getDate(dateString) {
        const regex = /(?<date>[0-9]{4}-[0-9]{2}-[0-9]{2})/gm
        const res = regex.exec(dateString)

        return res && res.groups && res.groups.date ? res.groups.date : ''
    }
}

export default new PeriodMapper()
