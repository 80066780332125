import { ROLE_ADMIN } from '@/enum/roles'
import routeType from '@/router/routeType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

const breadcrumbs = [
    {
        to: {
            name: routeType.BRAND_LIST,
        },
        label: 'menu.brands',
        roles: [ROLE_ADMIN],
    },
]

const passthroughRender = {
    render: (c) => c('router-view'),
}

export default [
    {
        path: '/brands',
        component: passthroughRender,
        meta,
        children: [
            {
                path: '',
                name: routeType.BRAND_LIST,
                component: () =>
                    import(
                        /* webpackChunkName: "brand-list" */ '@/pages/brands/BrandList.vue'
                    ),
                meta,
            },
            {
                path: 'create',
                name: routeType.BRAND_CREATE,
                component: () =>
                    import(
                        /* webpackChunkName: "brand-edit" */ '@/pages/brands/BrandCreate.vue'
                    ),
                meta: {
                    ...meta,
                    breadcrumbs,
                },
            },
            {
                path: ':id',
                name: routeType.BRAND_EDIT,
                component: () =>
                    import(
                        /* webpackChunkName: "brand-edit" */ '@/pages/brands/BrandEdit.vue'
                    ),
                props: true,
                meta: {
                    ...meta,
                    breadcrumbs,
                },
            },
        ],
    },
]
