import ErrorHandler from '@/services/ErrorHandler'
import { i18n } from '@/plugins/i18n'
import Store from '@/store/store'
import configType from '@/store/type/configType'

class Notification {
    setVueInstance(vue) {
        this.vue = vue
    }

    notification(title = '', msg = '', type = 'success', timeout = 5000) {
        this.vue.$toastr.Add({
            title,
            msg,
            type,
            timeout,
            preventDuplicates: true,
        })
    }

    error(title, msg, timeout) {
        this.notification(title, msg, 'error', timeout)
    }

    warning(title, msg, timeout) {
        this.notification(title, msg, 'warning', timeout)
    }

    success(title, msg, timeout) {
        this.notification(title, msg, 'success', timeout)
    }

    // general notifications

    batchDeleteFinished(result) {
        this.success(
            i18n.t('notifications.batch_delete_finished.title'),
            i18n.t('notifications.batch_delete_finished.message', {
                count: result.deleted.length,
            })
        )
    }

    batchDeleteFinishedWithErrors(result) {
        this.warning(
            i18n.t('notifications.batch_delete_finished_with_error.title'),
            i18n.t('notifications.batch_delete_finished_with_error.message', {
                count: result.deleted.length,
                list: result.failed.join(', '),
            })
        )
    }

    batchDeleteCanceled(result) {
        const suffix =
            result.deleted.length > 0 ? 'has_deleted_items' : 'no_deleted_items'

        this.warning(
            i18n.t('notifications.batch_delete_cancel.title'),
            i18n.t('notifications.batch_delete_cancel.' + suffix, {
                list: result.deleted.join(', '),
            }),
            0
        )
    }

    entityCreated(entity, entityName) {
        this.success(
            i18n.t(entity || 'notifications.success'),
            i18n.t('notifications.entity_created.message', {
                name: entityName || i18n.t('labels.entity'),
            })
        )
    }

    entityUpdated(entity, entityName) {
        this.success(
            i18n.t(entity || 'notifications.success'),
            i18n.t('notifications.entity_updated.message', {
                name: entityName || i18n.t('labels.entity'),
            })
        )
    }

    entityDeleted(entity, entityName) {
        this.success(
            i18n.t(entity || 'notifications.success'),
            i18n.t('notifications.entity_deleted.message', {
                name: entityName || i18n.t('labels.entity'),
            })
        )
    }

    entityActivated(entity, entityName) {
        this.success(
            i18n.t(entity || 'notifications.success'),
            i18n.t('notifications.entity_activated.message', {
                name: entityName || i18n.t('labels.entity'),
            })
        )
    }

    entityDeactivated(entity, entityName) {
        this.success(
            i18n.t(entity || 'notifications.success'),
            i18n.t('notifications.entity_deactivated.message', {
                name: entityName || i18n.t('labels.entity'),
            })
        )
    }

    restaurantAdminEnabled(entity, entityName) {
        this.success(
            i18n.t(entity || 'notifications.success'),
            i18n.t('notifications.restaurant_admin_enabled.message', {
                name: entityName || i18n.t('labels.entity'),
            })
        )
    }

    restaurantAdminDisabled(entity, entityName) {
        this.success(
            i18n.t(entity || 'notifications.success'),
            i18n.t('notifications.restaurant_admin_disabled.message', {
                name: entityName || i18n.t('labels.entity'),
            })
        )
    }

    requestError(response) {
        ErrorHandler.error(response)
        response = response.message || response
        const networkError =
            response.toString().toLowerCase() === 'network error'

        if (networkError) this.networkError()
        else
            this.error(
                i18n.t('notifications.request_error.title'),
                response,
                10000
            )
    }

    networkError() {
        const locale = Store.getters[configType.getters.CURRENT_LOCALE]
        const title = locale === 'hu' ? 'Hiba' : 'Error'
        const response =
            locale === 'hu'
                ? 'Jelenleg nincs internet kapcsolat'
                : 'No internet connection'
        this.error(title, response, 10000)
    }

    formError() {
        this.error(i18n.t('notifications.form_error.title'), '')
    }

    deleteError(entityName) {
        this.error(
            i18n.t('notifications.delete_error.title', { name: entityName }),
            ''
        )
    }

    importSuccessful(name) {
        this.success(
            i18n.t(name),
            i18n.t('notifications.import_successful.title')
        )
    }
}

export default new Notification()
