import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import config from './config'
import meal from './meal'
import restaurant from './restaurant'
import order from './order'
import delivery from './delivery'
import cc from './cc'
import routeInfo from './routeInfo'
import baseForm from './baseForm'
import dirty from './dirty'
import firebase from './firebase'
import selectapi from './selectapi'
import selectapiType from './type/selectapiType'
import credentialPersister from '@/store/plugin/credentialPersister'
import googleMaps from '@/store/plugin/googleMapsInit'

Vue.use(Vuex)

const Store = new Vuex.Store({
    modules: {
        auth,
        config,
        routeInfo,
        baseForm,
        meal,
        restaurant,
        order,
        delivery,
        cc,
        dirty,
        firebase,
    },
    plugins: [credentialPersister, googleMaps],
})

const selectTypes = Object.values(selectapiType.clientTypes)
for (let type of selectTypes) {
    Store.registerModule(selectapiType.getModuleName(type), selectapi(type))
}

export default Store
