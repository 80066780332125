import { ROLE_ADMIN } from '@/enum/roles'
import routeType from '@/router/routeType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

const breadCrumbs = {
    list: {
        to: {
            name: routeType.PROMO_BANNERS_LIST,
        },
        label: 'menu.promo_banners',
        roles: [ROLE_ADMIN],
    },
}

export default [
    {
        path: '/promo-banners',
        component: {
            render: (c) => c('router-view'),
        },
        meta,
        children: [
            {
                path: '',
                name: routeType.PROMO_BANNERS_LIST,
                component: () =>
                    import(
                        /* webpackChunkName: "promo-banner-list" */ '@/pages/promoBanners/List.vue'
                    ),
                meta,
            },
            {
                path: 'create',
                name: routeType.PROMO_BANNERS_CREATE,
                component: () =>
                    import(
                        /* webpackChunkName: "promo-banner-edit" */ '@/pages/promoBanners/Create.vue'
                    ),
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumbs.list],
                },
            },
            {
                path: ':id',
                name: routeType.PROMO_BANNERS_EDIT,
                component: () =>
                    import(
                        /* webpackChunkName: "promo-banner-edit" */ '@/pages/promoBanners/Edit.vue'
                    ),
                props: true,
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumbs.list],
                },
            },
        ],
    },
]
