import { Validator } from 'vee-validate'
import { parseNumber, isValidNumber } from 'libphonenumber-js'
import Store from '@/store/store'
import configType from '@/store/type/configType'
import dayjs from '@/plugins/date'

const periodRegex = /^([0-1][0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/

Validator.extend('period-hour-format', {
    getMessage: () => 'Invalid.',
    validate: (value) => {
        return !(!periodRegex.test(value.from) || !periodRegex.test(value.to))
    },
})

Validator.extend('period-from-lt-to', {
    getMessage: () => 'Invalid.',
    validate: (value) => {
        return value.from <= value.to
    },
})

Validator.extend('period-overlap', {
    getMessage: () => 'Invalid.',
    validate: (value, args) => {
        let isValid = true

        args.periods.forEach((period, index) => {
            if (index === args.index) {
                return
            }

            if (period.from > value.from && period.from < value.to) {
                isValid = false
            }

            if (period.to > value.from && period.to < value.to) {
                isValid = false
            }

            if (period.from === value.from && period.to === value.to) {
                isValid = false
            }
        })

        return isValid
    },
})

Validator.extend('phonenumber', {
    getMessage: () => 'Invalid.',
    validate: (value) => {
        const defaultCountry =
            Store.getters[configType.getters.CURRENCY_LOCALE]
                .toUpperCase()
                .split('-')[1] || 'GB'

        const parsed = parseNumber(value, { extended: true, defaultCountry })
        return isValidNumber(parsed)
    },
})

Validator.extend('dateafter', {
    getMessage: () => 'Invalid.',
    validate: (value) => {
        return value >= dayjs().format('YYYY-MM-DD') ? true : false
    },
})

Validator.extend('space', {
    getMessage: () => 'Invalid.',
    validate: (value) => {
        return value.toString().charAt(0) == ' ' ? false : true
    },
})

Validator.extend('invoice-sum', {
    getMessage: () => 'Invalid.',
    validate: (value, args) => {
        let sum = 0
        args.forEach((arg) => {
            sum += parseFloat(arg)
        })
        return sum === parseFloat(value)
    },
})
