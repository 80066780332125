import cloneDeep from 'lodash/cloneDeep'

class InvoicesFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)
        if (entity.company) entity['company'] = entity['company']['@id']
        if (entity.restaurant)
            entity['restaurant'] = entity['restaurant']['@id']

        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)
        values.periodStart = values.periodStart.substr(0, 10)
        values.periodEnd = values.periodEnd.substr(0, 10)

        return values
    }
}

export default new InvoicesFormMapper()
