import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'

class CompanyCloudcanteens extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/cloud-canteens'
    }

    listUserBudgets(userId, query) {
        return ResponseHandler.handle(
            apiClient.get(`${this.getBaseUrl()}/budgets-for-user/${userId}`, {
                params: query,
            })
        )
    }

    updateUserBudget(canteenId, credits, userId) {
        return ResponseHandler.handle(
            apiClient.post(`${this.getBaseUrl()}/budgets-for-user/${userId}`, {
                canteenId,
                credits,
            })
        )
    }
}

export default new CompanyCloudcanteens()
