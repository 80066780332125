<template lang="pug">
    v-text-field(
        v-model="search"
        hide-details="auto"
        :label="label"
        :readonly="busy"
        prepend-inner-icon="search"
        class="search-field"
        background-color="white"
        outlined
        dense)
        span(
            slot="append"
            v-if="search !== ''"
            class="clear-link"
            @click.prevent="search = ''")
            v-icon clear
</template>

<script>
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        field: {
            type: Object,
            default: () => {},
        },
        busy: {
            type: Boolean,
        },
    },
    data() {
        return {
            search: '',
        }
    },
    computed: {
        key() {
            return this.field?.key || '_search'
        },
        type() {
            return this.field?.type
        },
        label() {
            return this.field?.label
        },
    },
    watch: {
        search: debounce(function () {
            const filters = cloneDeep(this.value)
            const searchValue = cloneDeep(this.search)

            if (searchValue === '') {
                if (this.type) {
                    delete filters[this.key][this.type]
                    if (!Object.keys(filters[this.key]).length)
                        delete filters[this.key]
                } else {
                    delete filters[this.key]
                }
            } else {
                if (this.type) {
                    if (!filters[this.key]) filters[this.key] = {}
                    filters[this.key][this.type] = searchValue
                } else filters[this.key] = searchValue
            }

            this.$emit('input', filters)
        }, 800),
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                if (this.type)
                    this.search = value?.[this.key]?.[this.type] || ''
                else this.search = value?.[this.key] || ''
            },
        },
    },
}
</script>

<style scoped>
.search-field {
    max-width: 500px;
}

.clear-link {
    position: absolute;
    right: 4px;
    cursor: pointer;
}
</style>
