<script>
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import AddressAutocompleteInput from '@/components/form/Inputs/google/AddressAutocompleteInput'
import AddressMapInput from '@/components/form/Inputs/google/AddressMapInput'
import filter from './filter'

export default {
    components: { AddressAutocompleteInput, AddressMapInput },
    mixins: [filter],
    data() {
        return {
            valueCache: {},
        }
    },
    computed: {
        hasInputValue() {
            return true
        },
        latitude() {
            return this.valueCache?.lat
        },
        longitude() {
            return this.valueCache?.lon
        },
        updateLater() {
            return debounce(this.updateLocation, 5)
        },
    },
    watch: {
        value: {
            handler: function (val) {
                if (val[this.keyEnd]) {
                    this.valueCache = {
                        lat: parseFloat(val[this.keyEnd]?.lat),
                        lon: parseFloat(val[this.keyEnd]?.lon),
                    }
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        setLatitude(coord) {
            if (coord === this.valueCache.lat) return

            this.valueCache.lat = coord
            if (!coord) delete this.valueCache.lat
            this.updateLater()
        },
        setLongitude(coord) {
            if (coord === this.valueCache.lon) return

            this.valueCache.lon = coord
            if (!coord) delete this.valueCache.lon
            this.updateLater()
        },
        updateLocation() {
            let value = cloneDeep(this.value)
            if (Object.keys(this.valueCache).length) {
                value[this.keyEnd] = cloneDeep(this.valueCache)
            } else {
                delete value[this.keyEnd]
            }
            this.$emit('input', value)
        },
    },
}
</script>

<template>
    <div class="mb-2">
        <address-autocomplete-input
            :value="internalValue"
            :label="field.label"
            :latitude="latitude"
            :longitude="longitude"
            @input="setValue"
            @update:latitude="setLatitude"
            @update:longitude="setLongitude"
        ></address-autocomplete-input>
        <address-map-input
            :value="internalValue"
            style="height: 250px"
            :latitude="latitude"
            :longitude="longitude"
            @input="setValue"
            @update:latitude="setLatitude"
            @update:longitude="setLongitude"
        ></address-map-input>
    </div>
</template>
