import Vue from 'vue'
import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'
import authType from '@/store/type/authType'
import { ROLE_ADMIN } from '@/enum/roles'
import selectapiType from '@/store/type/selectapiType'
import Config from '@/config.loader'

const UPD_TIMEOUT = 700
const restaurantGetter = `${selectapiType.getModuleName(
    selectapiType.clientTypes.TABLET_RESTAURANTS
)}/${selectapiType.getters.VALUES}`
const userGetter = `${selectapiType.getModuleName(
    selectapiType.clientTypes.TABLET_USERS
)}/${selectapiType.getters.VALUES}`
let timeout = null

const subscribeToFirebaseTopic = (topic, commit, dispatch, mutation) => {
    const throttledCommit = throttle(
        (snapshot) => {
            const data = snapshot.val()
            dispatch(mutation, data)
            console.log(`FB throttled ${mutation}`, new Date().getTime())
        },
        3000,
        {
            leading: true,
            trailing: true,
        }
    )

    Vue.prototype.$fbdb.ref(topic).on('value', (snapshot) => {
        try {
            throttledCommit(snapshot)
            // if (mutation === 'setClientConfigs') throttledLookup()
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(`Failed subscribing ${topic} to topic`)
        }
    })
}

const dbRoute = Config.getConfigValue('VUE_APP_FB_DB_ROUTE')

const updateAction = (prop, mutation, { commit, state }, data) => {
    const updated = []
    for (let key in state[prop]) {
        if (!isEqual(state[prop][key], data[key])) updated.push(key)
    }
    commit(mutation, data)
    commit('setUpdated', updated)
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
        commit('setUpdated', [])
    }, UPD_TIMEOUT)
}

export default {
    namespaced: true,
    state: {
        isInited: false,
        isSubscribed: false,
        clientConfigs: {},
        clientStatuses: {},
        clientSunmis: {},
        updated: [],
        users: {},
        restaurants: {},
    },

    mutations: {
        setInited: (state, v) => (state.isInited = v),

        setSubscribed: (state, v) => (state.isSubscribed = v),

        setClientConfigs: (state, data) => {
            const updated = []
            for (let key in state.clientConfigs) {
                if (!isEqual(state.clientConfigs[key], data[key]))
                    updated.push(key)
            }
            state.clientConfigs = data
        },

        setClientStatuses: (state, data) => {
            const updated = []
            for (let key in state.clientStatuses) {
                if (!isEqual(state.clientStatuses[key], data[key]))
                    updated.push(key)
            }
            state.clientStatuses = data
        },

        setClientSunmis: (state, data) => {
            const updated = []
            for (let key in state.clientSunmis) {
                if (!isEqual(state.clientSunmis[key], data[key]))
                    updated.push(key)
            }
            state.clientSunmis = data
        },

        setUpdated: (state, data) => (state.updated = data),

        setUsers: (state, data) => (state.users = data),

        setRestaurants: (state, data) => (state.restaurants = data),
    },

    actions: {
        async init({ state, commit, dispatch, rootGetters }) {
            const restaurantsTask = dispatch(
                selectapiType.getActionName(
                    selectapiType.clientTypes.TABLET_RESTAURANTS,
                    selectapiType.actions.LOAD
                ),
                null,
                { root: true }
            )
            const usersTask = dispatch(
                selectapiType.getActionName(
                    selectapiType.clientTypes.TABLET_USERS,
                    selectapiType.actions.LOAD
                ),
                null,
                { root: true }
            )
            await Promise.allSettled([restaurantsTask, usersTask])

            const users = {}
            for (let user of rootGetters[userGetter]({})) {
                users[user.id] = user
            }
            commit('setUsers', users)
            const restaurants = {}
            for (let restaurant of rootGetters[restaurantGetter]({})) {
                restaurants[restaurant.id] = restaurant
            }
            commit('setRestaurants', restaurants)

            if (state.isInited) return dispatch('subscribe')

            if (
                rootGetters[authType.getters.GET_ROLES] &&
                Array.isArray(rootGetters[authType.getters.GET_ROLES]) &&
                rootGetters[authType.getters.GET_ROLES].includes(ROLE_ADMIN)
            ) {
                if (await dispatch('login')) {
                    await dispatch('subscribe')
                    commit('setInited', true)
                }
            }
        },

        // @TODO: Extend this, remove hardcoded user, solve user sync with core-api
        async login() {
            if (process.env.VUE_APP_FB_U && process.env.VUE_APP_FB_P)
                return await Vue.prototype.$fbauth
                    .signInWithEmailAndPassword(
                        process.env.VUE_APP_FB_U,
                        process.env.VUE_APP_FB_P
                    )
                    .catch((e) => {
                        // eslint-disable-next-line no-console
                        console.warn('Firebase Auth Failed', e)
                    })
                    .then(() => true)
        },

        async subscribe({ commit, state, dispatch }) {
            if (state.isSubscribed) return

            subscribeToFirebaseTopic(
                `${dbRoute}/status`,
                commit,
                dispatch,
                'setClientStatuses'
            )
            subscribeToFirebaseTopic(
                `${dbRoute}/clients`,
                commit,
                dispatch,
                'setClientConfigs'
            )
            subscribeToFirebaseTopic(
                `${dbRoute}/sunmiv2`,
                commit,
                dispatch,
                'setClientSunmis'
            )
            commit('setSubscribed', true)
        },

        async unsubscribe({ commit, state }) {
            if (!state.isSubscribed) return

            Vue.prototype.$fbdb.ref(`${dbRoute}/status`).off()
            Vue.prototype.$fbdb.ref(`${dbRoute}/clients`).off()
            commit('setSubscribed', false)
        },

        async setConfig(_vuex, params = {}) {
            if (params && params.id && params.key) {
                return await Vue.prototype.$fbdb
                    .ref(`${dbRoute}/clients/${params.id}/${params.key}`)
                    .set(params.value)
            }
        },

        async deleteConfig(_vuex, id) {
            if (id) {
                return Promise.allSettled([
                    Vue.prototype.$fbdb
                        .ref(`${dbRoute}/clients/${id}`)
                        .remove(),
                    Vue.prototype.$fbdb.ref(`${dbRoute}/status/${id}`).remove(),
                    Vue.prototype.$fbdb.ref(`${dbRoute}/sunmi/${id}`).remove(),
                ])
            }
        },

        setClientConfigs: (_vuex, data) => {
            updateAction('clientConfigs', 'setClientConfigs', _vuex, data)
        },

        setClientStatuses: (_vuex, data) => {
            updateAction('clientStatuses', 'setClientStatuses', _vuex, data)
        },

        setClientSunmis: (_vuex, data) => {
            updateAction('clientSunmis', 'setClientSunmis', _vuex, data)
        },
    },

    getters: {
        tabletClients: (state) => {
            return Object.keys(
                JSON.parse(JSON.stringify(state.clientStatuses || {}))
            ).map((el) => {
                const clientConf = state.clientConfigs[el]
                const sunmi = state.clientSunmis[el]
                const updated = state.updated.includes(el)
                const user = state.users[clientConf?.user_id]
                const restaurants = []
                for (let id of user?.restaurants || []) {
                    if (state.restaurants[id])
                        restaurants.push(state.restaurants[id])
                }
                return {
                    id: el,
                    status: state.clientStatuses[el],
                    user,
                    restaurants,
                    updated,
                    ...(clientConf || {}),
                    sunmi,
                }
            })
        },
    },
}
