<template>
    <div class="d-flex align-center">
        <div v-if="isSuperAdmin" class="pr-1">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon
                        :color="item.deliveryAddressDetails.city | lowercase"
                        small
                        v-on="on"
                        >circle</v-icon
                    >
                </template>
                <span>{{ item.deliveryAddressDetails.city }}</span>
            </v-tooltip>
        </div>
        <div>
            <router-link
                v-if="item.userProfile"
                :to="
                    getIdFromIri(
                        item.userProfile,
                        '@id',
                        routeType.USER_EDIT,
                        'userId'
                    )
                "
            >
                {{ item.userProfile | toFullName }}
            </router-link>
            <template v-else>
                {{ item.deliveryName }}
            </template>
            <small
                v-if="
                    item.companyName && item.companyName !== item.deliveryName
                "
                class="d-flex g-73--text"
            >
                {{ item.companyName }}
            </small>
            <small v-if="!item.userProfile" class="d-flex g-73--text">
                {{
                    $t('orders.canteen_details', {
                        portion: meals,
                        customer: customers,
                    })
                }}
            </small>
        </div>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import { getIdFromIri } from '@/services/IdFromIri'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    filters: {
        lowercase: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.toLowerCase()
        },
    },
    mixins: [AuthMixin],
    props: {
        item: null,
    },
    data() {
        return {
            routeType,
        }
    },
    computed: {
        customers() {
            return this.item?.canteenSubOrdersByCutoff?.reduce(
                (acc, cutoff) => acc + cutoff.orderIds.length,
                0
            )
        },
        meals() {
            return this.item?.canteenSubOrdersByCutoff?.reduce(
                (acc, cutoff) => acc + cutoff.mealsQuantity,
                0
            )
        },
    },
    methods: {
        getIdFromIri,
    },
}
</script>
