import cloneDeep from 'lodash/cloneDeep'

export const pickupInformation = {
    longitude: null,
    latitude: null,
    postCode: null,
    country: null,
    city: null,
    street: null,
    houseNumber: null,
    fullAddress: null,
    contactPhone: '',
    contactCompany: '',
    contactEmail: '',
    contactFirstName: '',
    contactLastName: '',
    pickupInstructions: null,
}

export function isPIfilled(pickupInfo) {
    return !!(
        pickupInfo?.longitude ||
        pickupInfo?.latitude ||
        pickupInfo?.postCode ||
        pickupInfo?.country ||
        pickupInfo?.city ||
        pickupInfo?.street ||
        pickupInfo?.houseNumber ||
        pickupInfo?.fullAddress ||
        pickupInfo?.contactPhone ||
        pickupInfo?.contactCompany ||
        pickupInfo?.contactEmail ||
        pickupInfo?.contactFirstName ||
        pickupInfo?.contactLastName ||
        pickupInfo?.pickupInstructions
    )
}

export function getPiClone() {
    return cloneDeep(pickupInformation)
}
