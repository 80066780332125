<template>
    <modal-dialog
        v-model="dialog"
        :title="title ? title : $t('texts.are_you_sure')"
        cancel-button
        :cancel-text="cancelText"
        :ok-text="approveText"
        :ok-color="approveColor"
        :show-overlay="processing"
        class="delivery-cancel-modal"
        @cancel="onCancel"
        @ok="onApprove"
    >
        <div class="cancel-modal-orderid">
            {{ $t('orders.order_id') }}: {{ orderId }}
        </div>

        <v-container class="px-0" fluid>
            <div class="radio-group-row py-2">
                <label class="radio">
                    <span
                        >{{ $t('labels.only_this_delivery_job') }} ({{
                            entityId
                        }})</span
                    >
                    <input
                        v-model="cancelordertype"
                        type="radio"
                        value="orderproviderjob"
                        name="ordercancel"
                    />
                </label>
            </div>
            <hr v-if="entityCount > 1" />
            <div v-if="entityCount > 1" class="radio-group-row py-2">
                <label class="radio">
                    <span
                        >{{ $t('labels.all_delivery_jobs') }} ({{
                            entityCount
                        }}
                        {{ $t('labels.deliveries') }})</span
                    >
                    <input
                        v-model="cancelordertype"
                        type="radio"
                        value="orderjob"
                        name="ordercancel"
                    />
                </label>
            </div>
        </v-container>
    </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/dialog/ModalDialog'

export default {
    components: {
        ModalDialog,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        entityId: {
            type: Number,
            default: null,
        },
        entityCount: {
            type: Number,
            default: null,
        },
        approveText: {
            type: String,
            default: null,
        },
        approveColor: {
            type: String,
            default: null,
        },
        cancelText: {
            type: String,
            default: null,
        },
        content: {
            type: Object,
            default: null,
        },
        processing: {
            type: Boolean,
        },
        orderId: {
            type: String,
        },
    },
    data() {
        return {
            dialog: false,
            cancelordertype: 'orderproviderjob',
        }
    },
    watch: {
        value() {
            this.dialog = this.value
        },
    },
    methods: {
        onApprove() {
            this.$emit('input', false)
            this.$emit('onApprove', this.cancelordertype)
        },
        onCancel() {
            this.$emit('input', false)
            this.$emit('onCancel')
        },
    },
}
</script>
<style>
.cancel-modal-orderid {
    margin-top: -1rem;
    font-weight: 500;
}
.radio-group-row input[type='radio'] {
    float: right;
}
</style>
