<script>
export default {
    props: {
        order: null,
    },
    computed: {
        paidByCard() {
            return (
                this.order?.paymentMethod === 'barion' ||
                this.order?.paymentMethod === 'otp' ||
                this.order?.paymentMethod === 'stripe' ||
                this.order?.paymentMethod === 'knh'
            )
        },
        paidByCompany() {
            return !this.order?.paymentMethod
        },
    },
}
</script>

<template lang="pug">
.paid-by(v-if="order")
    v-row(v-if="paidByCompany")
        v-col(align-self="baseline" offset="1")
            .font-weight-bold {{ $t('orders.paid_by_company') }}
        v-col.text-right(cols="2" align-self="baseline") {{ order.priceSumFirm | currency }}
    v-row(v-if="paidByCard")
        v-col(align-self="baseline" offset="1")
            .font-weight-bold {{ $t('orders.paid_by_card') }}
        v-col.text-right(cols="2" align-self="baseline") {{ order.priceSumUser | currency }}
</template>

<style lang="scss">
.delivery-fee {
    border-top: 1px solid var(--v-g-e8-base);
}
</style>
