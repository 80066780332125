<template lang="pug">
.d-flex.flex-column.align-center
    span(v-if="isPreparing && getTimeText" :class="`${getTimeText.color}--text`") {{ getTimeText.text  }}
    .d-flex.flex-column.align-center.text-body-2.g-73--text(v-else)
        template(v-if="isCanteen") {{ $t('orders.cutoff')}}   
        template(v-if="restaurantManaged") {{ $t('orders.delivery_by') }} 
        template(v-else) {{ $t('orders.pickup_at') }}
        span.text-h6.font-weight-bold(:inner-html.prop="isCanteen ? order.cutoffTime : deadline | toSchedule")
</template>

<script>
import Delivery from '@/mixins/Delivery'

export default {
    mixins: [Delivery],
}
</script>
