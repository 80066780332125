<script>
import PageTitle from '@/components/layout/components/pageTitle'
import OrdersTable from '@/components/pages/orders/Table'
import Export from '@/pages/orders/data/Export'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: {
        Export,
        PageTitle,
        OrdersTable,
    },
    mixins: [AuthMixin],
    data() {
        return {
            selectedFilters: {},
            selectedItems: [],
        }
    },
}
</script>

<template lang="pug">
div
    page-title {{ $t('orders.list_page_title') }}
    orders-table(:selected-filters.sync="selectedFilters" :selected-items.sync="selectedItems")
    portal(to="footer")
        export.mr-2(v-if="isSuperAdmin" :selected-filters="selectedFilters" :selected-items="selectedItems")
</template>
