import { ROLE_ADMIN } from '@/enum/roles'
import routeType from '@/router/routeType'
import SelectApiClientType from '@/api/SelectApiClientType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

const breadCrumbs = {
    tagGroupList: {
        to: {
            name: routeType.TAG_GROUP_LIST,
        },
        label: 'menu.tag_groups',
    },
    tagGroupEdit: {
        to: {
            name: routeType.TAG_GROUP_EDIT,
            query: { tab: 0 },
        },
        label: {
            selectType: SelectApiClientType.RESTAURANT_TAG_GROUPS,
            idParamName: 'tagGroupId',
        },
    },
}

const passthroughRender = {
    render: (c) => c('router-view'),
}

export default [
    {
        path: '/tag-groups',
        component: passthroughRender,
        meta,
        children: [
            {
                path: '',
                name: routeType.TAG_GROUP_LIST,
                component: () =>
                    import(
                        /* webpackChunkName: "tag-groups-list" */ '@/pages/tagGroups/List.vue'
                    ),
                meta,
            },
            {
                path: 'create',
                name: routeType.TAG_GROUP_CREATE,
                component: () =>
                    import(
                        /* webpackChunkName: "tag-group-edit" */ '@/pages/tagGroups/Create.vue'
                    ),
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumbs.tagGroupList],
                },
            },
            {
                path: ':id',
                name: routeType.TAG_GROUP_EDIT,
                component: () =>
                    import(
                        /* webpackChunkName: "tag-group-edit" */ '@/pages/tagGroups/Edit'
                    ),
                meta: {
                    ...meta,
                    breadcrumb: breadCrumbs.edit,
                },
            },
        ],
    },
]
