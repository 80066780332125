// const ORDER_NEW = 'new'
const ORDER_CANCELLED = 'cancelled'
const ORDER_CANCELLED_AFTER_APPROVAL = 'cancelled_after_approval'
const ORDER_APPROVED = 'approved'
const COURIER_NEW = 'new'
const COURIER_INITIALIZED = 'initialized'
const COURIER_QUEUED = 'queued'
const COURIER_SEARCHING = 'searching'
const COURIER_PICKING = 'picking'
const COURIER_ALMOST_PICKING = 'almost_picking'
const COURIER_WAITING_AT_PICKUP = 'waiting_at_pickup'
const COURIER_CANCELLED = 'cancelled'
const COURIER_ERROR = 'error'
const COURIER_DELIVERING = 'delivering'
const COURIER_ALMOST_DELIVERING = 'almost_delivering'
const COURIER_WAITING_AT_DROPOFF = 'waiting_at_dropoff'
const COURIER_DELIVERED = 'delivered'
const COURIER_FINISHED = 'finished'

const CANCELLED_STATE = [ORDER_CANCELLED, ORDER_CANCELLED_AFTER_APPROVAL]

const DELIVERING_STATE_BEFORE = [
    ORDER_APPROVED,
    COURIER_NEW,
    COURIER_INITIALIZED,
    COURIER_QUEUED,
    COURIER_SEARCHING,
    COURIER_PICKING,
    COURIER_ALMOST_PICKING,
    COURIER_WAITING_AT_PICKUP,
    COURIER_CANCELLED,
    COURIER_ERROR,
]

const DELIVERING_STATE = [
    COURIER_DELIVERING,
    COURIER_ALMOST_DELIVERING,
    COURIER_WAITING_AT_DROPOFF,
]

const DELIVERING_STATE_AFTER = [COURIER_DELIVERED, COURIER_FINISHED]

const COLOR_SUCCESS = 'success'
const COLOR_WARNING = 'error'
const COLOR_DEFAULT = 'black'

const DEADLINE_FORMAT = 'DD MMM, HH:mm'

import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'

export default {
    props: {
        order: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapGetters({
            locale: configType.getters.CURRENT_LOCALE,
            now: configType.getters.NOW,
        }),
        restaurantManaged() {
            return !(
                this.order?.deliveryJob &&
                this.order?.deliveryJob.primaryDeliveryMethod !==
                    'restaurant_managed'
            )
        },
        deadline() {
            if (this.restaurantManaged) return this.order?.requiredDeliveryTime
            switch (this.order?.deliveryJob?.courierStatus) {
                case COURIER_QUEUED:
                case COURIER_SEARCHING:
                    return (
                        this.order?.deliveryJob?.pickupTime ||
                        this.order?.deliveryJob?.etaToPickup ||
                        this.order?.startDeliveryAt
                    )
                default:
                    return (
                        this.order?.deliveryJob?.etaToPickup ||
                        this.order?.deliveryJob?.pickupTime ||
                        this.order?.startDeliveryAt
                    )
            }
        },
        isCanteen() {
            return Boolean(this.order.cutoffTime)
        },
        isPreparing() {
            return !!this.order.preparationConfirmed
        },
        isOngoing() {
            return DELIVERING_STATE_BEFORE.includes(
                this.order?.deliveryJob?.courierStatus
            )
        },
        isDelivering() {
            return DELIVERING_STATE.includes(
                this.order?.deliveryJob?.courierStatus
            )
        },
        isFinished() {
            return DELIVERING_STATE_AFTER.includes(
                this.order?.deliveryJob?.courierStatus
            )
        },
        isCancelled() {
            return CANCELLED_STATE.includes(
                this.order?.deliveryJob?.courierStatus
            )
        },
        isNew() {
            return (
                this.isRecent &&
                this.order?.deliveryJob?.courierStatus === COURIER_NEW
            )
        },
        isInitialized() {
            return (
                this.order?.deliveryJob?.courierStatus === COURIER_INITIALIZED
            )
        },
        isRecent() {
            if (
                this.order?.deliveryJob == null &&
                this.order?.requiredDeliveryTime
            )
                return this.$date(this.order?.requiredDeliveryTime).isAfter(
                    this.$date()
                )
            else return true
        },
        getTimeText() {
            if (!this.locale) return
            if (this.isCancelled) {
                return {
                    color: COLOR_DEFAULT,
                    text: this.$t('orders.delivery.update'),
                }
            } else if (this.isOngoing) {
                if (
                    this.$date(this.now)
                        .add(2, 'minute')
                        .isBefore(this.deadline)
                ) {
                    const deadline = this.$date(this.deadline)
                    if (this.$date(this.deadline).isSame(this.now, 'day')) {
                        if (deadline.diff(this.now, 'minute') <= 60)
                            return {
                                color: COLOR_SUCCESS,
                                text: `${deadline.diff(
                                    this.now,
                                    'minute'
                                )} ${this.$t('labels.min')}`,
                            }
                        else
                            return {
                                color: COLOR_SUCCESS,
                                text: `${this.$t(
                                    'labels.today'
                                )} ${deadline.format('HH:mm')}`,
                            }
                    } else if (
                        deadline.isSame(
                            this.$date(this.now).add(1, 'days'),
                            'day'
                        )
                    ) {
                        return {
                            color: COLOR_SUCCESS,
                            text: `${this.$t(
                                'labels.tomorrow'
                            )} ${deadline.format('HH:mm')}`,
                        }
                    } else
                        return {
                            color: COLOR_SUCCESS,
                            text: deadline.format(DEADLINE_FORMAT),
                        }
                } else {
                    return {
                        color: COLOR_WARNING,
                        text: this.$t('orders.delivery.now'),
                    }
                }
            } else if (this.isDelivering) {
                return {
                    color: COLOR_DEFAULT,
                    text: this.$t('orders.delivery.out'),
                }
            } else if (this.isFinished) {
                const dropOff =
                    this.order.deliveryJob?.dropoffFinishedAt ||
                    this.order.requiredDeliveryTime
                return {
                    color: COLOR_DEFAULT,
                    text: this.$t('orders.delivery.delivered', {
                        time: dropOff
                            ? this.$date(dropOff).format(DEADLINE_FORMAT)
                            : '',
                    }),
                }
            } else {
                return
            }
        },
    },
}
