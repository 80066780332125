import { ROLE_ADMIN, ROLE_COMPANY, ROLE_RESTAURANT } from '@/enum/roles'
import routeType from '@/router/routeType'
import SelectApiClientType from '@/api/SelectApiClientType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

const breadCrumbs = {
    userList: {
        to: {
            name: routeType.USER_LIST,
        },
        label: 'menu.users',
    },
    userEdit: {
        to: {
            name: routeType.USER_EDIT,
            query: { tab: 0 },
        },
        label: {
            selectType: SelectApiClientType.USER,
            idParamName: 'userId',
        },
    },
    addressList: {
        to: {
            name: routeType.USER_ADDRESS_LIST,
        },
        label: 'labels.addresses',
    },
}

const passthroughRender = {
    render: (c) => c('router-view'),
}

const userAddress = {
    path: 'addresses',
    component: passthroughRender,
    meta,
    children: [
        {
            path: '',
            name: routeType.USER_ADDRESS_LIST,
            component: () =>
                import(
                    /* webpackChunkName: "user-address-list" */ '@/pages/users/addresses/List.vue'
                ),
            props: true,
            meta: {
                ...meta,
                roles: [ROLE_ADMIN, ROLE_COMPANY],
            },
        },
        {
            path: 'create',
            name: routeType.USER_ADDRESS_CREATE,
            component: () =>
                import(
                    /* webpackChunkName: "user-address-edit" */ '@/pages/users/addresses/Create.vue'
                ),
            props: true,
            meta: {
                ...meta,
                breadcrumbs: [breadCrumbs.userEdit, breadCrumbs.addressList],
            },
        },
        {
            path: ':addressId',
            name: routeType.USER_ADDRESS_EDIT,
            component: () =>
                import(
                    /* webpackChunkName: "user-address-edit" */ '@/pages/users/addresses/Edit.vue'
                ),
            props: true,
            meta: {
                ...meta,
                breadcrumbs: [breadCrumbs.userEdit, breadCrumbs.addressList],
            },
        },
    ],
}

export default [
    {
        path: '/users',
        component: passthroughRender,
        meta,
        children: [
            {
                path: '',
                name: routeType.USER_LIST,
                component: () =>
                    import(
                        /* webpackChunkName: "user-list" */ '@/pages/users/List.vue'
                    ),
                meta,
            },
            {
                path: 'create',
                name: routeType.USER_CREATE,
                component: () =>
                    import(
                        /* webpackChunkName: "user-edit" */ '@/pages/users/Create.vue'
                    ),
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumbs.userList],
                    roles: [ROLE_ADMIN, ROLE_COMPANY],
                },
            },
            {
                path: ':userId',
                component: passthroughRender,
                props: true,
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumbs.userList],
                },
                children: [
                    {
                        path: '',
                        name: routeType.USER_EDIT,
                        component: () =>
                            import(
                                /* webpackChunkName: "user-edit" */ '@/pages/users/Edit.vue'
                            ),
                        props: true,
                        meta: {
                            ...meta,
                            roles: [ROLE_ADMIN, ROLE_RESTAURANT, ROLE_COMPANY],
                        },
                    },
                    userAddress,
                    {
                        path: 'balances',
                        name: routeType.COMPANY_BALANCE_LIST,
                        component: () =>
                            import(
                                /* webpackChunkName: "company-balances" */ '@/pages/users/balances/List'
                            ),
                        props: true,
                        meta,
                    },
                    {
                        path: 'canteenbalances',
                        name: routeType.CANTEEN_BALANCE_LIST,
                        component: () =>
                            import(
                                /* webpackChunkName: "canteen-balances" */ '@/pages/users/balances/CanteenList'
                            ),
                        props: true,
                        meta,
                    },
                ],
            },
        ],
    },
]
