<template>
    <strong-confirm-modal
        :value="value"
        :title="$t('labels.cancel_order')"
        :approve-disabled="!isValid"
        scrollable
        approve-color="red"
        @input="$emit('input', $event)"
        @onApprove="cancelOrder"
    >
        <v-radio-group v-model="reasonType" hide-details="auto">
            <v-radio
                v-for="reason_obj in reasons"
                :key="reason_obj.value"
                :value="reason_obj.value"
                :label="reason_obj.label"
            ></v-radio>
        </v-radio-group>
        <text-input
            v-model="reason"
            label="labels.order_cancel_reason"
            :disabled="!reasonType"
        ></text-input>
        <checkbox-input
            v-model="sendEmail"
            label="labels.send_email"
        ></checkbox-input>
        <overlay :show="!reasonType" :progress="false">
            <wyswyg-input
                v-if="sendEmail"
                v-model="cancelEmailText"
                :editor-options="actionNames"
                class="mt-3"
                label="labels.cancel_other_email_text"
            />
        </overlay>
        <div v-if="hasFirmPayment">
            <select-input
                v-model="selectReason"
                :items="orderReasons"
                label="orders.refund_reason"
                hide-details="auto"
                @input="updateReason"
            ></select-input>
            <text-input
                v-show="selectReason === 'other'"
                v-model="refundReason"
                hide-details="auto"
            ></text-input>
            <select-input
                v-model="refundOnBehalf"
                label="orders.on_behalf_of"
                :items="behalfOptions"
            ></select-input>
        </div>
    </strong-confirm-modal>
</template>

<script>
import OrdersApiClient from '@/api/RestApi/OrdersApiClient'
import StrongConfirmModal from '@/components/confirm/StrongConfirmModal'
import TextInput from '@/components/form/Inputs/TextInput'
import SelectInput from '@/components/form/Inputs/SelectInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import Overlay from '@/components/overlay/Overlay'
import WyswygInput from '@/components/form/Inputs/WyswygInput'
import { orderReasons } from './tabs/refund/helpers'

const reasons = [
    'not_confirmed',
    'stock_issue',
    'technical_issue',
    'restaurant_cancelled',
    'customer_cancelled',
    'other',
]

const actionNames = [
    'heading1',
    'heading2',
    'paragraph',
    'bold',
    'italic',
    'underline',
    'olist',
    'ulist',
    'link',
]

export default {
    components: {
        StrongConfirmModal,
        TextInput,
        SelectInput,
        CheckboxInput,
        Overlay,
        WyswygInput,
    },

    props: {
        value: Boolean,
        orderId: {
            type: Number,
            required: true,
            default: 0,
        },
        hasFirmPayment: Boolean,
    },

    data() {
        return {
            reasonType: null,
            reason: null,
            cancelEmailText: null,
            selectReason: null,
            refundReason: null,
            refundOnBehalf: null,
            processing: false,
            actionNames,
        }
    },

    computed: {
        reasons() {
            return reasons.map((reason) => ({
                value: reason,
                label: this.$t(`enums.order_cancel_reasons.${reason}`),
            }))
        },

        isValid() {
            return (
                this.reasonType !== null &&
                (this.reasonType !== 'other' || this.reason)
            )
        },

        isOther() {
            return this.reasonType === 'other'
        },

        sendEmail: {
            get() {
                return this.cancelEmailText !== null
            },
            set(value) {
                if (value) {
                    this.cancelEmailText = this.getDefaultCancelEmailText(
                        this.reasonType
                    )
                } else {
                    this.cancelEmailText = null
                }
            },
        },

        orderReasons() {
            return orderReasons.map((value) => {
                return {
                    title: this.$t(`orders.refund_reasons.${value}`),
                    value,
                }
            })
        },

        behalfOptions() {
            return ['ordit', 'restaurant', 'logistics'].map((value) => {
                return {
                    title: this.$t(`enums.refund_on_behalf.${value}`),
                    value,
                }
            })
        },
    },

    watch: {
        value() {
            this.reasonType = null
            this.reason = null
            this.cancelEmailText = null
            this.processing = false
        },
        reasonType(reasonType) {
            if (this.cancelEmailText) {
                this.cancelEmailText = this.getDefaultCancelEmailText(
                    reasonType
                )
            }
        },
        cancelEmailText() {
            this.cancelEmailText = this.filterCancelEmailText()
        },
    },

    methods: {
        cancelOrder() {
            if (!this.orderId) return
            this.processing = true
            const payload = {
                reasonType: this.reasonType,
                reason: this.reason,
                cancelEmailText: this.filterCancelEmailText(true),
            }

            if (this.hasFirmPayment) {
                payload.refundReason = this.refundReason
                payload.refundOnBehalf = this.refundOnBehalf
            }

            OrdersApiClient.cancel(this.orderId, payload)
                .then(() => {
                    this.$emit('order-cancelled')
                })
                .finally(() => {
                    this.processing = false
                })
        },
        getDefaultCancelEmailText(reasonType) {
            return reasonType
                ? this.$t(`order_cancel.${reasonType}`)
                : this.$t('texts.cancel_order_email_placeholder')
        },
        filterCancelEmailText(filterEmpty = false) {
            if (!this.cancelEmailText) return null

            const doc = new DOMParser().parseFromString(
                this.cancelEmailText,
                'text/html'
            )

            doc.querySelectorAll('a, b, i, u, em, strong, span').forEach(
                (el) => {
                    el.style = ''
                }
            )
            doc.querySelectorAll('h1, h2').forEach((el) => {
                el.style = 'margin-top:.5em;margin-bottom:1em;'
            })

            doc.querySelectorAll('p').forEach((el) => {
                el.style =
                    'margin-bottom:25px;margin-top:0;font-size:16px;line-height:1;'
            })

            doc.querySelectorAll('li').forEach((el) => {
                el.style =
                    'margin-bottom:10px;margin-top:0;font-size:16px;line-height:1;'
            })

            if (filterEmpty) {
                doc.querySelectorAll('body *').forEach(
                    (el) =>
                        el.textContent.trim() === '' &&
                        el.parentNode.removeChild(el)
                )
            }

            const content = doc.body.innerHTML || ''
            return content
        },

        updateReason() {
            if (this.selectReason !== 'other') {
                this.refundReason = this.$t(
                    `orders.refund_reasons.${this.selectReason}`
                )
            } else {
                this.refundReason = ''
            }
        },
    },
}
</script>
