import DataIterator from '@/components/table/DataIterator'

export default {
    data() {
        return {
            filters: {},
        }
    },
    components: {
        DataIterator,
    },
}
