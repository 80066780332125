import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import apiClient from '@/api/ApiClient'
import ResponseHandler from '@/api/response/ResponseHandler'
import SelectApiClientType from '@/api/SelectApiClientType'

class CompanyAllowances extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/company-allowances'
    }

    activate(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), { isActive: true })
        )
    }

    deactivate(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), { isActive: false })
        )
    }

    getRelatedSelectApi() {
        return SelectApiClientType.COMPANY_ALLOWANCE
    }
}

export default new CompanyAllowances()
