export default {
    getters: {
        ACTIVE_ORDER: 'activeOrder',
        NEW_ORDERS_LIST: 'newOrdersList',
        HISTORY_ORDERS_LIST: 'historyOrdersList',
        APPROVED_ORDERS_LIST: 'approvedOrdersList',
        CONFIRMED_ORDERS_LIST: 'confirmedOrdersList',
        APPROVE_OPEN: 'approveOpen',
        CONFIRM_OPEN: 'prepareConfirmOpen',
        ORDER_BY_ID: 'orderById',
        SUBORDERS: 'suborders',
        BASE_ORDERS: 'baseOrders',
    },
    mutations: {
        SET_INCOMING_CONFIRM_STATE: 'setIncomingConfirmState',
        SET_CONFIRM_STATE: 'setPrepareConfirmState',
        SET_ACTIVE_ORDER: 'setActiveOrder',
        SET_NEW_ORDERS_LIST: 'setNewOrdersList',
        UPDATE_NEW_ORDERS_LIST: 'updateNewOrdersList',
        REMOVE_ORDER_FROM_NEW_ORDERS_LIST: 'removeOrderFromNewOrdersList',
        SET_ACTIVE_ORDERS_LIST: 'setActiveOrdersList',
        UPDATE_ACTIVE_ORDERS_LIST: 'updateActiveOrdersList',
        UPDATE_ACTIVE_ORDER: 'updateActiveOrder',
        SET_HISTORY_ORDERS_LIST: 'setHistoryOrderList',
        SET_LOADING: 'setLoading',
        ADD_SUBORDERS: 'addSuborders',
        ADD_BASE_ORDER: 'addBaseOrder',
        SET_NEXT_CUTOFF: 'setNextCutoff',
    },
    actions: {
        GET_ORDER: 'getOrder',
        GET_NEW_ORDERS: 'getNewOrders',
        GET_ORDERS: 'getOrders',
        GET_ORDERS_EVENT: 'getOrdersEvent',
        GET_ACTIVE_ORDERS: 'getActiveOrders',
        SET_HISTORY_ORDERS_LIST: 'setHistoryOrderList',
        APPROVE_ORDER: 'approveOrder',
        CONFIRM_ORDER: 'confirmOrder',
        UPDATE_NEW_ORDERS_LIST: 'updateNewOrdersList',
        UPDATE_CUTOFF_TIMER: 'updateCutoffTimer',
    },
    loaders: {
        APPROVE_ORDER_LOADING: 'approveOrderLoading',
        CONFIRM_ORDER_LOADING: 'confirmOrderLoading',
        GET_NEW_ORDERS_LOADING: 'getNewOrdersLoading',
        GET_ACTIVE_ORDERS_LOADING: 'getActiveOrdersLoading',
        GET_ORDER_LOADING: 'getOrderLoading',
    },
}
