<template lang="pug">
div(v-if="isSuperAdmin")
    v-chip(v-if="thirdPartyMenuDisabled" small color="red" class="rounded px-2 py-2 mb-1 white--text w-full")
        | {{ $t('tp_diff.order_menu_disabled') }}
</template>

<script>
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    mixins: [AuthMixin],
    props: {
        item: null,
    },
    computed: {
        thirdPartyMenuDisabled() {
            let result = false
            if (
                this.item.thirdPartyDifferences?.length &&
                this.item.status === 'new'
            ) {
                this.item.thirdPartyDifferences?.[0].diffs?.forEach((diff) => {
                    if (diff.key === 'menu_disabled' && diff.remote === true) {
                        result = true
                    }
                })
            }
            return result
        },
    },
}
</script>
