export default {
    getters: {
        GET_USERS: 'get_users',
        GET_COMPANIES: 'get_companies',
    },
    mutations: {
        SET_USER: 'set_user',
        SET_COMPANY: 'set_company',
        SET_LOADER: 'set_loader',
    },
    actions: {
        GET_USER: 'get_user',
        GET_COMPANY: 'get_company',
    },
}
