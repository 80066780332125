import { ROLE_ADMIN } from '@/enum/roles'
import routeType from '@/router/routeType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

const breadCrumbs = {
    tagList: {
        to: {
            name: routeType.TAG_LIST,
        },
        label: 'menu.tags',
    },
    tagEdit: {
        to: {
            name: routeType.TAG_EDIT,
            query: { tab: 0 },
        },
        label: 'labels.tag_edit',
    },
}

const passthroughRender = {
    render: (c) => c('router-view'),
}

export default [
    {
        path: '/tags',
        component: passthroughRender,
        meta,
        children: [
            {
                path: '',
                name: routeType.TAG_LIST,
                component: () =>
                    import(
                        /* webpackChunkName: "tag-list" */ '@/pages/tags/List.vue'
                    ),
                meta,
            },
            {
                path: 'create',
                name: routeType.TAG_CREATE,
                component: () =>
                    import(
                        /* webpackChunkName: "tag-edit" */ '@/pages/tags/Create.vue'
                    ),
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumbs.tagList],
                },
            },
            {
                path: ':id',
                name: routeType.TAG_EDIT,
                component: () =>
                    import(
                        /* webpackChunkName: "tag-edit" */ '@/pages/tags/Edit.vue'
                    ),
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumbs.tagEdit],
                },
            },
        ],
    },
]
