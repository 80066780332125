<template>
    <v-row>
        <v-col cols="7" class="py-0">
            <select-input
                v-model="valueDayOffset"
                :label="label"
                :items="selectItems"
                :hint="valueHint"
                :disabled="disabled"
            ></select-input>
        </v-col>
        <v-col cols="5" class="py-0">
            <date-picker-input
                v-model="valueTime"
                :name="name"
                :error-messages="
                    validationMessage ? [validationMessage] : errorMessages
                "
                only-time
                :editable="!disabled"
            ></date-picker-input>
        </v-col>
    </v-row>
</template>

<script>
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'
import DatePickerInput from './DatePickerInput'
import SelectInput from './SelectInput'
import {
    TIME_FORMAT,
    DATE_FORMAT,
    DATE_TIME_FORMAT,
} from '@/const/DateTimeFormats'

export default {
    components: {
        DatePickerInput,
        SelectInput,
    },
    mixins: [ErrorMsgInputMixin],
    props: {
        required: Boolean,
        min: String,
        max: String,
        invalidDate: Boolean,
        value: {
            type: String,
            default: null,
        },
        reference: {
            type: String,
            required: true,
        },
        selectLabel: {
            type: String,
            default: 'day_before_delivery',
        },
        showActualDate: {
            type: Boolean,
            default: true,
        },
        disabled: Boolean,
        relativeError: Boolean,
    },
    data() {
        return {
            valid: false,
            validationMessage: undefined,
        }
    },
    computed: {
        referenceDate() {
            return this.$date(this.$date(this.reference).format(DATE_FORMAT))
        },
        valueDate() {
            return this.$date(this.value || undefined)
        },
        valueDayOffset: {
            get() {
                return this.$date(this.valueDate.format(DATE_FORMAT)).diff(
                    this.referenceDate,
                    'd'
                )
            },
            set(newDayOffset) {
                const offset = this.referenceDate.add(newDayOffset, 'd')
                this.$emit(
                    'input',
                    offset
                        .set('hour', this.valueDate.get('hour'))
                        .set('minute', this.valueDate.get('minute'))
                        .format()
                )
            },
        },
        valueTime: {
            get() {
                return this.valueDate.format(TIME_FORMAT)
            },
            set(newTime) {
                const offset = this.referenceDate.add(this.valueDayOffset, 'd')
                const timeParts = newTime.split(':')
                this.$emit(
                    'input',
                    offset
                        .set('hour', parseInt(timeParts?.[0]))
                        .set('minute', parseInt(timeParts?.[1]))
                        .format()
                )
            },
        },
        selectItems() {
            const items = []

            for (let i = 0; i < 8; i++) {
                items.push({
                    title: this.$tc(`relative_date.${this.selectLabel}`, i, {
                        n: i,
                    }),
                    value: -i,
                })
            }

            return items
        },
        minDate() {
            return this.min ? this.$date(this.min) : undefined
        },
        maxDate() {
            return this.max ? this.$date(this.max) : undefined
        },
        valueHint() {
            return this.showActualDate
                ? this.valueDate.format(DATE_TIME_FORMAT)
                : undefined
        },
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler() {
                this.validate()
            },
        },
        minDate(minDate) {
            if (minDate) this.validate()
        },
        maxDate(maxDate) {
            if (maxDate) this.validate()
        },
    },
    methods: {
        validate() {
            this.validationMessage = undefined
            if (this.min) {
                this.valid = this.valueDate.isSameOrAfter(this.minDate)
                if (!this.valid) {
                    let hour = this.minDate.format(
                        this.invalidDate && !this.relativeError
                            ? DATE_TIME_FORMAT
                            : TIME_FORMAT
                    )

                    if (this.relativeError) {
                        const i = -this.$date(
                            this.minDate.format(DATE_FORMAT)
                        ).diff(this.referenceDate, 'd')
                        const diffTxt = this.$tc(
                            `relative_date.${this.selectLabel}`,
                            i,
                            {
                                n: i,
                            }
                        )
                        hour = `${diffTxt} ${hour}`
                    }

                    this.validationMessage = this.$t(
                        `validations.messages.min-value`,
                        {
                            hour,
                        }
                    )
                }
            }
            if (this.max) {
                this.valid = this.valueDate.isSameOrBefore(this.maxDate)
                if (!this.valid) {
                    let hour = this.maxDate.format(
                        this.invalidDate && !this.relativeError
                            ? DATE_TIME_FORMAT
                            : TIME_FORMAT
                    )

                    if (this.relativeError) {
                        const i = -this.$date(
                            this.maxDate.format(DATE_FORMAT)
                        ).diff(this.referenceDate, 'd')
                        const diffTxt = this.$tc(
                            `relative_date.${this.selectLabel}`,
                            i,
                            {
                                n: i,
                            }
                        )
                        hour = `${diffTxt} ${hour}`
                    }

                    this.validationMessage = this.$t(
                        `validations.messages.max-value`,
                        {
                            hour,
                        }
                    )
                }
            }
        },
    },
}
</script>
