<script>
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import restaurantType from '@/store/type/restaurantType'
import routeInfoType from '@/store/type/routeInfoType'
import Config from '@/config.loader'

export default {
    computed: {
        ...mapGetters({
            userId: authType.getters.USER_ID,
            email: authType.getters.GET_EMAIL,
            companyId: authType.getters.ACTIVE_COMPANY_ID,
            restaurantId: restaurantType.getters.RESTAURANT_ID,
            isSuperAdmin: authType.getters.IS_SUPER_ADMIN,
            loggedIn: authType.getters.IS_LOGGED_IN,
        }),

        model() {
            return {
                guest: false,
                user_id: this.intercomUserId,
                email: this.email,
                company_id: this.companyId,
                restaurant_id: this.restaurantId,
                admin: true,
            }
        },

        intercomUserId() {
            const prefix = Config.getConfigValue('VUE_APP_INTERCOM_PREFIX')
            return `${prefix}${this.userId}`
        },
    },

    watch: {
        loggedIn() {
            this.$intercom.shutdown()
            this.start()
        },
    },

    mounted() {
        this.start()
    },

    methods: {
        start() {
            if (!this.loggedIn || this.isSuperAdmin) {
                return
            }

            const id = `${this.userId}/self`

            this.$store
                .dispatch(routeInfoType.actions.GET_USER, id)
                .then((user) => {
                    this.$intercom.boot(
                        Object.assign({}, this.model, {
                            name: `${user.firstName} ${user.lastName}`,
                            phone: user.phone,
                        })
                    )
                })
        },
    },
}
</script>

<template>
    <div></div>
</template>
