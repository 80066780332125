<template>
    <div class="admin-data-iterator">
        <v-row
            v-if="hasSlot('filters') || hasSlot('actions')"
            justify="space-between"
        >
            <v-col class="pt-0">
                <slot
                    name="filters"
                    :items="items"
                    :busy="busy"
                    :total="total"
                    :query="filters"
                ></slot>
            </v-col>
            <v-col cols="auto" class="pt-3">
                <slot
                    name="actions"
                    :items="items"
                    :busy="busy"
                    :total="total"
                    :query="filters"
                ></slot>
            </v-col>
        </v-row>

        <overlay :show="busy || showOverlay" :color="overlayColor">
            <slot name="before"></slot>
            <slot
                :items="items"
                :busy="busy"
                :total="total"
                :query="filters"
            ></slot>
            <div
                v-if="items.length === 0 && !busy && $slots.noItems"
                class="text-center py-8 text-caption font-italic g-73--text"
            >
                <slot name="noItems"></slot>
            </div>

            <portal to="footer">
                <slot
                    name="batchActions"
                    :items="items"
                    :busy="busy"
                    :total="total"
                    :query="filters"
                ></slot>
            </portal>
            <portal
                v-if="!disablePagination && !paginatorBelowTable"
                to="footer-right"
            >
                <div class="d-flex justify-end my-n1">
                    <div v-if="fixedItemsPerPage == null" class="pt-1">
                        <v-select
                            v-model="itemsPerPage"
                            class="d-inline-block mr-2 per-page-select"
                            hide-details="auto"
                            dense
                            :items="perPageChoices"
                            :rules="[(v) => !!v || 'Item is required']"
                        ></v-select>
                    </div>
                    <div
                        v-if="
                            fixedItemsPerPage == null ||
                            Math.ceil(total / itemsPerPage) > 1
                        "
                    >
                        <v-pagination
                            v-model="page"
                            :length="length"
                            total-visible="7"
                            color="primary"
                        ></v-pagination>
                    </div>
                </div>
            </portal>
            <div
                v-else-if="!disablePagination && paginatorBelowTable"
                class="d-flex justify-end my-2"
            >
                <div v-if="fixedItemsPerPage == null" class="pt-1">
                    <v-select
                        v-model="itemsPerPage"
                        class="d-inline-block mr-2 per-page-select"
                        hide-details="auto"
                        dense
                        :items="perPageChoices"
                        :rules="[(v) => !!v || 'Item is required']"
                    ></v-select>
                </div>
                <div
                    v-if="
                        fixedItemsPerPage == null ||
                        Math.ceil(total / itemsPerPage) > 1
                    "
                >
                    <v-pagination
                        v-model="page"
                        :length="length"
                        total-visible="7"
                        color="primary"
                    ></v-pagination>
                </div>
            </div>

            <slot name="after"></slot>
        </overlay>
    </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import { DEFAULT_PERPAGE, PERPAGE_CHOICES } from '@/defaults'
import RestApiCollection from '@/api/RestApiCollection'
import Overlay from '@/components/overlay/Overlay'
import MapperCollection from '@/services/mapper/MapperCollection'
import { EventBus } from '@/plugins/events'

export default {
    components: {
        Overlay,
    },
    props: {
        api: {
            type: String,
            required: true,
        },
        filters: {
            type: Object,
            default: () => {},
        },
        fixedFilters: {
            type: Object,
            default: () => {
                return {}
            },
        },
        disableQueryParams: {
            type: Boolean,
        },
        fixedItemsPerPage: {
            type: Number,
            default: null,
        },
        disablePagination: {
            type: Boolean,
            default: false,
        },
        mapper: {
            type: String,
            default: null,
        },
        showOverlay: {
            type: Boolean,
            default: false,
        },
        overlayColor: {
            type: String,
            default: undefined,
        },
        paginatorBelowTable: Boolean,
    },
    data() {
        return {
            total: 0,
            page: 1,
            busy: false,
            items: [],
            sentQuery: {},
            updateItemsTimeout: null,
            itemsPerPage: 50,
        }
    },
    computed: {
        perPageChoices() {
            return PERPAGE_CHOICES
        },
        length() {
            return Math.ceil(this.total / this.itemsPerPage)
        },
    },
    watch: {
        filters: {
            deep: true,
            immediate: true,
            handler(newFilters, oldFilters) {
                if (isEqual(newFilters, oldFilters)) return
                if (
                    newFilters?.page === oldFilters?.page &&
                    newFilters?.page !== 1
                )
                    this.page = 1

                this.updateItems()
            },
        },
        itemsPerPage(value) {
            this.$emit('update:filters', {
                ...this.filters,
                ...{ itemsPerPage: value },
            })
        },
        page(value) {
            this.$emit('update:filters', {
                ...this.filters,
                ...{ page: value },
            })
        },
    },
    created() {
        EventBus.$on('updateList', this.updateList)

        this.itemsPerPage = DEFAULT_PERPAGE
        if (
            !this.disableQueryParams &&
            !isEqual(this.$route.query, {
                ...this.filters,
                ...{
                    page: 1,
                    itemsPerPage: DEFAULT_PERPAGE,
                },
            })
        ) {
            const query = {
                ...this.filters,
                ...{
                    page: 1,
                    itemsPerPage: DEFAULT_PERPAGE,
                },
                ...this.$route.query,
            }

            this.page = parseInt(query.page)
            this.itemsPerPage = parseInt(query.itemsPerPage)
            this.$emit('update:filters', query)
        }

        if (this.fixedItemsPerPage !== null) {
            this.itemsPerPage = this.fixedItemsPerPage
        }
    },
    beforeDestroy() {
        EventBus.$off('updateList', this.updateList)
    },
    methods: {
        hasSlot(name) {
            return !!this.$slots[name] || !!this.$scopedSlots[name]
        },
        updateList(api) {
            if (api === this.api) this.updateItems(true)
        },
        updateItems(update = false, onlyReload = false) {
            return new Promise((resolve) => {
                const query = { ...this.filters, ...this.fixedFilters }
                if (
                    isEqual(query, this.sentQuery) &&
                    !update &&
                    this.items.length
                ) {
                    resolve()
                    return
                }

                this.busy = true
                this.sentQuery = query

                if (this.updateItemsTimeout !== null)
                    clearTimeout(this.updateItemsTimeout)
                this.updateItemsTimeout = setTimeout(() => {
                    if (
                        !this.disableQueryParams &&
                        !onlyReload &&
                        !isEqual(this.$route.query, this.filters)
                    ) {
                        this.$router.replace({
                            query: this.filters,
                        })
                    }

                    RestApiCollection.get(this.api)
                        .list(query)
                        .then((data) => {
                            this.items = this.mapper
                                ? MapperCollection.get(this.mapper).map(
                                      data['hydra:member']
                                  )
                                : data['hydra:member']
                            this.total = data['hydra:totalItems']
                            this.$emit('items-updated')
                        })
                        .finally(() => {
                            resolve()
                            this.busy = false
                        })
                }, 100)
            })
        },
    },
}
</script>

<style lang="scss">
.admin-data-iterator {
    &.dandd-iterator {
        .v-list-item.active {
            background-color: var(--v-g-f0-base);
        }
    }
}

.per-page-select {
    width: 4em;
}
</style>
