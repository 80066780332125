import cloneDeep from 'lodash/cloneDeep'

class CompaniesFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        if (entity.hideAllRestaurants) {
            entity.hiddenRestaurants = []
        } else {
            entity.hideAllExceptions = []
        }

        if (typeof entity.deliveryCompletedTemplate == 'undefined') {
            entity.deliveryCompletedTemplate = null
        }

        if (
            typeof entity.serviceFeePercent === 'number' &&
            isFinite(entity.serviceFeePercent)
        ) {
            entity.serviceFeePercent = entity.serviceFeePercent.toString()
        }

        if (!entity.billingInfo?.groupOrderFee) {
            entity.billingInfo.groupOrderFee = null
        } else {
            entity.billingInfo.groupOrderFee = entity.billingInfo.groupOrderFee.toString()
        }

        if (!entity.orderConfirmationEmail) entity.orderConfirmationEmail = null
        if (!entity.deliveryCompletedEmail) entity.deliveryCompletedEmail = null
        if (!entity.deliveryDriverDeliveringEmail)
            entity.deliveryDriverDeliveringEmail = null

        if (entity.ccPartnerRelations) {
            for (let relation of entity.ccPartnerRelations) {
                relation.deliveryTime = parseInt(relation.deliveryTime)
                if (typeof relation['@id'] === 'number') {
                    delete relation['@id']
                }
            }
        }

        entity.deliveryPriceOverrides.forEach((override) => {
            override.min_order_value =
                override.min_order_value || override.min_order_value === 0
                    ? parseFloat(override.min_order_value)
                    : null
            override.delivery_price =
                override.delivery_price || override.delivery_price === 0
                    ? parseFloat(override.delivery_price)
                    : null
        })
        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)

        if (values.hideAllRestaurants) {
            values.hiddenRestaurants = []
        } else {
            values.hideAllExceptions = []
        }

        if (values.billingInfo === null) {
            values.billingInfo = {}
        }

        return values
    }
}

export default new CompaniesFormMapper()
