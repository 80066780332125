import ResponseHandler from '@/api/response/ResponseHandler'
import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import apiClient from '@/api/ApiClient'
import getAdminSource from '@/services/GetAdminSource'

class OrdersApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/public/orders'
    }

    approve(id, entity) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'approve'), entity, {
                params: getAdminSource(),
            })
        )
    }

    getToken(ccId, restaurantId) {
        return ResponseHandler.handle(
            apiClient.get(
                '/api/v1/cloudcanteen/label/gettoken/' +
                    ccId +
                    '/' +
                    restaurantId
            )
        )
    }

    downloadLabel(ccId, restaurantId, token, cutoffTime) {
        let url = `/frontend-api/public/cloudcanteen/label/view/${ccId}/${restaurantId}?token=${token}`
        if (cutoffTime) {
            url += `&cutoffTime=${cutoffTime}`
        }
        return ResponseHandler.handle(
            apiClient.get(url, {
                transformRequest: [
                    (data, headers) => {
                        delete headers.Authorization
                        return data
                    },
                ],
                responseType: 'blob',
            })
        )
    }

    sendCanteenNotiConfirm(restaurantId, canteenIdentifier, cutoffTime) {
        return ResponseHandler.handle(
            apiClient.put(
                `/public/restaurants/${restaurantId}/confirm-canteen-notification/collected_orders_notification/${canteenIdentifier}`,
                {
                    canteenIdentifier,
                    cutoffTime,
                },
                {
                    params: {
                        cutoffTime,
                        ...getAdminSource(),
                    },
                }
            )
        )
    }
}

export default new OrdersApiClient()
