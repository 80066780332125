import Vue from 'vue'
import Intercom from '@/services/intercom'
import Config from '@/config.loader'

const intercom = new Intercom({
    appId: Config.getConfigValue('VUE_APP_INTERCOM_APPID'),
})

Object.defineProperties(Vue.prototype, {
    $intercom: {
        get() {
            return intercom
        },
    },
})
