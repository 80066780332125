import authType from '@/store/type/authType'
import Cookies from 'js-cookie'

const JWT_KEY = 'jwt'
const REFRESH_TOKEN_KEY = 'refresh'

const storeItem = (key, value, persistent) => {
    const options = {
        sameSite: 'Lax',
    }

    if (persistent) {
        options.expires = 30
    }

    Cookies.set('ordit.' + key, value, options)
}

const getItem = (key) => Cookies.get('ordit.' + key)

const deleteItems = (...keys) => {
    const options = {
        sameSite: 'Lax',
    }

    keys.forEach((key) => {
        Cookies.remove('ordit.' + key, options)
    })
}

const populateStore = (store) => {
    const jwt = getItem(JWT_KEY)

    if (jwt) {
        store.dispatch(authType.actions.LOGIN_WITH_JWT, jwt)
    }

    const refreshToken = getItem(REFRESH_TOKEN_KEY)

    if (refreshToken) {
        store.dispatch(authType.actions.SET_REFRESH_TOKEN, refreshToken)
    }
}

const credentialPersister = (store) => {
    populateStore(store)
    store.subscribe((mutation, state) => {
        if (mutation.type === authType.mutations.SET_JWT) {
            storeItem(JWT_KEY, state.auth.jwt, state.auth.keepMeSignedIn)
        }

        if (mutation.type === authType.mutations.SET_REFRESH_TOKEN) {
            storeItem(
                REFRESH_TOKEN_KEY,
                state.auth.refreshToken,
                state.auth.keepMeSignedIn
            )
        }

        if (mutation.type === authType.mutations.LOGOUT) {
            deleteItems(JWT_KEY, REFRESH_TOKEN_KEY)
        }
    })
}

export default credentialPersister
