import cloneDeep from 'lodash/cloneDeep'

class CompanyTeamsFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)
        if (entity['@id']) entity.company = entity.company['@id']

        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)
        values.employeesIris = []
        if (values.employees.length > 0) {
            values.employees.map((item) =>
                values.employeesIris.push(item['@id'])
            )
        }

        values.employees = values.employeesIris
        delete values.employeesIris

        return values
    }
}

export default new CompanyTeamsFormMapper()
