import { ROLE_ADMIN } from '@/enum/roles'
import routeType from '@/router/routeType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

const breadCrumbs = {
    list: {
        to: {
            name: routeType.PROMOTION_LIST,
        },
        text: 'menu.promotions',
    },
    edit: {
        to: {
            name: routeType.PROMOTION_EDIT,
        },
        label: '...',
    },
}

export default [
    {
        path: '/promotions',
        component: {
            render: (c) => c('router-view'),
        },
        meta: {
            ...meta,
            breadcrumb: breadCrumbs.list,
        },
        children: [
            {
                path: '',
                name: routeType.PROMOTION_LIST,
                component: () =>
                    import(
                        /* webpackChunkName: "promo-list" */ '@/pages/promotions/List.vue'
                    ),
                meta,
            },
            {
                path: 'create',
                name: routeType.PROMOTION_CREATE,
                component: () =>
                    import(
                        /* webpackChunkName: "promo-edit" */ '@/pages/promotions/Create.vue'
                    ),
                meta: {
                    ...meta,
                    breadcrumb: breadCrumbs.edit,
                },
            },
            {
                path: ':id',
                name: routeType.PROMOTION_EDIT,
                component: () =>
                    import(
                        /* webpackChunkName: "promo-edit" */ '@/pages/promotions/Edit.vue'
                    ),
                meta: {
                    ...meta,
                    breadcrumb: breadCrumbs.edit,
                },
            },
        ],
    },
]
