<script>
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import filter from './filter'

export default {
    components: { EntitySelectInput },

    mixins: [filter],

    props: {
        useNameField: {
            type: Boolean,
        },
        useIdField: {
            type: Boolean,
        },
    },
}
</script>

<template>
    <entity-select-input
        :value="internalValue"
        :label="field.label"
        :entity="field.entity"
        :translate-items="field.translate"
        :use-name-field="field.useNameField"
        :use-id-field="useIdField"
        :disabled="blocked"
        :multi-select="field.multiple"
        :query-params="field.queryParams"
        hide-details="auto"
        autocomplete
        filter
        @input="setValue"
    ></entity-select-input>
</template>
