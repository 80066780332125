import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'

class NotificationsApiClient {
    getBaseUrl() {
        return '/api/v1/notification'
    }
    register(token) {
        return ResponseHandler.handle(
            apiClient.post(this.getBaseUrl() + '/register', {
                deviceToken: token,
            })
        )
    }
    test() {
        return ResponseHandler.handle(
            apiClient.get(`api/v1/test-push-notification/new-order/50395`)
        )
    }
    testApprove() {
        return ResponseHandler.handle(
            apiClient.get(`api/v1/test-push-notification/order-accepted/50395`)
        )
    }
}

export default new NotificationsApiClient()
