import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'

class CompanyInvoice extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/file-manager'
    }

    clone(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'clone'))
        )
    }

    getFolders(type, relationId) {
        return ResponseHandler.handle(
            apiClient.get(
                this.getBaseUrl() + '/' + type + '/' + relationId + '/folders'
            )
        )
    }

    createFolder(type, relationId, parentFolderId, title) {
        return ResponseHandler.handle(
            apiClient.post(
                this.getBaseUrl() + '/' + type + '/' + relationId + '/folders',
                {
                    parentFolderId: parentFolderId,
                    title: title,
                }
            )
        )
    }

    modifyFolder(type, relationId, id, title) {
        return ResponseHandler.handle(
            apiClient.put(
                this.getBaseUrl() +
                    '/' +
                    type +
                    '/' +
                    relationId +
                    '/folders/' +
                    id,
                {
                    title: title,
                }
            )
        )
    }

    deleteFolder(type, relationId, id) {
        return ResponseHandler.handle(
            apiClient.delete(
                this.getBaseUrl() +
                    '/' +
                    type +
                    '/' +
                    relationId +
                    '/folders/' +
                    id
            )
        )
    }

    list(type, relationId, folderId) {
        return this.getFolderFiles(type, relationId, folderId)
    }

    getFolderFiles(type, relationId, folderId) {
        return ResponseHandler.handle(
            apiClient.get(
                this.getBaseUrl() +
                    '/' +
                    type +
                    '/' +
                    relationId +
                    '/folders/' +
                    folderId +
                    '/files'
            )
        )
    }

    uploadFiles(type, relationId, folderId, file) {
        return ResponseHandler.handle(
            apiClient.post(
                this.getBaseUrl() +
                    '/' +
                    type +
                    '/' +
                    relationId +
                    '/folders/' +
                    folderId +
                    '/files',
                file,
                {
                    headers: {
                        'content-type': 'multipart/form-data',
                    },
                }
            )
        )
    }

    modifyFile(type, relationId, folderId, fileId, title) {
        return ResponseHandler.handle(
            apiClient.put(
                this.getBaseUrl() +
                    '/' +
                    type +
                    '/' +
                    relationId +
                    '/folders/' +
                    folderId +
                    '/files/' +
                    fileId,
                {
                    title: title,
                },
                {
                    headers: {
                        //    "content-type": 'multipart/form-data',
                    },
                }
            )
        )
    }

    deleteFile(type, relationId, folderId, fileId) {
        return ResponseHandler.handle(
            apiClient.delete(
                this.getBaseUrl() +
                    '/' +
                    type +
                    '/' +
                    relationId +
                    '/folders/' +
                    folderId +
                    '/files/' +
                    fileId
            )
        )
    }

    downloadFile(type, relationId, folderId, fileId) {
        return ResponseHandler.handle(
            apiClient.get(
                this.getBaseUrl() +
                    '/' +
                    type +
                    '/' +
                    relationId +
                    '/folders/' +
                    folderId +
                    '/files/' +
                    fileId +
                    '/download',
                {
                    responseType: 'blob',
                }
            )
        )
    }
}

export default new CompanyInvoice()
