export default {
    getters: {
        CANTEEN: 'canteen',
        CANTEEN_LIST: 'canteenList',
        BALANCES: 'balances',
        BALANCES_LOADED: 'balancesLoaded',
    },
    mutations: {
        SET_CANTEEN: 'setCanteen',
        SET_CANTEEN_LIST: 'setCanteenList',
        UPDATE_CANTEEN_LIST: 'updateCanteenList',
        BALANCE_LOADER: 'balanceLoader',
        BALANCE_VALUES: 'balanceValues',
        BALANCE_INVALIDATE: 'balanceInvalidate',
        REMOVE_CANTEEN: 'removeCanteen',
    },
    actions: {
        LOAD_CANTEN_BALANCES: 'loadBalances',
        REFRESH_BALANCES: 'refreshBalances',
        DELETE_CANTEEN: 'deleteCanteen',
    },
}
