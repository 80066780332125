<template lang="pug">
#app(v-if='translationsAreLoaded' :class='{ inlined: isInlined }')
    v-app
        template(v-if="isRestaurantAdmin && isLoggedIn")
            notification-alert        
            manage-orders
        component(:is="$router.currentRoute.meta.layout ? $router.currentRoute.meta.layout : 'ordit-layout'")
            transition(name='fade' mode='out-in')
                v-main
                    v-container.px-md-6

                        //v-btn(v-if="!isRestaurantAdmin" @click="testMessaging") Test
                        //v-btn(v-if="!isRestaurantAdmin" @click="testApprove") Test approve

                        router-view
    intercom
div(v-else)
    .app-loading-cont
        v-progress-circular(:size="50" color="primary" indeterminate)


</template>

<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import authType from '@/store/type/authType'
import Intercom from '@/components/elements/Intercom'
import NotificationAlert from '@/components/pages/restaurants/NotificationAlert'
import ManageOrders from '@/components/pages/restaurantAdmin/confirm/ManageOrders'

export default {
    name: 'App',
    components: { Intercom, NotificationAlert, ManageOrders },
    computed: {
        ...mapGetters({
            isInlined: configType.getters.INLINED,
            translationsAreLoaded: configType.getters.TRANSLATIONS_ARE_LOADED,
            isLoggedIn: authType.getters.IS_LOGGED_IN,
            isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
        }),
    },
    // methods: {
    //     testMessaging() {
    //         setTimeout(() => {
    //
    //             RestApiCollection.get(RestApiType.NOTIFICATION).test()
    //         }, 5000)
    //     },
    //     testApprove() {
    //         setTimeout(() => {
    //
    //             RestApiCollection.get(RestApiType.NOTIFICATION).testApprove()
    //         }, 5000)
    //     },
    // },
}
</script>

<style lang="scss">
.app-loading-cont {
    width: 55px;
    margin: 0 auto;
    margin-top: 5vh;
}
.v-main {
    min-height: 100vh;
}
</style>
