<template>
    <v-navigation-drawer
        app
        floating
        :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
        :value="value"
        class="ordit-navigation-drawer border-right-g-e8 pt-3 pt-md-3 px-3 px-md-6 pr-3 pb-6"
        @input="$emit('input', $event)"
    >
        <template v-slot:prepend>
            <v-list-item>
                <v-list-item-content>
                    <router-link :to="ordersRoute">
                        <ordit-logo width="96px"></ordit-logo>
                    </router-link>
                </v-list-item-content>
            </v-list-item>
            <portal
                to="header"
                :disabled="$vuetify.breakpoint.xsOnly"
                :order="2"
            >
                <v-list-item class="px-0" style="flex: 0">
                    <v-list-item-content>
                        <v-list-item-title>
                            <strong v-if="isRestaurantAdmin">
                                {{ name }}
                            </strong>
                            <router-link
                                v-else
                                :to="{
                                    name: routeType.USER_EDIT,
                                    params: { userId },
                                }"
                                class="font-weight-bold grey--text text--darken-4 text-decoration-none"
                            >
                                {{ name }}
                            </router-link>
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                        <v-menu offset-y left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" icon v-on="on">
                                    <v-icon>expand_more</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="logout">
                                    <v-list-item-icon>
                                        <v-icon>logout</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ $t('auth.logout') }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item-icon>
                </v-list-item>
            </portal>
        </template>

        <VuePerfectScrollbar>
            <v-list nav dense flat>
                <v-list-item-group v-model="current">
                    <div v-if="isCompanyAdmin" class="mb-2 text-center">
                        <v-btn
                            color="primary"
                            class="white--text align-self mb-1"
                            depressed
                            :href="`${FeBaseURL}/restaurants`"
                            target="_blank"
                            >{{ $t('labels.order_now') }}</v-btn
                        >
                    </div>
                    <div v-for="(item, i) in sidebarMenu" :key="i">
                        <v-list-item
                            v-if="!item.children"
                            :to="item.route"
                            color="primary"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.notrans || $t(item.title) }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-group v-else :prepend-icon="item.icon">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $t(item.title) }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <template v-slot:default>
                                <v-list-item
                                    v-for="(citem, j) in item.children"
                                    :key="j"
                                    :to="citem.route"
                                    color="primary"
                                >
                                    <v-list-item-content class="pl-5">
                                        <v-list-item-title>
                                            {{ $t(citem.title) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list-group>
                    </div>
                    <div
                        v-if="otterLinkRouteLinks.length"
                        class="mt-2 text-center"
                    >
                        <v-tooltip
                            v-if="otterLinkRouteLinks.length === 1"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="primary"
                                    class="white--text align-self"
                                    depressed
                                    :href="otterLinkRouteLinks[0].url"
                                    target="_blank"
                                    v-on="on"
                                >
                                    {{ $t('labels.connect_otter') }}
                                </v-btn>
                            </template>
                            <span>
                                {{ $t('labels.connect') }}:
                                {{ otterLinkRouteLinks[0].name }}
                            </span>
                        </v-tooltip>
                        <v-menu v-else offset-y>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="primary"
                                    class="white--text align-self"
                                    v-on="on"
                                >
                                    {{ $t('labels.connect_otter') }}
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="(item, index) in otterLinkRouteLinks"
                                    :key="index"
                                >
                                    <v-list-item-title>
                                        <v-btn
                                            :href="item.url"
                                            target="_blank"
                                            plain
                                            text
                                        >
                                            {{ $t('labels.connect') }}:
                                            {{ item.name }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-list-item-group>
            </v-list>
        </VuePerfectScrollbar>

        <template v-slot:append>
            <v-row dense justify="space-between">
                <v-col cols="auto" class="pt-3">&copy; {{ year }} Ordit</v-col>
                <v-col cols="auto">
                    <language-selector></language-selector>
                </v-col>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import OrditLogo from './OrditLogo.vue'
import LanguageSelector from './LanguageSelector.vue'
import AuthMixin from '@/components/mixins/AuthMixin'
import MenuMixin from '../mixins/Menu'
import routeType from '@/router/routeType'
import authType from '@/store/type/authType'
import restaurantType from '@/store/type/restaurantType'
import Config from '@/config.loader'

export default {
    components: {
        VuePerfectScrollbar,
        OrditLogo,
        LanguageSelector,
    },
    mixins: [AuthMixin, MenuMixin],
    props: {
        value: {
            type: Boolean,
            default: null,
        },
    },
    data() {
        return {
            current: null,
            routeType,
            FeBaseURL: Config.getConfigValue('VUE_APP_PUBLIC_FE_BASE'),
            BeBaseURL: Config.getConfigValue('VUE_APP_BACKEND_BASE'),
        }
    },
    computed: {
        ...mapGetters({
            userId: authType.getters.USER_ID,
            name: authType.getters.GET_EMAIL,
            restaurant: restaurantType.getters.ACTIVE_RESTAURANT,
            companyId: authType.getters.ACTIVE_COMPANY_ID,
            isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
            isCompanyAdmin: authType.getters.IS_COMPANY_ADMIN,
            managedRestaurants: restaurantType.getters.MANAGED_RESTAURANTS,
            refreshToken: authType.getters.GET_REFRESH_TOKEN,
        }),
        ordersRoute() {
            return this.isRestaurantAdmin
                ? { name: routeType.ORDER_ACTIVE }
                : { name: routeType.ORDER_LIST }
        },
        sidebarMenu() {
            return this.menu.filter((menuItem) => {
                let enabled = true

                if (menuItem.roles) {
                    // userHasRoles második paraméter, ha true, akkor a tömbben felsorolt összes role-nak meg kell felelnie a usernek
                    enabled = this.userHasRoles(menuItem.roles, true)
                } else if (menuItem.route) {
                    const route = this.routeRecursiveFind(
                        menuItem.route.name,
                        this.$router.options.routes
                    )

                    if (route?.meta?.requiresAuth && route?.meta?.roles) {
                        const roleIntersection = route.meta.roles.filter(
                            (item) => this.userRoles.includes(item)
                        )

                        enabled = roleIntersection.length > 0
                    }
                }

                if (
                    menuItem.custom_export_edit_required &&
                    !this.customExportEditEnabled
                ) {
                    enabled = false
                }

                if (
                    menuItem.custom_export_edit_required === false &&
                    this.customExportEditEnabled
                ) {
                    enabled = false
                }

                return enabled
            })
        },
        year() {
            return new Date().getFullYear()
        },
        otterLinkRouteLinks() {
            return this.managedRestaurants
                .filter((r) => r?.externalType === 'otter' && !r?.externalId)
                .map((r) => {
                    return {
                        url: `${this.BeBaseURL}/connect-integration/otter/check?restaurant=${r.id}&token=${this.refreshToken}`,
                        name: r.name,
                    }
                })
        },
    },
    methods: {
        logout() {
            this.$store.dispatch(authType.actions.LOGOUT)
        },
        routeRecursiveFind(findName, routes) {
            for (const route of routes) {
                if (route.name === findName) {
                    return route
                } else if (route.children) {
                    const result = this.routeRecursiveFind(
                        findName,
                        route.children
                    )
                    if (result) {
                        return result
                    }
                }
            }

            return null
        },
    },
}
</script>

<style lang="scss">
//.theme--light.ordit-navigation-drawer {
//    background-color: var(--v-g-f5-base);
//    border-right: 1px solid var(--v-g-e8-base);
//}
</style>
