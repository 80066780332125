import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import {
    ROLE_ADMIN,
    ROLE_COMPANY,
    ROLE_RESTAURANT,
    ROLE_USER_PROFILE_CREATE,
    ROLE_EXPENSE_CODE_EDIT,
} from '@/enum/roles'

export default {
    computed: {
        ...mapGetters({
            userRoles: authType.getters.GET_ROLES,
            activeCompany: authType.getters.ACTIVE_COMPANY,
            customExportEditEnabled:
                authType.getters.CUSTOM_EXPORT_EDIT_ENABLED,
        }),
        isSuperAdmin() {
            return this.userRoles.indexOf(ROLE_ADMIN) !== -1
        },
        isCompanyAdmin() {
            return this.userRoles.indexOf(ROLE_COMPANY) !== -1
        },
        isRestaurantAdmin() {
            return this.userRoles.indexOf(ROLE_RESTAURANT) !== -1
        },
        canCreateUsers() {
            return this.userHasRoles([ROLE_USER_PROFILE_CREATE, ROLE_ADMIN])
        },
        canEditExpenseCodes() {
            return this.userHasRoles(
                [ROLE_COMPANY, ROLE_EXPENSE_CODE_EDIT],
                true
            )
        },
    },
    methods: {
        userHasRoles(roles, allEquals = false) {
            if (!(roles instanceof Array)) {
                roles = [roles]
            }

            const roleIntersection = roles.filter((item) => {
                return this.userRoles.includes(item)
            })

            if (
                allEquals &&
                roleIntersection.length > 0 &&
                roles.length !== roleIntersection.length
            ) {
                return false
            }

            return roleIntersection.length > 0
        },
    },
}
