import cloneDeep from 'lodash/cloneDeep'

class UserAddressesFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        for (const [key, value] of Object.entries(entity)) {
            if (value === '') {
                entity[key] = null
            }
        }

        return entity
    }

    fromEntity(entity) {
        return cloneDeep(entity)
    }
}

export default new UserAddressesFormMapper()
