import { ROLE_ADMIN } from '@/enum/roles'
import routeType from '@/router/routeType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

const passthroughRender = {
    render: (c) => c('router-view'),
}

export default [
    {
        path: '/residential-canteens',
        component: passthroughRender,
        meta,
        children: [
            {
                path: '',
                name: routeType.RESIDENTIAL_CANTEENS,
                component: () =>
                    import(
                        /* webpackChunkName: "tag-list" */ '@/pages/residentialCanteens/residentialCanteens/List.vue'
                    ),
                meta,
            },
            {
                path: 'create',
                name: routeType.RESIDENTIAL_CANTEENS_CREATE,
                component: () =>
                    import(
                        /* webpackChunkName: "promo-edit" */ '@/pages/residentialCanteens/residentialCanteens/Create.vue'
                    ),
                meta,
            },
            {
                path: ':entityId',
                name: routeType.RESIDENTIAL_CANTEENS_EDIT,
                component: () =>
                    import(
                        /* webpackChunkName: "promo-edit" */ '@/pages/residentialCanteens/residentialCanteens/Edit.vue'
                    ),
                meta,
            },
        ],
    },
    {
        path: '/residential-canteen-addresses',
        component: passthroughRender,
        meta,
        children: [
            {
                path: '',
                name: routeType.RESIDENTIAL_CANTEEN_ADDRESSES,
                component: () =>
                    import(
                        /* webpackChunkName: "tag-list" */ '@/pages/residentialCanteens/addresses/List.vue'
                    ),
                meta,
            },
            {
                path: 'create',
                name: routeType.RESIDENTIAL_CANTEEN_ADDRESSES_CREATE,
                component: () =>
                    import(
                        /* webpackChunkName: "promo-edit" */ '@/pages/residentialCanteens/addresses/Create.vue'
                    ),
                meta,
            },
            {
                path: ':entityId',
                name: routeType.RESIDENTIAL_CANTEEN_ADDRESSES_EDIT,
                component: () =>
                    import(
                        /* webpackChunkName: "promo-edit" */ '@/pages/residentialCanteens/addresses/Edit.vue'
                    ),
                meta,
            },
        ],
    },
    {
        path: '/residential-canteen-relations',
        component: passthroughRender,
        meta,
        children: [
            {
                path: '',
                name: routeType.RESIDENTIAL_CANTEEN_RELATIONS,
                component: () =>
                    import(
                        /* webpackChunkName: "tag-list" */ '@/pages/residentialCanteens/relations/List.vue'
                    ),
                meta,
            },
            {
                path: 'create',
                name: routeType.RESIDENTIAL_CANTEEN_RELATIONS_CREATE,
                component: () =>
                    import(
                        /* webpackChunkName: "promo-edit" */ '@/pages/residentialCanteens/relations/Create.vue'
                    ),
                meta,
            },
            {
                path: ':entityId',
                name: routeType.RESIDENTIAL_CANTEEN_RELATIONS_EDIT,
                component: () =>
                    import(
                        /* webpackChunkName: "promo-edit" */ '@/pages/residentialCanteens/relations/Edit.vue'
                    ),
                meta,
            },
        ],
    },
]
