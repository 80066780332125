import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'

class TodoApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/todo-lists'
    }

    finish(id) {
        return ResponseHandler.handle(
            apiClient.post(this.getItemIri(id, 'finish'))
        )
    }
}

export default new TodoApiClient()
