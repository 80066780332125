<template>
    <div>
        {{
            orderPriceTotal(item) | currency(item.currency, item.currencyLocale)
        }}
        <small v-if="isMultiPayment" class="d-flex g-73--text"
            >{{
                $t('orders.multi_payment', {
                    allowance: allowance,
                    method: paymethodInitial,
                    amount: userpay,
                })
            }}
        </small>
        <small v-else class="d-flex g-73--text">
            {{
                $t('enums.payment_methods.' + (item.paymentMethod || 'company'))
            }}
        </small>
    </div>
</template>

<script>
import CompanyServiceFeeMixin from '@/components/mixins/CompanyServiceFee'

export default {
    mixins: [CompanyServiceFeeMixin],
    props: {
        item: null,
    },
    computed: {
        hasFirmPayment() {
            return parseFloat(this.item.priceSumFirm) > 0
        },
        hasUserPayment() {
            return !!this.item.paymentMethod
        },
        isMultiPayment() {
            return this.hasFirmPayment && this.hasUserPayment
        },
        allowance() {
            return this.$options.filters.currency(
                this.item.priceSumFirm,
                this.item.currency,
                this.item.currencyLocale
            )
        },
        paymethodInitial() {
            return this.item.paymentMethod
                ? this.$t(
                      'enums.payment_methods.' + this.item.paymentMethod
                  ).charAt(0)
                : ''
        },
        userpay() {
            return this.$options.filters.currency(
                this.item.priceSumUser,
                this.item.currency,
                this.item.currencyLocale
            )
        },
    },
}
</script>
