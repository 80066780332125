import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import SelectApiClientType from '@/api/SelectApiClientType'
import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'

class TranslationsApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/translations'
    }

    getRelatedSelectApi() {
        return SelectApiClientType.TRANSLATION
    }

    clone(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'clone'))
        )
    }

    update(id, entity) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'translate'), entity)
        )
    }
}

export default new TranslationsApiClient()
