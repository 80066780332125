import jwt_decode from 'jwt-decode'
import Vue from 'vue'
import authType from './type/authType'
import restaurantType from './type/restaurantType'
import AuthApiClient from '@/api/AuthApiClient'
import NotificationsApiClient from '@/api/RestApi/NotificationsApiClient'
import CompaniesApiClient from '@/api/RestApi/CompaniesApiClient'
import { ROLE_ADMIN, ROLE_COMPANY, ROLE_RESTAURANT } from '@/enum/roles'
import router from '@/router/router'
import routeType from '@/router/routeType'
import Cookies from 'js-cookie'

export default {
    state: {
        jwt: null,
        refreshToken: null,
        keepMeSignedIn: false,
        isRestaurantAdmin: false,
        isMultiRestaurantAdmin: false,
        isCompanyAdmin: false,
        isSuperAdmin: false,
        jwtExpTime: null,
        email: null,
        roles: [],
        managed_companies: [],
        managed_restaurants: [],
        company: null,
        active_company: null,
        active_company_id: null,
        userId: null,
        authErrorHappened: false,
        notificationRegistered: false,
        notificationOffByUser: Cookies.get('notificationOffByUser') === 'true',
        notificationPermissionGranted: false,
        customExportEditEnabled: false,
    },
    getters: {
        [authType.getters.IS_LOGGED_IN](state) {
            return state.jwt !== null
        },
        [authType.getters.GET_JWT](state) {
            return state.jwt
        },
        [authType.getters.GET_REFRESH_TOKEN](state) {
            return state.refreshToken
        },
        [authType.getters.KEEP_ME_SIGNED_IN](state) {
            return state.keepMeSignedIn
        },
        [authType.getters.GET_JWT_EXP_TIME](state) {
            return state.jwtExpTime
        },
        [authType.getters.GET_EMAIL](state) {
            return state.email
        },
        [authType.getters.GET_ROLES](state) {
            return state.roles
        },
        [authType.getters.IS_RESTAURANT_ADMIN](state) {
            return state.isRestaurantAdmin
        },
        [authType.getters.IS_MULTI_RESTAURANT_ADMIN](state) {
            return state.isMultiRestaurantAdmin
        },
        [authType.getters.IS_COMPANY_ADMIN](state) {
            return state.isCompanyAdmin
        },
        [authType.getters.IS_SUPER_ADMIN](state) {
            return state.isSuperAdmin
        },
        [authType.getters.MANAGED_COMPANIES](state) {
            return state.managed_companies
        },
        [authType.getters.MANAGED_RESTAURANTS](state) {
            return state.managed_restaurants
        },
        [authType.getters.ACTIVE_COMPANY](state) {
            return state.active_company
        },
        [authType.getters.ACTIVE_COMPANY_ID](state) {
            return state.active_company_id
        },
        [authType.getters.ACTIVE_COMPANY_OBJ](state) {
            return state.company
        },
        [authType.getters.USER_ID](state) {
            return state.userId
        },
        [authType.getters.AUTH_ERROR_HAPPENED](state) {
            return state.authErrorHappened
        },
        [authType.getters.GET_NOTIFICATION_STATE](state) {
            return state.notificationRegistered
        },
        [authType.getters.NOTIFICATION_OFF_BY_USER](state) {
            return state.notificationOffByUser
        },
        [authType.getters.NOTIFICATION_PERMISSION_GRANTED](state) {
            return state.notificationPermissionGranted
        },
        [authType.getters.CUSTOM_EXPORT_EDIT_ENABLED](state) {
            return state.customExportEditEnabled
        },
    },
    mutations: {
        [authType.mutations.SET_JWT](state, jwt) {
            const parsedJwt = jwt_decode(jwt)
            state.jwtExpTime = parsedJwt.exp
            state.email = parsedJwt.username
            state.roles = parsedJwt.roles
            state.jwt = jwt
            state.managed_companies = parsedJwt.managed_companies
            state.managed_restaurants = parsedJwt.managed_restaurants
            if (state.roles) {
                state.isRestaurantAdmin =
                    state.roles.indexOf(ROLE_RESTAURANT) !== -1
                state.isCompanyAdmin = state.roles.indexOf(ROLE_COMPANY) !== -1
                state.isSuperAdmin = state.roles.indexOf(ROLE_ADMIN) !== -1
            }
            if (state.isRestaurantAdmin) {
                state.isMultiRestaurantAdmin = Boolean(
                    state.managed_restaurants.length > 1
                )
            }
            state.active_company =
                parsedJwt.managed_companies.length > 0
                    ? parsedJwt.managed_companies[0].iri
                    : null
            state.active_company_id =
                parsedJwt.managed_companies.length > 0
                    ? parsedJwt.managed_companies[0].id
                    : null
            state.userId = parsedJwt.id
            state.customExportEditEnabled = parsedJwt.custom_export_edit_enabled
        },
        [authType.mutations.SET_REFRESH_TOKEN](state, token) {
            state.refreshToken = token
        },
        [authType.mutations.SET_KEEP_ME_SIGNED_IN](state, data) {
            state.keepMeSignedIn = data
        },
        [authType.mutations.LOGOUT](state) {
            state.jwt = null
            state.refreshToken = null
            state.keepMeSignedIn = false
            state.jwtExpTime = null
            state.email = null
            state.roles = []
            state.managed_companies = []
            state.active_company = null
            state.userId = null
            state.customExportEditEnabled = false
        },
        [authType.mutations.HAS_AUTH_ERROR_HAPPENED](state) {
            state.authErrorHappened = true
        },
        [authType.mutations.CLEAR_AUTH_ERROR](state) {
            state.authErrorHappened = false
        },
        [authType.mutations.SET_NOTIFICATION_STATE](state, payload) {
            state.notificationRegistered = payload
        },
        [authType.mutations.SET_NOTIFICATIONS](state, payload) {
            if (payload) Cookies.set('notificationOffByUser', true)
            else Cookies.remove('notificationOffByUser')
            console.log(Cookies.get('notificationOffByUser'))
            state.notificationOffByUser =
                Cookies.get('notificationOffByUser') === 'true'
            console.log(state.notificationOffByUser)
        },
        [authType.mutations.SET_NOTIFICATION_PERMISSION](state, payload) {
            state.notificationPermissionGranted = payload
        },
        [authType.mutations.SET_COMPANY_OBJ](state, payload) {
            state.company = payload
        },
    },
    actions: {
        [authType.actions.LOGIN_WITH_JWT]({ dispatch, commit, state }, jwt) {
            commit(authType.mutations.SET_JWT, jwt)
            if (state.managed_restaurants) {
                dispatch(
                    restaurantType.actions.LOAD_MANAGED_RESTAURANTS,
                    state.managed_restaurants
                )
            }
            if (state.active_company) {
                CompaniesApiClient.get(state.active_company_id).then(
                    (result) => {
                        commit(authType.mutations.SET_COMPANY_OBJ, result)
                    }
                )
            }
        },
        [authType.actions.LOGIN_WITH_PASSWORD]({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                AuthApiClient.auth(data.email, data.password)
                    .then((result) => {
                        commit(
                            authType.mutations.SET_KEEP_ME_SIGNED_IN,
                            data.keepMeSignedIn
                        )
                        commit(
                            authType.mutations.SET_REFRESH_TOKEN,
                            result.data.refresh_token
                        )
                        dispatch(
                            authType.actions.LOGIN_WITH_JWT,
                            result.data.token
                        )
                        resolve()
                    })
                    .catch((reason) => {
                        reject(reason)
                    })
            })
        },
        [authType.actions.LOGOUT]({ commit }) {
            commit(authType.mutations.LOGOUT)
            commit(restaurantType.mutations.SET_ACTIVE_RESTAURANT, null)
            router.push({ name: routeType.LOGIN })
        },
        [authType.actions.SET_REFRESH_TOKEN]({ commit }, refreshToken) {
            commit(authType.mutations.SET_REFRESH_TOKEN, refreshToken)
        },
        [authType.actions.LOGIN_WITH_REFRESH_TOKEN](
            { commit, dispatch },
            refreshToken
        ) {
            return new Promise((resolve, reject) => {
                AuthApiClient.refresh(refreshToken)
                    .then((result) => {
                        commit(authType.mutations.SET_KEEP_ME_SIGNED_IN, false)
                        commit(
                            authType.mutations.SET_REFRESH_TOKEN,
                            result.data.refresh_token
                        )
                        dispatch(
                            authType.actions.LOGIN_WITH_JWT,
                            result.data.token
                        )
                        resolve()
                    })
                    .catch((reason) => {
                        reject(reason)
                    })
            })
        },
        [authType.actions.UNREGISTER_NOTIFICATIONS]({ commit, state }) {
            return new Promise((resolve, reject) => {
                if (state.notificationRegistered) {
                    Vue.prototype.$messaging
                        .deleteToken()
                        .then((res) => {
                            commit(
                                authType.mutations.SET_NOTIFICATION_STATE,
                                false
                            )
                            resolve(res)
                        })
                        .catch((e) => {
                            reject(e)
                        })
                } else {
                    resolve({ not_registered: true })
                }
            })
        },
        [authType.actions.REGISTER_NOTIFICATIONS]({ commit }) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$messaging
                    .getToken({
                        vapidKey: process.env.VUE_APP_FB_VAPID_KEY,
                    })
                    .then((currentToken) => {
                        if (currentToken) {
                            NotificationsApiClient.register(currentToken)
                                .then((res) => {
                                    commit(
                                        authType.mutations
                                            .SET_NOTIFICATION_STATE,
                                        res.success
                                    )
                                    resolve(res.success)
                                })
                                .catch((err) => {
                                    commit(
                                        authType.mutations
                                            .SET_NOTIFICATION_STATE,
                                        false
                                    )
                                    reject(err)
                                })
                        } else {
                            commit(
                                authType.mutations.SET_NOTIFICATION_STATE,
                                false
                            )
                            reject(
                                'No registration token available. Request permission to generate one.'
                            )
                        }
                    })
                    .catch((err) => {
                        commit(authType.mutations.SET_NOTIFICATION_STATE, false)
                        reject(
                            err,
                            'No registration token available. Request permission to generate one.'
                        )
                    })
            })
        },
        [authType.actions.SET_NOTIFICATIONS]({ commit }, payload) {
            commit(authType.mutations.SET_NOTIFICATIONS, payload)
        },

        [authType.actions.SET_NOTIFICATION_PERMISSION]({ commit }, payload) {
            commit(authType.mutations.SET_NOTIFICATION_PERMISSION, payload)
        },
    },
}
