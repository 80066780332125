<template>
    <delivery-table-button
        ref="cancelmodal"
        :tooltip="$t('actions.delete')"
        color="red"
        icon="cancel"
        confirm-dialog
        :content="content"
        :confirm-title="confirmTitle ? confirmTitle : $t('texts.are_you_sure')"
        :entity-id="entityId"
        :entity-count="entityCount"
        :order-id="orderId"
        :delivery-job-id="deliveryJobId"
        :approve-text="$t('actions.cancel-delivery')"
        approve-color="success"
        :modal-processing="modalProcessing"
        @execute="execute"
    ></delivery-table-button>
</template>

<script>
import DeliveryTableButton from '@/components/table/buttons/DeliveryTableButton'
import RestApiCollection from '@/api/RestApiCollection'
import Notification from '@/services/Notification/Notification'

export default {
    components: {
        DeliveryTableButton,
    },
    props: {
        entityName: {
            type: String,
            required: false,
        },
        entityCount: {
            type: Number,
            required: true,
        },
        api: {
            type: String,
            required: true,
        },
        entityId: {
            type: Number,
            required: true,
        },
        confirmTitle: {
            type: String,
            default: null,
        },
        orderId: {
            type: String,
            default: null,
        },
        deliveryJobId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            markers: [],
            isReady: false,
            modalProcessing: false,
        }
    },
    computed: {
        content() {
            return (
                <template v-slot:comp>
                    <div class="row">
                        <div class="item-title col-md-10">
                            Only this delivery job this.entityId
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <div class="separator"></div>
                </template>
            )
        },
    },
    methods: {
        execute(cancelordertype) {
            this.modalProcessing = true

            cancelordertype == 'orderjob'
                ? RestApiCollection.get(this.api)
                      .cancelDeliveryJob(this.deliveryJobId)
                      .then((data) => {
                          if (data.success === false) {
                              Notification.error(
                                  'Delivery ' +
                                      this.deliveryJobId +
                                      ' cancellation error',
                                  data.message
                              )
                          } else {
                              this.$emit('deleted', this.deliveryJobId)
                          }
                      })
                      .catch(() => {
                          this.$emit('failed', this.deliveryJobId)
                      })
                      .finally(() => {
                          this.$refs.cancelmodal.dialog = false
                          this.modalProcessing = false
                      })
                : RestApiCollection.get(this.api)
                      .cancelDeliveryProviderJob(this.entityId)
                      .then((data) => {
                          if (data.success === false) {
                              Notification.error(
                                  this.entityId + ' cancellation error',
                                  data.message
                              )
                          } else {
                              this.$emit('deleted', this.entityId)
                          }
                      })
                      .catch(() => {
                          this.$emit('failed', this.entityId)
                      })
                      .finally(() => {
                          this.$refs.cancelmodal.dialog = false
                          this.modalProcessing = false
                      })
        },
    },
}
</script>
