import Vue from 'vue'
import configType from './type/canteenType'
import qs from 'qs'
import CompanyCanteenApiClient from '@/api/RestApi/CompanyCloudcanteens'

const getKey = (userId, query) => `${userId}-${qs.stringify(query || {})}`

export default {
    state: {
        canteen: null,
        canteenList: [],
        balanceValues: {},
        balanceLoaded: {},
        balanceLoader: {},
    },
    getters: {
        [configType.getters.CANTEEN](state) {
            return state.canteen
        },
        [configType.getters.CANTEEN_LIST](state) {
            return state.canteenList.sort((a, b) => {
                return Vue.prototype.$date(a.cutoffTime).isBefore(b.cutoffTime)
                    ? -1
                    : 1
            })
        },
        [configType.getters.BALANCES]: (state) => (userId, query) =>
            state.balanceValues[getKey(userId, query)] || [],
        [configType.getters.BALANCES_LOADED]: (state) => (userId, query) =>
            state.balanceLoaded[getKey(userId, query)] || false,
    },
    mutations: {
        [configType.mutations.SET_CANTEEN](state, payload) {
            state.canteen = payload
        },
        [configType.mutations.SET_CANTEEN_LIST](state, payload) {
            state.canteenList = payload
        },
        [configType.mutations.REMOVE_CANTEEN](state, id) {
            const index = state.canteenList.findIndex(
                ({ canteenId }) => canteenId === id
            )
            if (index > -1) {
                if (state.canteen?.canteenId === id) state.canteen = null
                state.canteenList.splice(index, 1)
            }
        },
        [configType.mutations.UPDATE_CANTEEN_LIST](state, payload) {
            const index = state.canteenList.findIndex(
                (canteen) =>
                    canteen.identifier === payload.identifier &&
                    canteen.restaurantId === payload.restaurantId
            )
            if (index > -1) Vue.set(state.canteenList, index, payload)
            else state.canteenList.push(payload)
        },
        [configType.mutations.BALANCE_VALUES](state, payload) {
            const key = getKey(payload?.userId, payload?.query)
            state.balanceValues[key] = payload?.value || []
            state.balanceLoaded[key] = true
        },
        [configType.mutations.BALANCE_LOADER](state, payload) {
            const key = getKey(payload?.userId, payload?.query)
            state.balanceLoader[key] = payload?.value || null
        },
        [configType.mutations.BALANCE_INVALIDATE](state, payload) {
            const userId = payload?.userId
            if (!userId) return

            const keys = Object.keys(state.balanceLoaded)

            for (let key of keys) {
                if (!key.includes(userId)) continue

                state.balanceLoaded[key] = false
            }
        },
    },
    actions: {
        async [configType.actions.LOAD_CANTEN_BALANCES](
            { state, getters, commit },
            payload
        ) {
            const query = payload?.query
            const userId = payload?.userId
            const key = getKey(userId, query)

            if (state.balanceLoaded[key]) {
                return getters[configType.getters.BALANCES](userId, query)
            } else {
                return new Promise((resolve, reject) => {
                    const fetch =
                        state.balanceLoader[key] ||
                        CompanyCanteenApiClient.listUserBudgets(userId, query)

                    if (!state.balanceLoader[key]) {
                        commit(configType.mutations.BALANCE_LOADER, {
                            value: fetch,
                            userId,
                            query,
                        })
                    }

                    fetch
                        .then((value) => {
                            commit(configType.mutations.BALANCE_VALUES, {
                                value,
                                userId,
                                query,
                            })

                            resolve(value)

                            commit(configType.mutations.BALANCE_LOADER, {
                                value: null,
                                userId,
                                query,
                            })
                        })
                        .catch(() => reject())
                })
            }
        },
        [configType.actions.REFRESH_BALANCES]({ commit }, payload) {
            commit(configType.mutations.BALANCE_INVALIDATE, payload)
        },
        [configType.actions.DELETE_CANTEEN]({ commit }, id) {
            commit(configType.mutations.REMOVE_CANTEEN, id)
        },
    },
}
