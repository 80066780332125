import cloneDeep from 'lodash/cloneDeep'

export const ItemRefundPayload = {
    items: [],
    reason: '',
    refundOnBehalf: 'ordit',
}

export const AmountRefundPayload = {
    amount: null,
    reason: '',
    refundOnBehalf: 'ordit',
}

export function filterPayload(payload) {
    const result = cloneDeep(payload)

    if ('items' in result) {
        result.items = result.items.filter((item) => item.quantity > 0)
        result.items.forEach(
            (item) => (item.quantity = parseInt(item.quantity))
        )
    }

    if ('amount' in result) {
        if (result.amount === '') {
            result.amount = null
        }
    }

    if (!result.reason) {
        result.reason = 'a'
    }

    return result
}

export function filterCalculateRelevant(payload) {
    const result = filterPayload(payload)
    delete result.refundOnBehalf
    delete result.reason
    delete result.selectReason

    if ('items' in result) {
        result.items.forEach((item) => {
            delete item.reason
            delete item.selectReason
        })
    }

    return result
}

export function hasValueToCalculate(payload) {
    let data = filterPayload(payload)

    if ('items' in data) {
        return data.items.length > 0
    }

    if ('amount' in data) {
        return data.amount > 0
    }
}

export function clampItemQuantities(payload, order) {
    let changed = false

    if ('items' in payload) {
        payload.items.forEach((item) => {
            const meal = order.meals.find((meal) => meal.id === item.item)

            if (meal) {
                const refundableQuantity = meal.quantity - meal.refundedQuantity
                if (item.quantity > refundableQuantity) {
                    item.quantity = refundableQuantity
                    changed = true
                }
                if (item.quantity < 0) {
                    item.quantity = 0
                    changed = true
                }
            }
        })
    }

    return changed
}

export const itemReasons = [
    'not_prepared',
    'out_of_stock',
    'food_quality',
    'not_delivered',
    'damaged_packaging',
    'cancelled_item',
    'other',
]

export const orderReasons = [
    'not_prepared',
    'out_of_stock',
    'food_quality',
    'not_delivered',
    'late_delivery_restaurant',
    'late_delivery_courier',
    'damaged_packaging',
    'cancelled_order',
    'other',
]
