import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'

class Invoices extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/billing'
    }

    create(entity, headers) {
        return super.create(entity, headers).then((result) => {
            if (entity.files) {
                for (const file of entity.files) {
                    const myFile = new FormData()
                    myFile.append('file', file)
                    myFile.append(
                        'title',
                        file.name.substring(0, file.name.lastIndexOf('.'))
                    )
                    this.uploadFiles(
                        entity.type,
                        entity.relationId,
                        result.billingFolderId,
                        myFile
                    )
                }
            }

            return result
        })
    }

    update(entity, id) {
        return super.update(entity, id).then((result) => {
            if (entity.files) {
                for (const file of entity.files) {
                    const myFile = new FormData()
                    myFile.append('file', file)
                    myFile.append(
                        'title',
                        file.name.substring(0, file.name.lastIndexOf('.'))
                    )
                    this.uploadFiles(
                        entity.type,
                        entity.relationId,
                        entity.billingFolderId,
                        myFile
                    )
                }
            }

            return result
        })
    }

    uploadFiles(type, relationId, folderId, file) {
        return ResponseHandler.handle(
            apiClient
                .post(
                    '/api/v1/file-manager' +
                        '/' +
                        type +
                        '/' +
                        relationId +
                        '/folders/' +
                        folderId +
                        '/files',
                    file,
                    {
                        headers: {
                            'content-type': 'multipart/form-data',
                        },
                    }
                )
                .catch((error) => {
                    if (error.response.status === 500) {
                        Notification.requestError(error)
                    }

                    return Promise.reject(error)
                })
        )
    }

    deleteFile(type, relationId, folderId, fileId) {
        return ResponseHandler.handle(
            apiClient.delete(
                this.getBaseUrl() +
                    '/' +
                    type +
                    '/' +
                    relationId +
                    '/folders/' +
                    folderId +
                    '/files/' +
                    fileId
            )
        )
    }

    downloadFile(type, relationId, folderId, fileId) {
        return ResponseHandler.handle(
            apiClient.get(
                this.getBaseUrl() +
                    '/' +
                    type +
                    '/' +
                    relationId +
                    '/folders/' +
                    folderId +
                    '/files/' +
                    fileId +
                    '/download',
                {
                    responseType: 'blob',
                }
            )
        )
    }
}

export default new Invoices()
