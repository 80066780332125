import cloneDeep from 'lodash/cloneDeep'
import PeriodMapper from '@/services/mapper/PeriodMapper'

class CompanyAllowancesFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        // days
        if (entity.days) {
            entity.days = PeriodMapper.toEntity(entity.days)
        }

        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)

        // groupAllowedEmployees
        if (values.groupAllowedEmployees) {
            values.groupAllowedEmployees = values.groupAllowedEmployees.map(
                (item) => item['@id']
            )
        }

        // days
        if (values.days && Array.isArray(values.days)) {
            values.days = PeriodMapper.fromEntity(values.days)
        }

        if (values.intervalStart) {
            values.intervalStart = values.intervalStart.substr(0, 10)
        }

        return values
    }
}

export default new CompanyAllowancesFormMapper()
