import UsersFormMapper from './UsersFormMapper'
import PromotionsFormMapper from './PromotionsFormMapper'
import MapperType from './MapperType'
import CompaniesFormMapper from './CompaniesFormMapper'
import CompanyAddressFormMapper from './CompanyAddressFormMapper'
import CompanyCloudcanteenFormMapper from './CompanyCloudcanteenFormMapper'
import CompanyTeamsFormMapper from './CompanyTeamsFormMapper'
import CompanyBalancesFormMapper from './CompanyBalancesFormMapper'
import UserAddressesFormMapper from './UserAddressesFormMapper'
import CompanyAllowancesFormMapper from './CompanyAllowancesFormMapper'
import RestaurantFormMapper from '@/services/mapper/RestaurantFormMapper'
import MealCategoryListMapper from '@/services/mapper/MealCategoryListMapper'
import MealListMapper from '@/services/mapper/MealListMapper'
import TagFormMapper from '@/services/mapper/TagFormMapper'
import TagGroupFormMapper from '@/services/mapper/TagGroupFormMapper'
import DeliveryFormMapper from '@/services/mapper/DeliveryFormMapper'

import MealOptionGroupListMapper from '@/services/mapper/MealOptionGroupListMapper'
import MealOptionListMapper from '@/services/mapper/MealOptionListMapper'
import MealOptionFormMapper from '@/services/mapper/MealOptionFormMapper'
import MealFormMapper from '@/services/mapper/MealFormMapper'
import OrderFormMapper from '@/services/mapper/OrderFormMapper'
import CustomExportFormMapper from '@/services/mapper/CustomExportFormMapper'
import CouponsFormMapper from '@/services/mapper/CouponsFormMapper'
import ResidentialCanteensFormMapper from '@/services/mapper/ResidentialCanteensFormMapper'
import ResidentialCanteenAddressesFormMapper from '@/services/mapper/ResidentialCanteenAddressesFormMapper'
import ResidentialCanteenRelationsFormMapper from '@/services/mapper/ResidentialCanteenRelationsFormMapper'
import InvoicesFormMapper from '@/services/mapper/InvoicesFormMapper'
import PromoBannerFormMapper from '@/services/mapper/PromoBannerFormMapper'
import TodoFormMapper from '@/services/mapper/TodoFormMapper'
import BrandFormMapper from './BrandFormMapper'
import DefaultFormMapper from './DefaultFormMapper'

class MapperCollection {
    get(name) {
        switch (name) {
            case MapperType.USERS_FORM:
                return UsersFormMapper
            case MapperType.PROMOTIONS_FORM:
                return PromotionsFormMapper
            case MapperType.COMPANIES_FORM:
                return CompaniesFormMapper
            case MapperType.COMPANY_ADDRESSES_FORM:
                return CompanyAddressFormMapper
            case MapperType.COMPANY_CLOUDCANTEENS_FORM:
                return CompanyCloudcanteenFormMapper
            case MapperType.COMPANY_TEAMS_FORM:
                return CompanyTeamsFormMapper
            case MapperType.COMPANY_BALANCES_FORM:
                return CompanyBalancesFormMapper
            case MapperType.USER_ADDRESSES_FORM:
                return UserAddressesFormMapper
            case MapperType.COMPANY_ALLOWANCES_FORM:
                return CompanyAllowancesFormMapper
            case MapperType.RESTAURANT_FORM:
                return RestaurantFormMapper
            case MapperType.MEAL_CATEGORY_LIST:
                return MealCategoryListMapper
            case MapperType.MEAL_LIST:
                return MealListMapper
            case MapperType.MEAL_OPTION_GROUP:
                return MealOptionGroupListMapper
            case MapperType.MEAL_OPTION:
                return MealOptionListMapper
            case MapperType.MEAL_OPTION_FORM:
                return MealOptionFormMapper
            case MapperType.MEAL_FORM:
                return MealFormMapper
            case MapperType.ORDER_FORM:
                return OrderFormMapper
            case MapperType.TAG_FORM:
                return TagFormMapper
            case MapperType.TAG_GROUP_FORM:
                return TagGroupFormMapper
            case MapperType.DELIVERY_FORM:
                return DeliveryFormMapper
            case MapperType.CUSTOM_EXPORT_FORM:
                return CustomExportFormMapper
            case MapperType.COUPON_FORM:
                return CouponsFormMapper
            case MapperType.RESIDENTIAL_CANTEENS_FORM:
                return ResidentialCanteensFormMapper
            case MapperType.RESIDENTIAL_CANTEEN_ADDRESSES_FORM:
                return ResidentialCanteenAddressesFormMapper
            case MapperType.RESIDENTIAL_CANTEEN_RELATIONS_FORM:
                return ResidentialCanteenRelationsFormMapper
            case MapperType.INVOICE_FORM:
                return InvoicesFormMapper
            case MapperType.PROMO_BANNERS_FORM:
                return PromoBannerFormMapper
            case MapperType.TODO_FORM:
                return TodoFormMapper
            case MapperType.BRAND_FORM:
                return BrandFormMapper
            default:
                return DefaultFormMapper
        }
    }
}

export default new MapperCollection()
