<script>
import PageTitle from '@/components/layout/components/pageTitle'
import Back from '@/components/elements/Back'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: { Back, PageTitle },
    mixins: [AuthMixin],
}
</script>

<template lang="pug">
    .container.fill-screen.d-flex.align-center.justify-center.flex-column
        page-title 403
        h1.error--text.text-center {{ $t('texts.page_unauthorized') }}
        h3.text-center(v-if="isCompanyAdmin" v-html="$t('texts.company_admin')")
        back.mx-auto.d-block.mt-4(v-else)

</template>
