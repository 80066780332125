import apiClient from '@/api/ApiClient'
import FileSaver from 'file-saver'
import Notification from '@/services/Notification/Notification'
import qs from 'qs'

function getFileNameFromContentDispositon(contentDispositon, defaultFilename) {
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
        contentDispositon
    )

    return matches != null && matches[1]
        ? matches[1].replace(/['"]/g, '')
        : defaultFilename
}

export default function (url, query) {
    return apiClient
        .get(url, {
            headers: { Accept: '*' },
            params: query,
            paramsSerializer: (params) =>
                qs.stringify(params, {
                    encode: true,
                    arrayFormat: 'brackets',
                }),
        })
        .then((response) => {
            const filename = getFileNameFromContentDispositon(
                response.headers['content-disposition'],
                'file'
            )
            FileSaver.saveAs(
                new Blob([response.data], {
                    type: response.headers['content-type'],
                }),
                filename
            )

            return Promise.resolve()
        })
        .catch((error) => {
            Notification.requestError(error)

            return Promise.reject(error)
        })
}
