import axios from 'axios'
import store from '@/store/store'
import authType from '@/store/type/authType'
import Config from '@/config.loader'
import router from '@/router/router'
import routeType from '@/router/routeType'

const apiClient = axios.create({
    baseURL: Config.getConfigValue('VUE_APP_ADMIN_API_BASE'),
    headers: {
        Accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
    },
})

const setAuthorizationHeader = function (request) {
    request.headers.Authorization =
        'Bearer ' + store.getters[authType.getters.GET_JWT]
}

apiClient.interceptors.request.use((request) => {
    if (
        request.url.startsWith('/auth') ||
        request.url.startsWith('/reset_password') ||
        request.url.startsWith('/api/v1/config') ||
        request.url.startsWith('/public') ||
        request.url.startsWith('/frontend-api')
    ) {
        return request
    }

    const now = Date.now().valueOf() / 1000
    const exp = store.getters[authType.getters.GET_JWT_EXP_TIME]
    const diff = (exp || 0) - now

    if (diff < 60 * 10) {
        const refreshToken = store.getters[authType.getters.GET_REFRESH_TOKEN]

        return new Promise((resolve) => {
            store
                .dispatch(
                    authType.actions.LOGIN_WITH_REFRESH_TOKEN,
                    refreshToken
                )
                .finally(() => {
                    setAuthorizationHeader(request)
                    resolve(request)
                })
        })
    }

    setAuthorizationHeader(request)

    return request
})

apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            if (error.response.status === 404) {
                // potential restaurant admin disable, relog and go to orderlist
                if (store.getters[authType.getters.IS_RESTAURANT_ADMIN]) {
                    const refreshToken =
                        store.getters[authType.getters.GET_REFRESH_TOKEN]

                    store
                        .dispatch(
                            authType.actions.LOGIN_WITH_REFRESH_TOKEN,
                            refreshToken
                        )
                        .then(() => {
                            router.replace({ name: routeType.ORDER_ACTIVE })
                        })
                }
            }
            if (error.response.status === 403) {
                store.commit(authType.mutations.HAS_AUTH_ERROR_HAPPENED)
            }
            if (error.response.status === 401) {
                store.commit(authType.mutations.LOGOUT)
            }
        }
        return Promise.reject(error)
    }
)

export default apiClient
