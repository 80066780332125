import apiClient from '@/api/ApiClient'
import ResponseHandler from '@/api/response/ResponseHandler'
import SelectApiClientType from '@/api/SelectApiClientType'

class SelectApiClient {
    getSelectApiResponse(type, queryParams) {
        return ResponseHandler.handle(
            apiClient.get(`/api/v1/select-options/${type}`, {
                params: queryParams || {},
            })
        )
    }

    allergensSuitability() {
        return ResponseHandler.handle(
            apiClient.get('/api/v1/select-options/allergens-suitability', {
                params: {
                    translate: 0,
                },
            })
        )
    }

    getByEntityName(entity, queryParams) {
        switch (entity) {
            case SelectApiClientType.ALLERGENS_SUITABILITY:
                return this.allergensSuitability()
            default:
                return this.getSelectApiResponse(entity, queryParams)
        }
    }
}

export default new SelectApiClient()
