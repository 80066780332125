import Vue from 'vue'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isToday from 'dayjs/plugin/isToday'
import isoWeek from 'dayjs/plugin/isoWeek'
import relative from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/hu'

dayjs.extend(localizedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isToday)
dayjs.extend(isoWeek)
dayjs.extend(relative)
dayjs.extend(duration)
dayjs.extend(utc)

// from https://github.com/Juceztp/vue-dayjs
Object.defineProperties(Vue.prototype, {
    $date: {
        get() {
            return dayjs
        },
    },
    //TODO find a better solution
    $generalizeDate: {
        value: (value) => value?.split('+')?.[0].split('T')?.join(' '),
        writable: false,
    },
})

export default dayjs
