import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'

class CustomExportApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/custom-export'
    }

    clone(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'clone'))
        )
    }

    get(id) {
        return ResponseHandler.handle(apiClient.get(this.getItemIri(id)))
    }

    executeJson(token, params) {
        return apiClient.get('/public/custom-export', {
            params: {
                token: token,
                json: true,
                params: JSON.stringify(params),
            },
        })
    }

    download(token, params) {
        return ResponseHandler.handle(
            apiClient.get('/public/custom-export', {
                responseType: 'blob',
                params: {
                    token: token,
                    params: JSON.stringify(params),
                },
            })
        )
    }

    execute(token, params) {
        return apiClient.get('/public/custom-export', {
            params: {
                token: token,
                params: JSON.stringify(params),
            },
        })
    }
}

export default new CustomExportApiClient()
