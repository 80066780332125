<script>
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import filter from './filter'

export default {
    components: {
        DatePickerInput,
    },
    mixins: [filter],
    data() {
        return {
            format: 'YYYY-MM-DD',
        }
    },
}
</script>

<template lang="pug">
    date-picker-input(
        :value="internalValue"
        :label="field.label"
        :block-date="blocked ? internalValue : undefined"
        filter
        @input="setValue")
</template>
