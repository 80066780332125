import Vue from 'vue'
import VueToastr from 'vue-toastr'
// @TODO change notifications to setup here
// import Notification from '@/services/Notification/Notification'

Vue.use(VueToastr, {
    defaultTimeout: 5000,
    defaultProgressBar: true,
    defaultPosition: 'toast-top-center',
})

// Notification.setVueInstance(vue)
