<template>
    <div :class="['edit-button', 'd-inline-block']">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn
                    v-bind="$attrs"
                    text
                    :href="href"
                    target
                    icon
                    :x-small="xsmall"
                    :loading="loading"
                    :color="color"
                    :to="to"
                    :small="small"
                    v-on="on"
                    @click="execute"
                >
                    <v-icon :size="iconsize">{{ icon }}</v-icon>
                </v-btn>
            </template>
            <span v-if="tooltip">{{ tooltip }}</span>
            <span v-else>{{ $t('actions.edit') }}</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: 'edit',
        },
        href: {
            type: String,
            default: undefined,
        },
        target: {
            type: String,
            default: undefined,
        },
        iconsize: {
            type: String,
            default: undefined,
        },
        xsmall: Boolean,
        loading: Boolean,
        color: {
            type: String,
            default: 'brandAlt',
        },
        to: {
            type: [String, Object],
            default: undefined,
        },
        tooltip: {
            type: String,
            default: '',
        },
        small: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        execute() {
            this.$emit('execute')
        },
    },
}
</script>
