export default {
    methods: {
        getCanteenOrderIdentifier(identifier) {
            const firstDash = identifier?.indexOf('-')
            const lastDash = identifier?.lastIndexOf('-')

            return 'ORDIT' + identifier?.substring(firstDash, lastDash)
        },
    },
}
