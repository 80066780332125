<template>
    <modal-dialog
        v-model="dialog"
        :title="title ? title : $t('texts.are_you_sure')"
        cancel-button
        :cancel-text="cancelTextCalc"
        :ok-text="approveTextCalc"
        :ok-color="approveColor"
        :show-overlay="processing"
        :ok-disabled="isApproveDisabled"
        :scrollable="scrollable"
        @cancel="onCancel"
        @ok="onApprove"
    >
        <slot>
            {{ text }}
        </slot>
        <p>
            {{ $t('texts.please_confirm_by_typing_confirm') }}
        </p>
        <text-input v-model="inputValue"></text-input>
    </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/dialog/ModalDialog'
import TextInput from '@/components/form/Inputs/TextInput'

export default {
    components: {
        ModalDialog,
        TextInput,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        approveText: {
            type: String,
            default: null,
        },
        approveColor: {
            type: String,
            default: null,
        },
        cancelText: {
            type: String,
            default: null,
        },
        processing: Boolean,
        approveDisabled: Boolean,
        scrollable: Boolean,
    },
    data() {
        return {
            dialog: false,
            inputValue: '',
        }
    },
    computed: {
        isApproveDisabled() {
            return (
                this.inputValue?.toLowerCase() !== 'confirm' ||
                this.approveDisabled
            )
        },
        approveTextCalc() {
            return this.approveText || this.$t('labels.yes')
        },
        cancelTextCalc() {
            return this.cancelText || this.$t('labels.no')
        },
    },
    watch: {
        value() {
            this.dialog = this.value
            if (this.value) {
                this.inputValue = ''
            }
        },
    },
    methods: {
        onApprove() {
            this.$emit('input', false)
            this.$emit('onApprove')
        },
        onCancel() {
            this.$emit('input', false)
            this.$emit('onCancel')
        },
    },
}
</script>
