import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
import configType from '@/store/type/configType'

const googleMapsInit = (store) => {
    const unsub = store.subscribe((mutation) => {
        if (
            mutation.type === configType.mutations.CONFIG_IS_LOADED &&
            mutation.payload === true
        ) {
            Vue.use(VueGoogleMaps, {
                load: {
                    key: store.getters[configType.getters.GOOGLE_MAPS_API_KEY],
                    libraries: ['places', 'drawing'],
                },
            })

            Vue.use(VuetifyGoogleAutocomplete, {
                vueGoogleMapsCompatibility: true,
            })

            unsub()
        }
    })
}

export default googleMapsInit
