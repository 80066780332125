import dirtyType from './type/dirtyType'

export default {
    state: () => ({
        dialog: false,
        deliveryDialog: false,
        dirty: false,
        nextPage: null,
    }),

    mutations: {
        [dirtyType.mutations.SET_DIALOG]: (state, payload) => {
            state.dialog = payload
        },
        [dirtyType.mutations.SET_DIRTY]: (state, payload) => {
            state.dirty = payload
        },
        [dirtyType.mutations.SET_NEXT_PAGE]: (state, payload) => {
            state.nextPage = payload
        },
        [dirtyType.mutations.SET_CANCELDELIVERYDIALOG]: (state, payload) => {
            state.deliveryDialog = payload
        },
    },
}
