import { EventBus } from '@/plugins/events'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

export default {
    props: {
        field: {
            type: null,
            required: true,
        },
        value: {
            type: null,
            default: null,
        },
    },
    data() {
        return {
            blocked: false,
        }
    },
    computed: {
        fieldValue() {
            return this.field?.value
        },
        fieldValueEnd() {
            return this.field?.other?.value
        },
        key() {
            return this.field?.key
        },
        keyEnd() {
            return this.field?.other?.key
        },
        type() {
            return this.field?.type
        },
        typeEnd() {
            return this.field?.other?.type
        },
        internalValue() {
            return this.type
                ? this.value[this.key]?.[this.type]
                : this.value[this.key]
        },
        internalValueEnd() {
            return this.typeEnd
                ? this.value[this.keyEnd]?.[this.typeEnd]
                : this.value[this.keyEnd]
        },
        isInterval() {
            return Boolean(this.field?.other)
        },
        hasInputValue() {
            return (
                this.field.inputType === 'select' ||
                this.field.inputType === 'date'
            )
        },
        active() {
            const active =
                this.validateValue() &&
                (!this.isInterval || this.validateValue(true))
            if (this.field.blocker) EventBus.$emit(this.field.blocker, active)
            return active
        },
    },
    created() {
        if (this.field.listener)
            EventBus.$on(this.field.listener, this.blockFilter)
    },
    mounted() {
        if (this.field.blocker) EventBus.$emit(this.field.blocker, this.active)
        if (this.field.default && !this.active) this.setValue()
    },
    methods: {
        validateValue(isEnd) {
            const internalValue = isEnd // value selected by user
                ? this.internalValueEnd
                : this.internalValue
            const fieldValue = isEnd ? this.fieldValueEnd : this.fieldValue // predefined value
            return Boolean(
                internalValue !== undefined && // has value selected by user
                    (fieldValue !== undefined // if has predefined value
                        ? this.field.dateValidation //if the value is date and has a comparison base
                            ? this.$date(fieldValue).isSame(
                                  internalValue,
                                  this.field.dateValidation
                              ) // compare two dates
                            : isEqual(internalValue, fieldValue) // compare values equality operator (string == int)
                        : internalValue)
            )
        },
        blockFilter(state) {
            this.blocked = state
        },
        setProperty(object, value, isEnd = false) {
            let type = isEnd ? this.typeEnd : this.type
            let key = isEnd ? this.keyEnd : this.key
            if (type) {
                if (!object[key]) object[key] = {}
                object[key][type] = value
            } else {
                object[key] = value
            }
            return object
        },
        deleteProperty(object, isEnd = false) {
            let type = isEnd ? this.typeEnd : this.type
            let key = isEnd ? this.keyEnd : this.key
            if (type) {
                delete object[key][type]
                if (!Object.keys(object[key]).length) delete object[key]
            } else {
                delete object[key]
            }
        },
        setValue(fieldValue) {
            let value = cloneDeep(this.value)
            if (this.hasInputValue ? fieldValue : !this.active) {
                this.setProperty(
                    value,
                    fieldValue ? fieldValue : this.fieldValue
                )
                if (this.isInterval)
                    this.setProperty(value, this.fieldValueEnd, true)
                this.$emit('input', value)
            } else {
                this.deleteProperty(value)
                if (this.isInterval) this.deleteProperty(value, true)

                this.$emit('input', value)
            }
        },
    },
    beforeDestroy() {
        if (this.field.listener)
            EventBus.$off(this.field.listener, this.blockFilter)
    },
}
