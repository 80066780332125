export default {
    getters: {
        DELIVERY_SETTINGS: 'deliverySettings',
        RESTAURANT: 'restaurant',
        ACTIVE_RESTAURANT: 'activeRestaurant',
        RESTAURANT_ID: 'restaurantId',
        MANAGED_RESTAURANT: 'getManagedRestaurant',
        MANAGED_RESTAURANTS: 'getManagedRestaurants',
    },
    mutations: {
        SET_DELIVERY_SETTINGS: 'setDeliverySettings',
        SET_RESTAURANT: 'setRestaurant',
        SET_ACTIVE_RESTAURANT: 'setActiveRestaurant',
        SET_ACTIVE_RESTAURANT_ID: 'setActiveRestaurantId',
        ADD_MANAGED_RESTAURANT: 'addManagedRestaurant',
    },
    actions: {
        SET_ACTIVE_RESTAURANT_ID: 'setActiveRestaurantId',
        GET_RESTAURANT: 'getRestaurant',
        LOAD_MANAGED_RESTAURANTS: 'loadManagedRestaurants',
    },
}
