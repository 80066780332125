import VueI18n from 'vue-i18n'
import Vue from 'vue'
import FileContentClient from '@/api/FileContentClient'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {},
})

export function setI18nLanguage(lang) {
    i18n.locale = lang
    document.querySelector('html').setAttribute('lang', lang)
}

export async function loadLocalization(lang, version) {
    const messages = await FileContentClient.getTranslationFile(lang, version)

    i18n.setLocaleMessage(lang, messages)
}
