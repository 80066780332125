import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import FormBase from './FormBase'

export default {
    inject: {
        $validator: 'formValidator',
    },
    mixins: [FormBase],
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: {},
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                this.form = cloneDeep(value)
            },
        },
        form: {
            deep: true,
            handler(form) {
                if (!isEqual(form, this.value)) {
                    this.$emit('input', cloneDeep(form))
                }
            },
        },
        tabErrorsContainer(tabErrorsContainer) {
            this.$emit('tabErrorsContainer', cloneDeep(tabErrorsContainer))
        },
    },
}
