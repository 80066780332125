import Vue from 'vue'
//transformDate
Vue.filter('transformDate', (value, addMinute = 0, short = false) => {
    if (!value) return ''
    if (typeof value !== 'string') return 'String value only!'
    value = Vue.prototype.$generalizeDate(value)
    const now = Vue.prototype.$date()
    let date = Vue.prototype.$date(value).add(addMinute, 'm')
    if (date.isSame(now, 'day')) return date.format('HH:mm')
    return date.format(short ? 'HH:mm' : 'MMM D, HH:mm')
})
