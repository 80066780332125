class MealOptionListMapper {
    map(list) {
        list.sort((a, b) => {
            return parseInt(a.position) - parseInt(b.position)
        })

        return list
    }
}

export default new MealOptionListMapper()
