import { ROLE_ADMIN } from '@/enum/roles'
import routeType from '@/router/routeType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

const passthroughRender = {
    render: (c) => c('router-view'),
}

export default [
    {
        path: '/custom-export',
        component: passthroughRender,
        meta,
        children: [
            {
                path: '',
                name: routeType.CUSTOM_EXPORT_LIST,
                component: () =>
                    import(
                        /* webpackChunkName: "custom-export-list" */ '@/pages/customExport/List.vue'
                    ),
                meta,
            },
            {
                path: 'create',
                name: routeType.CUSTOM_EXPORT_CREATE,
                component: () =>
                    import(
                        /* webpackChunkName: "custom-export-create" */ '@/pages/customExport/Create.vue'
                    ),
                meta: {
                    ...meta,
                },
            },
            {
                path: ':id',
                name: routeType.CUSTOM_EXPORT_EDIT,
                component: () =>
                    import(
                        /* webpackChunkName: "custom-export-edit" */ '@/pages/customExport/Edit.vue'
                    ),
                meta: {
                    ...meta,
                },
            },
        ],
    },
    {
        path: '/custom-export-show',
        name: routeType.CUSTOM_EXPORT_SHOW,
        component: () =>
            import(
                /* webpackChunkName: "custom-export-show" */ '@/pages/customExport/ShowExport.vue'
            ),
        meta: {
            ...meta,
        },
    },
    {
        path: '/custom-update-show',
        name: routeType.CUSTOM_UPDATE_SHOW,
        component: () =>
            import(
                /* webpackChunkName: "custom-export-show" */ '@/pages/customExport/ShowUpdate.vue'
            ),
        meta: {
            ...meta,
        },
    },
]
