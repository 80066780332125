<template>
    <div style="display: inline-block">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn text icon :color="color" v-on="on" @click="execute">
                    <v-icon>{{ icon }}</v-icon>
                </v-btn>
            </template>
            <span>{{ tooltip }}</span>
        </v-tooltip>

        <confirm-delivery-modal
            v-model="dialog"
            :title="confirmTitle ? confirmTitle : $t('texts.are_you_sure')"
            :text="confirmText"
            :content="content"
            :entity-id="entityId"
            :entity-count="entityCount"
            :order-id="orderId"
            :approve-text="approveText"
            :approve-color="approveColor"
            :cancel-text="cancelText"
            :processing="modalProcessing"
            @onApprove="confirm"
        ></confirm-delivery-modal>
    </div>
</template>

<script>
import ConfirmDeliveryModal from '@/components/confirm/ConfirmDeliveryModal'
import RestApiType from '@/api/RestApiType'

export default {
    components: {
        ConfirmDeliveryModal,
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            required: true,
        },
        confirmDialog: {
            type: Boolean,
            required: false,
        },
        confirmTitle: {
            type: String,
            default: null,
        },
        confirmText: {
            type: String,
            required: false,
            default: null,
        },
        entityId: {
            type: Number,
            required: true,
        },
        entityCount: {
            type: Number,
            required: true,
        },
        content: {
            type: Object,
            default: null,
        },
        approveText: {
            type: String,
            default: null,
        },
        approveColor: {
            type: String,
            default: null,
        },
        cancelText: {
            type: String,
            default: null,
        },
        orderId: {
            type: String,
            default: null,
        },
        deliveryJobId: {
            type: Number,
            default: null,
        },
        modalProcessing: {
            type: Boolean,
        },
    },
    data() {
        return {
            dialog: false,
            cancelordertype: null,
            api: RestApiType.DELIVERIES,
        }
    },
    methods: {
        execute() {
            if (this.confirmDialog) {
                this.dialog = true
            }
        },
        confirm(cancelordertype) {
            this.dialog = true
            this.$emit('execute', cancelordertype)
        },
    },
}
</script>
