import { ROLE_ADMIN } from '@/enum/roles'
import routeType from '@/router/routeType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

const breadCrumb = {
    to: {
        name: routeType.AUDIT_LOG_LIST,
    },
    label: 'menu.audit',
    roles: [ROLE_ADMIN],
}

const passthroughRender = {
    render: (c) => c('router-view'),
}

export default [
    {
        path: '/audit',
        component: passthroughRender,
        meta,
        children: [
            {
                path: '',
                name: routeType.AUDIT_LOG_LIST,
                component: () =>
                    import(
                        /* webpackChunkName: "audit-log" */ '@/pages/audit/List'
                    ),
                meta,
            },
            {
                path: ':entity/:id?',
                name: routeType.AUDIT_LOG_HISTORY,
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "audit-log" */ '@/pages/audit/History'
                    ),
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumb],
                },
            },
        ],
    },
]
