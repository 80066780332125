import { ROLE_ADMIN, ROLE_EXTRA_BILLING_ACCESS } from '@/enum/roles'
import routeType from '@/router/routeType'

const meta1 = {
    requiresAuth: true,
    roles: [ROLE_ADMIN, ROLE_EXTRA_BILLING_ACCESS],
    layout: 'ordit-authenticated-layout',
}

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

export default [
    {
        path: '/invoices',
        component: {
            render: (c) => c('router-view'),
        },
        meta,
        children: [
            {
                path: '',
                name: routeType.INVOICES_DASHBOARD,
                component: () =>
                    import(
                        /* webpackChunkName: "invoices-dashboard" */ '@/pages/invoices/List.vue'
                    ),
                meta: {
                    ...meta1,
                },
            },
            {
                path: 'create',
                component: {
                    render: (c) => c('router-view'),
                },
                meta: {
                    ...meta,
                },
                children: [
                    {
                        path: '',
                        name: routeType.INVOICES_CREATE,
                        component: () =>
                            import(
                                /* webpackChunkName: "invoices-create" */ '@/pages/invoices/Create.vue'
                            ),
                        meta: {
                            ...meta,
                        },
                    },
                    {
                        path: ':itemType/:itemId',
                        name: 'invoice-create-with-params',
                        component: () =>
                            import(
                                /* webpackChunkName: "invoices-create" */ '@/pages/invoices/Create.vue'
                            ),
                        meta: {
                            ...meta,
                        },
                    },
                ],
            },
            {
                path: ':entityId',
                name: routeType.INVOICES_EDIT,
                component: () =>
                    import(
                        /* webpackChunkName: "invoices-edit" */ '@/pages/invoices/Edit.vue'
                    ),
                meta: {
                    ...meta,
                },
            },
        ],
    },
]
