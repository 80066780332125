import Event from '@/mixins/Event'
import OrdersApiClient from '@/api/RestApi/OrdersApiClient'
import OrderFormMapper from '@/services/mapper/OrderFormMapper'
import throttle from 'lodash/throttle'

export default {
    mixins: [Event],
    data() {
        return {
            channelOrder: null,
            channelDeliveryJobs: null,
            logToConsole: false,
            counter: 0,
            throttleOrder: null,
            deliveryEventHistory: [],
        }
    },
    mounted() {
        this.throttleOrder = throttle(this.updateList, 5000)

        this.channelOrder = this.pusher.subscribe('orders')
        this.channelDeliveryJobs = this.pusher.subscribe('delivery-jobs')

        this.channelOrder.bind('create-order', this.createOrder)
        this.channelOrder.bind('update-order', this.updateOrder)
        this.channelDeliveryJobs.bind(
            'create-delivery-job',
            this.createDelivery
        )
        this.channelDeliveryJobs.bind(
            'update-delivery-job',
            this.updateDelivery
        )
    },
    beforeDestroy() {
        if (this.pusher) {
            this.pusher.disconnect()
        }
    },
    methods: {
        createDelivery(data) {
            if (data) {
                let orderIds = JSON.parse(
                    data.data?.data_order_ids?.new || '[]'
                )
                if (orderIds?.length) {
                    orderIds?.forEach((id) => {
                        let itemKey = this.findOrder(id)
                        this.updateItem(
                            data.data,
                            itemKey,
                            this.mapDeliveryJob,
                            'deliveryJob'
                        )
                    })
                } else {
                    if (this.findOrders(data.id, 'deliveryJob').length)
                        this.updateDelivery(data)
                    else this.deliveryEventHistory.push(data)
                }
            }
        },
        updateDelivery(data) {
            if (data) {
                const itemKeys = this.findOrders(data.id, 'deliveryJob')
                itemKeys.forEach((itemKey) => {
                    this.updateItem(
                        data.data,
                        itemKey,
                        this.mapDeliveryJob,
                        'deliveryJob'
                    )
                })
            }
        },
        createOrder(data) {
            if (data) {
                if (this.filtersOn) {
                    this.throttleOrder()
                } else {
                    this.getOrder(data.id).then((data) => {
                        this.$refs.iterator.items.unshift(data)
                    })
                }
            }
        },
        updateOrder(data) {
            const itemKey = this.findOrder(data.id)
            // console.log('updateOrder', data.data)
            if ('approved_at' in data.data && !('status' in data.data)) {
                OrdersApiClient.list({ id: data.id }).then((res) => {
                    this.$set(
                        this.$refs.iterator.items,
                        itemKey,
                        res['hydra:member'][0]
                    )
                })
            } else {
                this.updateItem(data.data, itemKey, this.mapOrder)
            }
            if (data.data.delivery_job_id) {
                const eventIndex = this.deliveryEventHistory.findIndex(
                    (deliveryEvent) =>
                        deliveryEvent.id === data.data.delivery_job_id.new
                )
                if (eventIndex > -1) {
                    this.updateDelivery(this.deliveryEventHistory[eventIndex])
                    this.deliveryEventHistory.splice(eventIndex, 1)
                }
            }
        },
        getOrder(orderId) {
            return new Promise((resolve, reject) => {
                OrdersApiClient.get(orderId)
                    .then((data) => {
                        const entity = OrderFormMapper.fromEntity(data)
                        resolve(entity)
                    })
                    .catch((reason) => {
                        reject(reason)
                    })
            })
        },
    },
}
