export default {
    mutations: {
        SET: 'meal_set',
        MEAL_CATEGORY: 'meal_set_category',
        MEAL_OPTION_GROUP: 'meal_set_option_group',
        MEAL_OPTION: 'meal_set_option',
        CLEAR_ALL: 'clear_all',
    },
    actions: {
        MEAL_CATEGORY: 'meal_set_category',
        SET: 'meal_set',
        MEAL_OPTION_GROUP: 'meal_set_option_group',
        MEAL_OPTION: 'meal_set_option',
        CLEAR_ALL: 'clear_all',
        REFRESH_LIST: 'refresh_list',
    },
    getters: {
        MEAL_CATEGORY: 'meal_category',
        MEAL: 'meal',
        MEAL_OPTION_GROUP: 'meal_option_group',
        MEAL_OPTION: 'meal_option',
    },
}
