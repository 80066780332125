import Vue from 'vue'
import store from '@/store/store'
import configType from '@/store/type/configType'

Vue.filter(
    'toFullName',
    (value, firstNameKey = 'firstName', lastNameKey = 'lastName') => {
        if (!value) return
        const locale = store.getters[configType.getters.CURRENT_LOCALE]
        const firstName = value[firstNameKey]
        const lastName = value[lastNameKey]
        return locale === 'hu'
            ? `${lastName} ${firstName}`
            : `${firstName} ${lastName}`
    }
)
