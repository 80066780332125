import mealType from './type/mealType'
import Vue from 'vue'
import { EventBus } from '@/plugins/events'

export default {
    state: {
        meal: {},
        category: {},
        mealOption: {},
        mealOptionGroup: {},
    },
    mutations: {
        [mealType.mutations.SET](state, data) {
            Vue.set(state, 'meal', data)
        },

        [mealType.mutations.MEAL_CATEGORY](state, data) {
            Vue.set(state, 'category', data)
        },

        [mealType.mutations.MEAL_OPTION_GROUP](state, data) {
            Vue.set(state, 'mealOptionGroup', data)
        },

        [mealType.mutations.MEAL_OPTION](state, data) {
            Vue.set(state, 'mealOption', data)
        },

        [mealType.mutations.CLEAR_ALL](state) {
            state.meal = {}
            state.category = {}
            state.mealOption = {}
            state.mealOptionGroup = {}
        },
    },
    actions: {
        [mealType.actions.MEAL_CATEGORY]({ commit }, data) {
            commit(mealType.mutations.MEAL_CATEGORY, data)
        },
        [mealType.actions.SET]({ commit }, data) {
            commit(mealType.mutations.SET, data)
        },
        [mealType.actions.MEAL_OPTION_GROUP]({ commit }, data) {
            commit(mealType.mutations.MEAL_OPTION_GROUP, data)
        },
        [mealType.actions.MEAL_OPTION]({ commit }, data) {
            commit(mealType.mutations.MEAL_OPTION, data)
        },
        [mealType.actions.CLEAR_ALL]({ commit }) {
            commit(mealType.mutations.CLEAR_ALL)
        },
        [mealType.actions.REFRESH_LIST](_, data) {
            EventBus.$emit('updateList', data)
        },
    },
    getters: {
        [mealType.getters.MEAL_CATEGORY]: (state) =>
            JSON.parse(JSON.stringify(state.category)),
        [mealType.getters.MEAL]: (state) =>
            JSON.parse(JSON.stringify(state.meal)),
        [mealType.getters.MEAL_OPTION_GROUP]: (state) =>
            JSON.parse(JSON.stringify(state.mealOptionGroup)),
        [mealType.getters.MEAL_OPTION]: (state) =>
            JSON.parse(JSON.stringify(state.mealOption)),
    },
}
