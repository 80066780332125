import apiClient from '@/api/ApiClient'
import Notification from '@/services/Notification/Notification'

class FileContentClient {
    getTranslationFile(lang, version) {
        return apiClient
            .get('/public/translations/' + lang + '/' + version)
            .then((response) => {
                return Promise.resolve(response.data)
            })
            .catch((error) => {
                Notification.requestError(error)

                return Promise.reject(error)
            })
    }
}

export default new FileContentClient()
