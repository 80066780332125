function getConfigValue(key) {
    let value = undefined
    if (process.env && process.env[`${key}`] !== undefined) {
        value = process.env[`${key}`]
    } else {
        return getMetaValue(key)
    }
    return value
}

function getMetaValue(key) {
    let value = undefined
    const node = document.querySelector(`meta[property=${key}]`)
    if (node !== null) {
        value = node.getAttribute('content')
    }
    return value
}

export default { getConfigValue, getMetaValue }
