import dayjs from '@/plugins/date'

export function isCanteenOrder(order) {
    return order?.cloudcanteen || order?.residentialcanteen
}

export function isMultiCutoffCanteenOrder(order) {
    return order?.canteenRules?.length > 1
}

export function isMultiCutoffSuborder(order) {
    return 'canteenSuborderForCutoff' in order && 'isConfirmed' in order
}

export function isMultiCutoffBaseOrder(order) {
    return (
        order?.canteenRules?.length > 0 &&
        order?.canteenSubOrdersByCutoff?.length > 0 &&
        !('canteenSuborderForCutoff' in order)
    )
}

export function isActiveOrder(order) {
    return (
        (order?.status === 'new' || order?.status === 'approved') &&
        order?.isActiveForRestaurant === true
    )
}

export function subordersPerCutoff(order) {
    if (!isMultiCutoffCanteenOrder(order) || order?.mealsQuantity === 0) {
        return [order]
    }

    const noti = order.canteenData?.collected_orders_notification || []
    const suborders = []
    for (let ruleIndex in order.canteenSubOrdersByCutoff) {
        const suborder = order.canteenSubOrdersByCutoff[ruleIndex]
        const cutoffTime = dayjs(suborder.cutoffTime)

        const isConfirmed = !!noti.find((el) =>
            cutoffTime.isSame(el.cutoffTime)
        )

        const index = parseInt(ruleIndex) + 1

        const newSuborder = {
            ...order,
            id: `${order.id}-${index}`,
            baseId: order.id,
            identifierForRestaurant: `${order.identifierForRestaurant}-${index}`,
            mealsQuantity: suborder.mealsQuantity,
            priceSumItems: suborder.priceSumItems,
            supplierPriceSumItems:
                suborder.supplierPriceSumItems || suborder.priceSumItems,
            canteenSuborderForCutoff: cutoffTime.format('YYYY-MM-DD HH:mm:ss'),
            isConfirmed,
        }

        if (order.meals) {
            newSuborder.meals = order.meals.filter((meal) =>
                cutoffTime.isSame(meal.canteenCutoffTime, 'm')
            )
        }

        suborders.push(newSuborder)
    }

    return suborders
}

export function nextUpcomingCutoffOrder(baseOrders) {
    const now = dayjs()
    let date = null
    let orderId = null

    baseOrders.forEach((baseOrder) => {
        const rules = baseOrder.canteenRules || []

        for (let rule of rules) {
            if (now.isBefore(rule.cutoffTime)) {
                if (!date || date.isAfter(rule.cutoffTime)) {
                    date = dayjs(rule.cutoffTime)
                    orderId = baseOrder.id
                }
            }
        }
    })

    return date ? { date: date.format('YYYY-MM-DD HH:mm:ss'), orderId } : null
}

/**
 * @typedef MultiCutoffDetail
 * @type {object}
 * @property {number} nr
 * @property {?string} confirmed
 * @property {number} count
 * @property {string} cutoff
 */
/**
 * @returns {null|Array.<MultiCutoffDetail>}
 */
export function multicutoffDetails(order) {
    const subordersByCutoff = order?.canteenSubOrdersByCutoff
    if (
        !subordersByCutoff?.length ||
        subordersByCutoff?.length < 1 ||
        !isMultiCutoffCanteenOrder(order)
    ) {
        return null
    }

    const result = []
    for (let i in subordersByCutoff) {
        const subOrders = subordersByCutoff[i]
        const cutoff = dayjs(subOrders.cutoffTime)
        const nr = parseInt(i) + 1
        const confirmed = (
            order.canteenData?.collected_orders_notification || []
        ).find((el) => cutoff.isSame(el.cutoffTime, 'm'))
        const count = subOrders.orderIds?.length || 0
        const limit =
            order.canteenRules.find(
                (el) => el.cutoffTime === subOrders.cutoffTime
            )?.limit || 0
        const format = dayjs().isSame(cutoff, 'd') ? 'HH:mm' : 'MMM D, HH:mm'

        result.push({
            nr,
            confirmed,
            count,
            limit,
            cutoffTime: subOrders.cutoffTime,
            cutoff: cutoff.format(format),
        })
    }

    return result
}

export default {
    isMultiCutoffCanteenOrder,
    isMultiCutoffSuborder,
    isMultiCutoffBaseOrder,
    isActiveOrder,
    subordersPerCutoff,
    nextUpcomingCutoffOrder,
    multicutoffDetails,
}
