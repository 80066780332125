import Vue from 'vue'
import router from '@/router/router'
import store from '@/store/store'

import { i18n } from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'

import configType from '@/store/type/configType'
import Notification from '@/services/Notification/Notification'

import {
    fireReadyEvent,
    handleSetJWTTokenEvent,
} from '@/router/authIntegration'

import '@/plugins/toastr'
import '@/plugins/portal'
import '@/plugins/validation'
import '@/plugins/date'
import '@/plugins/layouts'
import '@/plugins/errorHandler'
import '@/plugins/firebase'
import '@/plugins/cookies'
import '@/plugins/intercom'

import '@/filters/CurrencyFilter'
import '@/filters/ScheduleFilter'
import '@/filters/DateFilter'
import '@/filters/transformDate'
import '@/filters/removeCountry'
import '@/filters/toFullName'

import '@/styles/main.scss'
import './registerServiceWorker'
import App from '@/App'

//TODO tree-shake lodash

Vue.config.productionTip = true

store.dispatch(configType.actions.INIT)

handleSetJWTTokenEvent()

const vue = new Vue({
    el: '#app',
    i18n,
    router,
    store,
    vuetify,
    mounted: function () {
        fireReadyEvent()
    },
    render: (h) => h(App),
})

Notification.setVueInstance(vue)

vue.$mount()
