<template>
    <portal v-if="$slots.default || text" to="header" :order="1">
        <h1>
            <slot>{{ text }}</slot>
        </h1>
    </portal>
</template>

<script>
export default {
    props: {
        title: String,
        translated: String,
    },
    computed: {
        text() {
            return this.translated ? this.translated : this.$t(this.title)
        },
    },
    watch: {
        text: {
            immediate: true,
            handler(val) {
                document.title = `${val} | ${this.$t('global.app_title')}`
            },
        },
    },
}
</script>
