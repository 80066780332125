import routeInfoType from './type/routeInfoType'
import UsersApiClient from '@/api/RestApi/UsersApiClient'
import CompaniesApiClient from '@/api/RestApi/CompaniesApiClient'

export default {
    state: () => ({
        users: {},
        companies: {},
        loaders: {},
    }),
    getters: {
        [routeInfoType.getters.GET_USERS]: (state) => state.users,
        [routeInfoType.getters.GET_COMPANIES]: (state) => state.companies,
    },
    mutations: {
        [routeInfoType.mutations.SET_USER]: (state, payload) => {
            if (payload.id && payload.value) {
                state.users = {
                    ...state.users,
                    [payload.id]: payload.value,
                }
            }
        },
        [routeInfoType.mutations.SET_COMPANY]: (state, payload) => {
            if (payload.id && payload.value) {
                state.companies = {
                    ...state.companies,
                    [payload.id]: payload.value,
                }
            }
        },
        [routeInfoType.mutations.SET_LOADER]: (state, payload) => {
            if (payload.promise) {
                state.loaders = {
                    ...state.loaders,
                    [payload.id]: payload.promise,
                }
            } else {
                delete state.loaders[payload.id]
            }
        },
    },
    actions: {
        [routeInfoType.actions.GET_USER]: async ({ state, commit }, userId) => {
            if (!state.users[userId]) {
                let id = `user-${userId}`
                let promise = state.loaders[id]
                if (!promise) {
                    promise = UsersApiClient.get(userId)
                    commit(routeInfoType.mutations.SET_LOADER, {
                        id,
                        promise,
                    })
                }

                await promise
                commit(routeInfoType.mutations.SET_LOADER, {
                    id,
                    promise: null,
                })
            }

            return state.users[userId]
        },
        [routeInfoType.actions.GET_COMPANY]: async (
            { state, commit },
            companyId
        ) => {
            if (!state.companies[companyId]) {
                let id = `company-${companyId}`
                let promise = state.loaders[id]
                if (!promise) {
                    promise = CompaniesApiClient.get(companyId)
                    commit(routeInfoType.mutations.SET_LOADER, {
                        id,
                        promise,
                    })
                }

                await promise
                commit(routeInfoType.mutations.SET_LOADER, {
                    id,
                    promise: null,
                })
            }

            return state.companies[companyId]
        },
    },
}
