import routeInfoType from '@/store/type/routeInfoType'
import Store from '@/store/store'
import AbstractRestApi from '@/api/RestApi/AbstractRestApi'

class DeliverySettings extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/delivery-setting'
    }
    get(id) {
        return super.get(id).then((data) => {
            Store.commit(routeInfoType.mutations.SET_DELIVERY_SETTINGS, data)
            return data
        })
    }
}

export default new DeliverySettings()
