<template>
    <table-button
        :tooltip="$t('actions.delete')"
        color="blue-grey"
        icon="delete"
        confirm-dialog
        :confirm-text="confirmText"
        :strong-confirm="strong"
        approve-color="error"
        @execute="execute"
    ></table-button>
</template>

<script>
import TableButton from '@/components/table/buttons/TableButton'
import RestApiCollection from '@/api/RestApiCollection'

export default {
    components: {
        TableButton,
    },
    props: {
        entityName: {
            type: String,
            required: true,
        },
        api: {
            type: String,
            required: true,
        },
        entityId: {
            type: Number,
            required: true,
        },
        strong: Boolean,
    },
    computed: {
        confirmText() {
            return this.$t('texts.you_will_delete_this_item', {
                item: this.entityName,
            })
        },
    },
    methods: {
        execute() {
            RestApiCollection.get(this.api)
                .delete(this.entityId)
                .then(() => {
                    this.$emit('deleted', this.entityName)
                })
                .catch(() => {
                    this.$emit('failed', this.entityName)
                })
        },
    },
}
</script>
