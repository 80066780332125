import SelectApiClientType from '@/api/SelectApiClientType'
import RestApiType from '@/api/RestApiType'
import cloneDeep from 'lodash/cloneDeep'
import MapperType from '@/services/mapper/MapperType'
import BaseForm from '@/components/form/BaseForm.vue'

export default {
    components: {
        BaseForm,
    },

    provide() {
        return {
            formValidator: this.$validator,
        }
    },

    props: ['entityId'],

    data() {
        return {
            form: {},
            entity: '',
            editRoute: '',
            listRoute: '',
            disabled: false,
            mapperType: MapperType,
            selectApiClientType: SelectApiClientType,
            apiClientType: RestApiType,
            tabErrorsContainer: {},
        }
    },

    computed: {
        title() {
            return this.form.name
        },
        tabErrors() {
            const tabErrors = {}

            Object.keys(this.tabErrorsContainer).forEach((tabName) => {
                Object.keys(this.tabErrorsContainer[tabName]).forEach(
                    (propertyName) => {
                        if (!tabErrors[tabName]) {
                            tabErrors[tabName] = 0
                        }

                        if (
                            this.tabErrorsContainer[tabName][propertyName] > 0
                        ) {
                            tabErrors[tabName]++
                        }
                    }
                )
            })

            return tabErrors
        },
    },

    methods: {
        created(data) {
            this.disabled = true
            if (this.$route.query.redirectTo) {
                this.$router.push(this.$route.query.redirectTo)
            } else {
                this.$router.push({
                    name: this.editRoute,
                    params: this.editRedirectParams(data),
                    query: { tab: this.$route.query.tab || 0 },
                })
            }
        },
        updated() {
            if (this.$route.query.redirectTo) {
                this.$router.push(this.$route.query.redirectTo)
            }
        },
        cancelled() {
            this.$router.push({
                name: this.listRoute,
                params: this.listRedirectParams(),
            })
        },
        deleted() {
            this.$router.push({
                name: this.listRoute,
                params: this.listRedirectParams(),
            })
        },
        mergeTabErrorsContainer(tabErrorsContainer) {
            this.tabErrorsContainer = cloneDeep(
                Object.assign(this.tabErrorsContainer, tabErrorsContainer)
            )
        },
        // to be overridden by forms when param names change
        editRedirectParams: (data) => ({ id: data.id }),
        listRedirectParams: () => ({}),
    },
}
