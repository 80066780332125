import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'

export default {
    computed: {
        ...mapGetters({
            companyObj: authType.getters.ACTIVE_COMPANY_OBJ,
        }),
        serviceFee() {
            return (
                this.hasServiceFee &&
                parseFloat(this.companyObj?.billingInfo?.serviceFee)
            )
        },
        hasServiceFee() {
            return !!(
                this.companyObj?.isServiceFeeVisible &&
                this.companyObj?.billingInfo?.serviceFee
            )
        },
    },
    methods: {
        getServiceFee(order) {
            return this.hasServiceFee
                ? Math.round(order.priceSumFirm * this.serviceFee * 1.27)
                : 0
        },
        orderPriceTotal(order) {
            return order.priceTotal + this.getServiceFee(order)
        },
    },
}
