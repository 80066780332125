import apiClient from '@/api/ApiClient'
import ResponseHandler from '@/api/response/ResponseHandler'

class ConfigApiClient {
    get() {
        return ResponseHandler.handle(apiClient.get('/api/v1/configs'))
    }
}

export default new ConfigApiClient()
