<template>
    <v-dialog
        :value="value"
        :max-width="maxWidth"
        persistent
        :fullscreen="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
        :transition="false"
        @input="$emit('input', $event)"
    >
        <slot name="content">
            <Overlay :show="showOverlay" style="height: 100%">
                <v-card height="100%">
                    <v-card-title class="headline">
                        {{ title }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="$emit('close')">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        <slot></slot>
                    </v-card-text>
                    <v-card-actions class="mx-auto">
                        <v-spacer></v-spacer>
                        <slot name="actions">
                            <v-btn
                                class="grey lighten-2"
                                depressed
                                @click="$emit('downloadLabels')"
                            >
                                {{
                                    downloadText || $t('orders.download_labels')
                                }}
                                <v-icon class="ml-1" small
                                    >picture_as_pdf</v-icon
                                >
                            </v-btn>
                            <v-btn
                                color="success"
                                class="white--text"
                                depressed
                                @click="$emit('printLabels')"
                            >
                                {{ printText || $t('orders.print_labels') }}
                                <v-icon class="ml-1" small>print</v-icon>
                            </v-btn>
                        </slot>
                    </v-card-actions>
                </v-card>
            </Overlay>
        </slot>
    </v-dialog>
</template>

<script>
import Overlay from '@/components/overlay/Overlay'

export default {
    components: {
        Overlay,
    },
    props: {
        value: {
            type: Boolean,
        },
        showOverlay: {
            type: Boolean,
        },
        title: {
            type: String,
            default: null,
        },
        downloadText: {
            type: String,
            default: null,
        },
        printText: {
            type: String,
            default: null,
        },
        maxWidth: {
            type: [Number, String],
            default: 500,
        },
    },
}
</script>
