import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'

const baseUrl = '/api/v1/audit-log'

class AuditLogApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/auditLog'
    }

    get(id, entity, query) {
        return ResponseHandler.handle(
            apiClient.get(`${this.getBaseUrl()}/${entity}/${id}`, {
                params: query,
            })
        )
    }

    list() {
        return ResponseHandler.handle(apiClient.get(`${baseUrl}/list`))
    }

    history(entity, id, query) {
        let url = `${baseUrl}/${entity}`
        if (id) {
            url += `/${id}`
        }
        return ResponseHandler.handle(
            apiClient.get(url, {
                params: query,
            })
        )
    }
}

export default new AuditLogApiClient()
