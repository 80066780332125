<template lang="pug">
.d-flex.align-center
    v-icon(:color="color" small) location_on
    .d-flex.flex-column(v-if="!reason && !isMultiCutoff")
        | {{ $t(`enums.order_status.${status}`) }}
        small.d-flex.flex-nowrap.g-73--text(v-if="updatedAt") {{updatedAt | transformDate}}
    v-tooltip(v-else, bottom)
        template(v-slot:activator="{ on }")
            .d-flex.flex-column(v-on="on")
                | {{ $t(`enums.order_status.${status}`) }} {{ reason || multicutoffProgressText }}
                small.d-flex.flex-nowrap.g-73--text(v-if="updatedAt") {{updatedAt | transformDate}}
        span(v-if="!isMultiCutoff") {{ fullReason }}
        span(v-else)
            span.d-block(v-for="detail in multicutoffDetails" :key="detail.line1")
                span.d-block {{ detail.line1 }}
                span.d-block.mb-2 {{ detail.line2 }}
</template>

<script>
import { multicutoffDetails } from '@/services/Orders/CanteenMultiCutoffRuleLogic'

export default {
    props: {
        item: null,
    },
    computed: {
        status() {
            return this.item?.status
        },
        updatedAt() {
            return this.item?.updatedAt
        },
        reason() {
            return this.item?.cancelReason
                ? `(${this.item?.cancelReason.substring(0, 22)})`
                : this.cappedCancelReason
        },
        fullReason() {
            let reason = this.item?.cancelReason
            const UAregex = /(, )?(useragent|user-agent):.*$/i
            if (typeof reason === 'string' && UAregex.test(reason)) {
                reason = reason.replace(UAregex, '')
            }

            if (!reason) {
                reason = this.cancelReason
            }

            return reason
        },
        cancelReason() {
            let str = null
            const reasonType = this.item?.adminCancelReasonType
            if (reasonType) {
                str = this.$t(`enums.order_cancel_reasons.${reasonType}`)
                if (this.item?.adminCancelReason) {
                    str += ': ' + this.item?.adminCancelReason
                }
            }
            return str
        },
        cappedCancelReason() {
            let reason = this.cancelReason
            if (reason) {
                reason = ' - ' + reason.substring(0, 18)
            }
            return reason
        },
        color() {
            switch (this.status) {
                case 'init-payment':
                    return 'yellow'
                case 'new':
                    return 'info'
                case 'cancelled':
                    return 'warning'
                case 'cancelled-after-approval':
                    return 'error'
                default:
                    return 'success'
            }
        },
        isMultiCutoff() {
            return (
                (this.item?.cloudcanteen || this.item?.residentialcanteen) &&
                !this.item?.userProfile &&
                this.item?.canteenRules?.length > 1
            )
        },
        multicutoffProgressText() {
            const result = {
                confirmed: [],
                total: this.item?.canteenRules?.length || 0,
            }

            if (this.item?.canteenRules?.length > 1) {
                const now = this.$date()
                let j = 0

                for (let i in this.item.canteenRules) {
                    const rule = this.item.canteenRules[i]
                    const cutoff = this.$date(rule.cutoffTime)

                    const notification = (
                        this.item.canteenData?.collected_orders_notification ||
                        []
                    ).find((el) => cutoff.isSame(el.cutoffTime, 'm'))
                    const subOrders = (
                        this.item.canteenSubOrdersByCutoff || []
                    ).find((el) => cutoff.isSame(el.cutoffTime, 'm'))

                    if (now.isAfter(cutoff)) {
                        if (!subOrders) {
                            result.total--
                        } else if (notification) {
                            result.confirmed.push(++j)
                        }
                    }
                }

                result.confirmed = result.confirmed.join(', ') || '0'

                return `(${result.confirmed} / ${result.total})`
            }

            return ''
        },
        multicutoffDetails() {
            const details = multicutoffDetails(this.item) || []
            return details.map((el) => {
                const notiTxt = el.confirmed
                    ? this.$t('orders.confirmed') + ' | '
                    : ''
                return {
                    line1: `${el.nr}. ${this.$t('orders.multi_cutoff')}, ${
                        el.cutoff
                    }`,
                    line2:
                        notiTxt +
                        this.$tc('labels.orders_count', el.count, {
                            n: el.count,
                        }),
                }
            })
        },
    },
}
</script>

<style lang="scss">
.editable-field {
    width: 100px;
}
</style>
