import cloneDeep from 'lodash/cloneDeep'

class MealFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        // convert to ids
        if (entity['imageMedia'] && entity['imageMedia']['@id']) {
            entity['imageMedia'] = entity['imageMedia']['@id']
        }

        if (entity['thumbMedia'] && entity['thumbMedia']['@id']) {
            entity['thumbMedia'] = entity['thumbMedia']['@id']
        }

        if (!entity['deliveryUnit']) {
            entity['deliveryUnit'] = 1
        }

        if (entity['discountPrice'] == '') {
            entity['discountPrice'] = null
        }

        if (entity?.supplierPrice == '') {
            entity.supplierPrice = null
        }

        entity.kcal = entity.kcal ? parseInt(entity.kcal) : null
        entity.minimumQuantity = entity.minimumQuantity
            ? parseInt(entity.minimumQuantity)
            : 1

        if (entity['groupsOrder'] && entity['groupsOrder'] instanceof Array) {
            entity['groupsOrder'] = entity['groupsOrder'].filter((el) => el?.id)
            if (!entity['groupsOrder'].length) entity['groupsOrder'] = null
        }

        return entity
    }

    fromEntity(entity) {
        const formData = cloneDeep(entity)

        return formData
    }
}

export default new MealFormMapper()
