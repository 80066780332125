import cloneDeep from 'lodash/cloneDeep'

class ResidentialCanteenRelationsFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)
        if (entity?.restaurant) {
            values.restaurant = entity.restaurant['@id']
            values.restaurantName = entity.restaurant['name']
        }
        if (entity?.address) {
            values.address = entity.address['@id']
            values.addressName = entity.address['name']
        }

        return values
    }
}

export default new ResidentialCanteenRelationsFormMapper()
