import cloneDeep from 'lodash/cloneDeep'
import CommonCanteen from './CanteenCommonMapper'

class ResidentialCanteensFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        CommonCanteen.toEntity(entity)
        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)
        if (entity?.address) {
            values.address = entity.address['@id']
        }

        CommonCanteen.fromEntity(entity, values)
        return values
    }
}

export default new ResidentialCanteensFormMapper()
