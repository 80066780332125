import cloneDeep from 'lodash/cloneDeep'

class DeliveryFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)
        if (entity['vehicleType'] && entity.deliveryJob) {
            entity.deliveryJob.vehicleType = entity['vehicleType']
            delete entity.vehicleType
        }

        return entity
    }

    fromEntity(entity) {
        const formData = cloneDeep(entity)
        formData.disableAutoCancel = false

        return formData
    }
}

export default new DeliveryFormMapper()
