<template>
    <v-breadcrumbs
        v-if="items.length"
        :items="items"
        large
        class="pl-0 pb-0 pt-1 ordit-breadcrumbs"
    >
        <template v-slot:divider>
            <v-icon>chevron_right</v-icon>
        </template>
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                :to="item.to"
                :exact="true"
                class="brandalt--text"
            >
                {{ item.text }}
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script>
import Vue from 'vue'
import { LABEL_PH } from '@/defaults'
import selectapiType from '@/store/type/selectapiType'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    mixins: [AuthMixin],
    props: {
        value: Boolean,
    },
    computed: {
        items() {
            return Vue.observable(this.getBreadcrumbs()) // bayi magic
        },
    },
    methods: {
        getBreadcrumbs() {
            const breadcrumbs = []
            this.$route.matched.forEach((route) => {
                let routeBc = route.meta.breadcrumbs || []

                routeBc.forEach((breadcrumb) => {
                    if (!this.userHasRoles(breadcrumb.roles)) {
                        return
                    }

                    let clone = { ...breadcrumb },
                        strLabel = typeof clone.label === 'string'

                    clone.text = strLabel ? this.$t(clone.label) : LABEL_PH

                    if (!strLabel) {
                        this.$store
                            .dispatch(
                                selectapiType.getActionName(
                                    clone.label.selectType,
                                    selectapiType.actions.LOAD
                                )
                            )
                            .then((data) => {
                                const id = parseInt(
                                    this.$route.params[clone.label.idParamName]
                                )
                                const label = data.find((val) => val.id === id)
                                if (label) {
                                    clone.text = label.name
                                }
                            })
                    }

                    breadcrumbs.push(clone)
                })
            })
            this.$emit('input', Boolean(breadcrumbs.length))
            return breadcrumbs
        },
    },
}
</script>

<style lang="scss">
.ordit-breadcrumbs {
    margin-top: 4px;
    .v-application & a {
        color: inherit;
    }
}
</style>
