<template lang="pug">
v-textarea.w-input-sm(
    v-if="item"
    v-model="value"
    :key="item.id"
    :disabled="disabled"
    :rows="2"
    dense
    hide-details
    auto-grow)
    template(slot="append")
        v-fade-transition
            v-btn(v-if="value && isDirty" color="primary" @click="cancel" icon plain x-small)
                v-icon settings_backup_restore
        v-btn.ml-2(v-if="!disabled" @click="update" color="primary" :disabled="!value || !isDirty"  icon plain x-small)
            v-icon save
</template>

<script>
import RestApiCollection from '@/api/RestApiCollection'

export default {
    props: {
        item: null,
        api: null,
        updateList: Function,
        disabled: Boolean,
    },
    data() {
        return {
            value: null,
        }
    },
    computed: {
        isDirty() {
            return this.value !== this.item.costCode
        },
    },
    watch: {
        'item.costCode': {
            immediate: true,
            handler(code) {
                if (code) {
                    this.value = code
                }
            },
        },
    },
    methods: {
        update() {
            RestApiCollection.get(this.api)
                .update(
                    { ...this.item, ...{ costCode: this.value } },
                    this.item.id
                )
                .then(() => {
                    this.updateList()
                })
        },
        cancel() {
            this.value = this.item.costCode
        },
    },
}
</script>

<style scoped lang="scss"></style>
