import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import SelectApiClientType from '@/api/SelectApiClientType'

class CompanyAddresses extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/company-addresses'
    }

    getRelatedSelectApi() {
        return SelectApiClientType.COMPANY_ADDRESS
    }
}

export default new CompanyAddresses()
