<template lang="pug">
modal-dialog(
    v-model="dialog"
    :title="title ? title : $t('texts.are_you_sure')"
    cancel-button
    :cancel-text="cancelText"
    :ok-text="approveText"
    :ok-color="approveColor"
    :show-overlay="processing"
    :title-center="titleCenter"
    :text-center="textCenter"
    @cancel="onCancel"
    @ok="onApprove")
    p.m-0(:class="{'text-center': textCenter}" :inner-html.prop="text")
</template>

<script>
import ModalDialog from '@/components/dialog/ModalDialog'

export default {
    components: {
        ModalDialog,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        approveText: {
            type: String,
            default: null,
        },
        approveColor: {
            type: String,
            default: null,
        },
        cancelText: {
            type: String,
            default: null,
        },
        titleCenter: {
            type: Boolean,
            default: false,
        },
        textCenter: {
            type: Boolean,
            default: false,
        },
        processing: {
            type: Boolean,
        },
    },
    data() {
        return {
            dialog: false,
        }
    },
    watch: {
        value() {
            this.dialog = this.value
        },
    },
    methods: {
        onApprove() {
            this.$emit('input', false)
            this.$emit('onApprove')
        },
        onCancel() {
            this.$emit('input', false)
            this.$emit('onCancel')
        },
    },
}
</script>
