<template>
    <v-switch
        :input-value="value"
        :name="name"
        :label="labelText"
        type="checkbox"
        :color="color"
        :hide-details="hideDetails"
        :error-messages="errorMessages"
        :disabled="disabled"
        @change="$emit('input', $event)"
    ></v-switch>
</template>

<script>
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'

export default {
    mixins: [ErrorMsgInputMixin],
    inject: {
        $validator: '$validator',
    },
    props: {
        value: {
            type: Boolean,
        },
        color: {
            type: String,
            default: 'success',
        },
        hideDetails: {
            type: [Boolean, String],
            default: false,
        },
        disabled: Boolean,
    },
}
</script>
