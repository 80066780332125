import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'

class TagGroupsApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/restaurant-tag-groups'
    }

    clone(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'clone'))
        )
    }

    get(id) {
        return ResponseHandler.handle(apiClient.get(this.getItemIri(id)))
    }
}

export default new TagGroupsApiClient()
