<template>
    <v-text-field
        :value="internalValue ? internalValue : value"
        :name="name"
        :label="labelText"
        :error-messages="errorMessages"
        :autocomplete="autocomplete"
        :type="type"
        :suffix="suffix"
        :prefix="prefix"
        :autofocus="autofocus"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        :append-icon="appendIcon"
        :prepend-icon="prependIcon"
        :hint="hint"
        :hide-details="hideDetails"
        :counter="counter"
        :min="min"
        :max="max"
        @input="inputHandler"
        @blur="blurHandler"
    ></v-text-field>
</template>

<script>
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'

export default {
    mixins: [ErrorMsgInputMixin],
    inject: {
        $validator: '$validator',
    },
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        autocomplete: {
            type: String,
            default: 'off',
        },
        type: {
            type: String,
            default: 'text',
        },
        suffix: {
            type: String,
            default: undefined,
        },
        prefix: {
            type: String,
            default: undefined,
        },
        variant: {
            type: String,
            default: null,
        },
        autofocus: {
            type: Boolean,
        },
        hint: {
            type: String,
            default: undefined,
        },
        hideDetails: {
            type: [Boolean, String],
            default: false,
        },
        counter: {
            type: [Boolean, Number, String],
            default: undefined,
        },
        icon: undefined,
        currency: undefined,
        append: Boolean,
        prepend: Boolean,
        percent: Boolean,
        required: Boolean,
        disabled: Boolean,
        readonly: Boolean,
        min: Number,
        max: Number,
    },

    data() {
        return {
            internalValue: null,
        }
    },

    computed: {
        appendIcon() {
            return this.append ? this.icon : undefined
        },
        prependIcon() {
            return this.prepend ? this.icon : undefined
        },
    },

    mounted() {
        if (this.percent) this.$set(this, 'internalValue', this.value)
    },

    methods: {
        inputHandler(val) {
            if (this.percent) this.$set(this, 'internalValue', val)
            this.$emit(
                'input',
                this.type === 'number' && val !== '' ? Number(val) : val
            )
        },

        blurHandler(evt) {
            let val = evt.target.value
            if (this.percent) {
                if (val && /^\d+(\.\d+)?%$/.test(val)) {
                    this.$set(this, 'internalValue', val)
                } else {
                    if (parseFloat(val) && parseFloat(val) == 'NaN') {
                        this.$emit('input', null)
                        this.$set(this, 'internalValue', null)
                    }
                }
            }
            this.$emit('blur', evt)
        },
    },
}
</script>
