import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'

class MealOptions extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/meal-options'
    }

    moveToPosition(id, position) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'move-to-position'), {
                position,
            })
        ).then(this.refreshSelectapiStore.bind(this))
    }

    inactivateForToday(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'inactivate-for-today'))
        ).then(this.refreshSelectapiStore.bind(this))
    }

    inactivate(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), { isActive: false })
        ).then(this.refreshSelectapiStore.bind(this))
    }

    activate(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'activate'))
        ).then(this.refreshSelectapiStore.bind(this))
    }
}

export default new MealOptions()
