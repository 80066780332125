export default {
    props: {
        label: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
    },

    computed: {
        labelText() {
            return this.$t(this.label)
        },
    },
}
