<template lang="pug">
    span(class="ordit-logo" :style="{ width }")
        img(src="/img/ordit_logo.svg" alt="Ordit logo")
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '100px',
        },
    },
}
</script>

<style lang="scss">
.ordit-logo {
    display: inline-block;

    img {
        max-width: 100%;
        height: auto;
        display: block;
    }
}
</style>
