import routeInfoType from '@/store/type/routeInfoType'
import Store from '@/store/store'
import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import apiClient from '@/api/ApiClient'
import ResponseHandler from '@/api/response/ResponseHandler'
import SelectApiClientType from '@/api/SelectApiClientType'

class UsersApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/users'
    }

    get(id) {
        return super.get(id).then((data) => {
            Store.commit(routeInfoType.mutations.SET_USER, {
                id,
                value: {
                    ...data,
                },
            })
            return data
        })
    }

    update(entity, id) {
        return super.update(entity, id).then((entity) => {
            Store.commit(routeInfoType.mutations.SET_USER, {
                id: entity.id,
                value: {
                    ...entity,
                },
            })
            return entity
        })
    }

    getRelatedSelectApi() {
        return SelectApiClientType.USER
    }

    activate(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), { enabled: true })
        )
    }

    deactivate(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), { enabled: false })
        )
    }
}

export default new UsersApiClient()
