<template>
    <vuetify-google-autocomplete
        :id="`map-autocomplete-${id}`"
        ref="autocomplete"
        :value="value"
        :label="labelText"
        :error-messages="errorMessages"
        append-icon="search"
        :disabled="disabled"
        @input="inputEvent"
        @placechanged="placeChangedEvent"
        @no-results-found="noResultEvent"
    />
</template>

<script>
import ErrorMsgInputMixin from '../InputMixins/ErrorMsgInputMixin'
import GoogleMapAddressMixin from '../InputMixins/GoogleMapAddressMixin'

export default {
    mixins: [ErrorMsgInputMixin, GoogleMapAddressMixin],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            id: null,
        }
    },

    mounted() {
        this.id = this._uid
        this.$gmapApiPromiseLazy().then(() => {
            this.$geocoder = new google.maps.Geocoder()
        })
    },
    methods: {
        inputEvent(value) {
            if (!value) {
                this.clear()
            }
        },

        placeChangedEvent(data, place) {
            const address = this.convertAddress(this.parseAddress(place))
            this.updateAddressFields(address)
        },

        noResultEvent(result) {
            // evade noResult on value change
            if (typeof result === 'object') {
                if (!result.name) {
                    this.clear()
                    return
                }

                this.$geocoder.geocode(
                    { address: result.name },
                    (results, status) => {
                        if (status === 'OK' && results.length > 0) {
                            const address = this.convertAddress(
                                this.parseAddress(results[0])
                            )
                            this.updateAddressFields(address)
                            this.$refs.autocomplete.update(address.full_address)
                        }
                    }
                )
            }
        },

        clear() {
            const emptyAddress = {
                full_address: null,
                country: null,
                longitude: null,
                latitude: null,
                town: null,
                zip_code: null,
                street: null,
                house_number: null,
                country_code: null,
                place_id: null,
            }

            this.updateAddressFields(emptyAddress)
        },
    },
}
</script>
