<template>
    <modal-dialog
        v-model="dialog"
        :title="$t('labels.pedalme_header')"
        cancel-button
        :max-width="600"
        :show-overlay="processing"
        :ok-disabled="!sendable"
        :ok-text="$t('actions.send')"
        @cancel="onCancel"
        @ok="onApprove"
    >
        <div v-if="items.length" class="pedalme-list">
            <date-picker-input
                v-model="pickupTime"
                label="labels.pedalme_pickup_time"
                name="pickupTime"
                class="w-100"
            ></date-picker-input>
            <v-list>
                <draggable
                    :value="selectedItems"
                    handle=".handle"
                    @change="onDragEnd"
                >
                    <v-list-item
                        v-for="item in selectedItems"
                        :key="item['@id']"
                        class="show-item-on-hover"
                    >
                        <v-list-item-icon>
                            <v-icon class="handle c-grab">
                                drag_indicator
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="d-flex">
                                <v-switch
                                    v-model="selection"
                                    :value="item.id"
                                    class="mt-0"
                                    :hide-details="true"
                                ></v-switch>
                                <div>
                                    {{ getLabel(item) }}
                                    <div
                                        class="g-73--text pedalme-list-subtext"
                                    >
                                        {{ getHint(item) }}
                                    </div>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </draggable>
            </v-list>
            <v-list>
                <v-list-item
                    v-for="item in deselectedItems"
                    :key="item['@id']"
                    class="show-item-on-hover"
                >
                    <v-list-item-icon>
                        <v-icon></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="d-flex">
                            <v-switch
                                v-model="selection"
                                :value="item.id"
                                class="mt-0"
                                :hide-details="true"
                            ></v-switch>
                            <div>
                                {{ getLabel(item) }}
                                <div class="g-73--text pedalme-list-subtext">
                                    {{ getHint(item) }}
                                </div>
                            </div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>
        <div v-else>{{ $t('texts.no_result') }}</div>
    </modal-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import DeliveryApi from '@/api/RestApi/DeliveriesApiClient'
import SAT from '@/store/type/selectapiType'
import Draggable from 'vuedraggable'
import ModalDialog from '@/components/dialog/ModalDialog'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'

export default {
    components: {
        ModalDialog,
        Draggable,
        DatePickerInput,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        deliveryJob: {
            type: Object,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            dialog: false,
            processing: false,
            selection: [],
            items: [],
            pickupTime: null,
        }
    },
    computed: {
        ...mapGetters({
            restNames: `${SAT.getModuleName(SAT.clientTypes.RESTAURANT)}/${
                SAT.getters.VALUES
            }`,
        }),
        restaurantNames() {
            const restaurantNames = this.restNames({})
            const names = {}
            for (let el of restaurantNames) {
                names[el.id] = el.name
            }

            return names
        },
        selectedItems() {
            const result = []
            this.selection.forEach((id) => {
                for (let item of this.items) {
                    if (item.id === id) {
                        result.push(item)
                        break
                    }
                }
            })
            return result
        },
        deselectedItems() {
            return this.items.filter((el) => !this.selection.includes(el.id))
        },
        sendable() {
            return (
                !this.processing && this.selection.length > 0 && this.pickupTime
            )
        },
    },
    watch: {
        value() {
            this.dialog = this.value
            if (this.value) {
                this.pickupTime = null
                this.selection = []
                this.processing = false

                // order list job without dropoff fix...
                if (this.deliveryJob && !this.deliveryJob?.dropoffAddress) {
                    this.processing = true
                    DeliveryApi.getDeliveryJob(this.deliveryJob?.id)
                        .then((job) => {
                            DeliveryApi.getPedalme(
                                job.dropoffAddress.latitude,
                                job.dropoffAddress.longitude
                            )
                                .then((res) => {
                                    this.items = res['hydra:member'] || []
                                })
                                .finally(() => {
                                    this.processing = false
                                })
                        })
                        .catch(() => {
                            this.processing = false
                            this.value = false
                        })
                }
            }
        },
        deliveryJob(newJob, oldJob) {
            if (
                newJob &&
                (newJob?.dropoffAddress?.latitude !==
                    oldJob?.dropoffAddress?.latitude ||
                    newJob?.dropoffAddress?.longitude !==
                        oldJob?.dropoffAddress?.longitude)
            ) {
                this.processing = true
                DeliveryApi.getPedalme(
                    newJob.dropoffAddress.latitude,
                    newJob.dropoffAddress.longitude
                ).then((res) => {
                    this.items = res['hydra:member'] || []
                    this.processing = false
                })
            }
        },
        selection() {
            let earliestPickup = null
            this.selectedItems.forEach((item) => {
                if (!earliestPickup || earliestPickup > item.pickupTime)
                    earliestPickup = item.pickupTime
            })
            this.pickupTime = earliestPickup
        },
    },
    mounted() {
        this.$store.dispatch(
            SAT.getActionName(SAT.clientTypes.RESTAURANT, SAT.actions.LOAD)
        )
    },
    methods: {
        getLabel(item) {
            if (item.preCreatedFor?.restaurant_id) {
                return this.restaurantNames[item.preCreatedFor.restaurant_id]
            } else if (item.orders?.[0]?.restaurantName) {
                return item.orders?.[0]?.restaurantName
            }
        },
        getHint(item) {
            const date = this.$options.filters.toDate(
                item.pickupTime,
                'YYYY-MM-DD HH:mm'
            )
            let uid =
                item.preCreatedFor?.canteen_uid || item.orders?.[0]?.identifier
            return `${uid} ⎯ ${date}`
        },
        onApprove() {
            this.processing = true
            this.$emit('onApprove', this.pickupTime, this.selection)
        },
        onCancel() {
            this.$emit('input', false)
            this.$emit('onCancel')
        },
        onDragEnd({ moved }) {
            this.selection.splice(moved.oldIndex, 1)
            this.selection.splice(moved.newIndex, 0, moved.element.id)
        },
    },
}
</script>

<style lang="scss">
.pedalme-list {
    max-height: 70vh;
    overflow-y: auto;
}
.pedalme-list-subtext {
    font-size: 0.75rem;
}
</style>
