import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import Notification from '@/services/Notification/Notification'
import RestApiCollection from '@/api/RestApiCollection'
import MapperCollection from '@/services/mapper/MapperCollection'

export default {
    data() {
        return {
            api: {},
            mapper: {},
            editedItem: null,
            editedOriginal: null,
            isValid: false,
        }
    },

    methods: {
        edit(item, propKey) {
            if (this.editedItem) {
                this.cancel(this.editedItem, propKey).then((cancelled) => {
                    if (cancelled) {
                        this.editStart(item)
                    }
                })
            } else {
                this.editStart(item)
            }
        },
        editStart(item) {
            this.isValid = true
            this.editedOriginal = cloneDeep(item)
            this.editedItem = item
        },
        submit(item) {
            this.$validator.validate().then((isValid) => {
                if (isValid) {
                    this.busy = true
                    const entity = MapperCollection.get(this.mapper).toEntity(
                        this.editedItem
                    )

                    RestApiCollection.get(this.api)
                        .update(entity, entity.id)
                        .then(() => {
                            Notification.entityUpdated(
                                this.entity,
                                this.getTitle(item)
                            )
                            this.editedItem = null
                        })
                        .catch(() => {
                            Notification.formError()
                        })
                        .finally(() => {
                            this.busy = false
                        })
                }
            })
        },

        cancel(item, propKey) {
            return this.$validator.validate().then((isValid) => {
                let changed = !isEqual(item, this.editedItem)
                let cancelled =
                    !changed ||
                    !isValid ||
                    confirm(this.$t('texts.unsaved_sure'))
                if (cancelled) {
                    this.editedItem = null
                    item[propKey] = this.editedOriginal[propKey]
                    this.editedOriginal = null
                    this.$validator.reset()
                }

                return cancelled
            })
        },

        getTitle(item) {
            return item
        },

        setItem(val, item, propKey) {
            this.edit(item, propKey)
            this.$set(item, propKey, val)
        },
    },
}
