import cloneDeep from 'lodash/cloneDeep'
import dayjs from '@/plugins/date'

export default {
    toEntity(entity) {
        for (let relation of entity.relatedRestaurants) {
            if (typeof relation['@id'] === 'number') {
                delete relation['@id']
            }

            if (entity.originalRelations) {
                for (let origRelation of entity.originalRelations) {
                    if (
                        origRelation.restaurant === relation.restaurant &&
                        origRelation['@id'] !== relation['@id']
                    ) {
                        relation['@id'] = origRelation['@id']
                    }
                }
            }

            relation.rules.forEach((rule) => {
                rule.cutoffTime = dayjs(rule.cutoffTime).format(
                    'YYYY-MM-DD HH:mm:ss'
                )
            })
        }

        delete entity.originalRelations
        delete entity.originalDeliveryTime
        delete entity.originalEarliestCutoffTime
        delete entity.earliestCutoffTime

        if (!entity.recurrenceEnds) entity.recurrenceEnds = null
    },

    fromEntity(entity, values) {
        values.originalRelations = cloneDeep(entity.relatedRestaurants)
        values.originalDeliveryTime = values.deliveryTime
        values.earliestCutoffTime = values.cutoffTime

        values.relatedRestaurants.forEach((related) => {
            if (related.cutoffTimeOverride === null) {
                related.cutoffTimeOverride = values.cutoffTime
            } else if (related.cutoffTimeOverride < values.earliestCutoffTime) {
                values.earliestCutoffTime = related.cutoffTimeOverride
            }
        })

        values.originalEarliestCutoffTime = values.earliestCutoffTime

        if (values.recurrenceEnds)
            values.recurrenceEnds = values.recurrenceEnds.substr(0, 10)
    },
}
