<template>
    <div>
        <v-row class="table-filter-row align-center">
            <v-col v-if="!hideSearchBox" class="input-col">
                <search-box
                    v-model="tableFilters"
                    :label="$t('labels.search')"
                ></search-box>
            </v-col>
            <v-col v-if="!hideFilterBox && advancedFilters.length" cols="auto">
                <v-chip
                    :input-value="showFilters"
                    :class="[
                        'g-73--text',
                        { 'border-g-e8 bg-white': !showFilters },
                    ]"
                    @click="showFilters = !showFilters"
                >
                    <v-icon small>tune</v-icon>
                </v-chip>
            </v-col>
            <v-col v-if="!hideFilterBox" cols="auto">
                <v-row>
                    <v-col
                        v-for="(filter, index) in quickFilters"
                        :key="filter.key + filter.type + index"
                        cols="auto"
                        align-self="center"
                    >
                        <select-filter
                            v-if="filter.inputType === 'select'"
                            v-model="tableFilters"
                            :use-id-field="filter.useIdField"
                            :use-name-field="filter.useNameField"
                            :field="filter"
                        ></select-filter>
                        <search-box
                            v-else-if="filter.inputType === 'search'"
                            v-model="tableFilters"
                            :field="filter"
                        ></search-box>
                        <chip-filter
                            v-else
                            v-model="tableFilters"
                            :field="filter"
                        ></chip-filter>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-expand-transition>
            <v-row v-show="showFilters">
                <v-col class="py-0">
                    <v-row>
                        <v-col
                            v-for="(filter, index) in advancedFilters"
                            :key="filter.key + filter.type + index"
                            cols="12"
                            :sm="filter.wider ? 12 : 6"
                            :md="filter.wider ? 8 : 4"
                            :lg="filter.wider ? 6 : 3"
                        >
                            <select-filter
                                v-if="filter.inputType === 'select'"
                                v-model="tableFilters"
                                :use-id-field="filter.useIdField"
                                :use-name-field="filter.useNameField"
                                :field="filter"
                            ></select-filter>
                            <search-box
                                v-if="filter.inputType === 'search'"
                                v-model="tableFilters"
                                :field="filter"
                            ></search-box>
                            <date-filter
                                v-if="filter.inputType === 'date'"
                                v-model="tableFilters"
                                :field="filter"
                            ></date-filter>
                            <location-filter
                                v-if="filter.inputType === 'location'"
                                v-model="tableFilters"
                                :field="filter"
                            ></location-filter>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-expand-transition>
    </div>
</template>

<script>
import SearchBox from './SearchBox'
import ChipFilter from './ChipFilter'
import SelectFilter from './SelectFilter'
import DateFilter from './DateFilter'
import LocationFilter from './LocationFilter'
import isEqual from 'lodash/isEqual'

export default {
    components: {
        SearchBox,
        ChipFilter,
        SelectFilter,
        DateFilter,
        LocationFilter,
    },

    props: {
        value: {
            type: Object,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        busy: {
            type: Boolean,
        },
        hideFilterBox: {
            type: Boolean,
        },
        hideSearchBox: {
            type: Boolean,
        },
        listFilters: {
            type: Array,
            default: () => {
                return []
            },
        },
    },

    data() {
        return {
            tableFilters: {},
            showFilters: false,
            checked: false,
        }
    },

    computed: {
        filterCount() {
            return Object.keys(this.value).length
        },

        quickFilters() {
            return this.listFilters.filter((el) => {
                return el.inputType == 'checkbox' || el.quick
            })
        },

        advancedFilters() {
            return this.listFilters.filter((el) => {
                return el.inputType != 'checkbox' && !el.quick
            })
        },
    },

    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                if (!isEqual(value, this.tableFilters)) {
                    this.tableFilters = value
                }
            },
        },
        tableFilters: {
            deep: true,
            handler(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
.table-filter-row {
    .col {
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-col {
        max-width: 300px;
    }
}
</style>
