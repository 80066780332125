<script>
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'

import Vue from 'vue'
import VuePellEditor from 'vue-pell-editor'

Vue.use(VuePellEditor)

export default {
    mixins: [ErrorMsgInputMixin],
    inject: {
        $validator: '$validator',
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        editorOptions: {
            type: Array,
            default: () => ['bold', 'olist', 'ulist', 'italic'],
        },
    },

    data() {
        return {
            internalValue: null,
            id: null,
        }
    },

    watch: {
        value: {
            handler() {
                this.internalValue = this.value
            },
            immediate: true,
        },
    },

    mounted() {
        this.id = this._uid
    },

    methods: {
        inputHandler(val) {
            this.internalValue = val
            this.$emit('input', val)
        },
    },
}
</script>

<template>
    <div class="ordit-wyswyg">
        <label v-if="label" :for="'wyswyg' + id">
            {{ labelText }}
        </label>
        <vue-pell-editor
            :id="'wyswyg' + id"
            :value="value"
            class="ordit-wyswyg"
            :actions="editorOptions"
            :content="internalValue"
            :placeholder="placeholder"
            :style-with-css="false"
            default-paragraph-separator="p"
            @input="inputHandler"
        />
    </div>
</template>

<style lang="scss">
.ordit-wyswyg {
    margin-bottom: 30px;
    .pell-content {
        color: rgba(0, 0, 0, 0.87);
        font-size: 16px;
        height: auto;
        border: thin solid rgba(0, 0, 0, 0.42);
        border-width: thin 0 thin 0;
        line-height: 1.2;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
}
</style>
