<template>
    <div style="position: relative">
        <slot></slot>
        <v-overlay :value="show" :color="color" absolute>
            <v-progress-circular
                v-if="progress"
                size="80"
                width="6"
                color="amber"
                indeterminate
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
export default {
    props: {
        show: Boolean,
        progress: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: '#FFFFFF',
        },
    },
}
</script>
