<template>
    <div v-if="selectedIds.length > 0" class="d-inline-block">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn
                    :color="color"
                    dark
                    depressed
                    v-on="on"
                    @click.stop="execute"
                >
                    <v-icon dark>{{ icon }}</v-icon>
                    <small>{{ selectedIds.length }}</small>
                </v-btn>
            </template>
            <span>{{ tooltip }}</span>
        </v-tooltip>

        <confirm-modal
            v-if="!strongConfirm"
            v-model="dialog"
            :title="confirmTitle ? confirmTitle : $t('texts.are_you_sure')"
            :text="confirmText"
            :approve-color="approveColor"
            :approve-text="approveText"
            :cancel-text="cancelText"
            @onApprove="confirm"
        ></confirm-modal>
        <strong-confirm-modal
            v-else
            v-model="dialog"
            :title="confirmTitle ? confirmTitle : $t('texts.are_you_sure')"
            :text="confirmText"
            :approve-color="approveColor"
            :approve-text="approveText"
            :cancel-text="cancelText"
            @onApprove="confirm"
        ></strong-confirm-modal>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import StrongConfirmModal from '@/components/confirm/StrongConfirmModal'

export default {
    components: { ConfirmModal, StrongConfirmModal },
    props: {
        selectedIds: {
            type: Array,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        confirmDialog: {
            type: Boolean,
            required: false,
        },
        confirmTitle: {
            type: String,
            default: null,
        },
        confirmText: {
            type: String,
            default: null,
        },
        approveText: {
            type: String,
            default: null,
        },
        approveColor: {
            type: String,
            default: null,
        },
        cancelText: {
            type: String,
            default: null,
        },
        strongConfirm: Boolean,
    },
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        execute() {
            if (this.confirmDialog) {
                this.dialog = true
            } else {
                this.$emit('execute', cloneDeep(this.selectedIds))
            }
        },
        confirm() {
            this.dialog = false
            this.$emit('execute', cloneDeep(this.selectedIds))
        },
    },
}
</script>
