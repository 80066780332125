import BaseInputMixin from './BaseInputMixin'

export default {
    mixins: [BaseInputMixin],
    props: {
        errorMessages: {
            type: [Array, Object],
            default: () => [],
        },
    },
}
