<template lang="pug">
v-dialog(
    v-if="isRestaurantAdmin"
    :value="!notificationsOn && loaded && !notificationOffByUser" 
    :width="width"
    no-click-animation
    persistent eager)
    v-card       
        v-card-title.justify-center {{ $t('texts.get_alerts') }}
        v-card-text.text-body-1.text-center {{ $t('texts.get_alerts_desc') }}
        v-card-text.text-body-1.error--text.d-grid.grid-rows-0fr.transition-grid-row(:class="{'grid-rows-1fr': notificationBlocked}") 
            div.overflow-hidden 
                p.text-center {{ $t('texts.notification_blocked_in_browser') }}
                v-img(
                    v-for="(image, index) in helperImage" 
                    :src="image" 
                    :key="`helperImage-${index}`"
                    contain)
        v-card-actions.flex-column
            v-btn.mb-4(
                color="primary" 
                :loading="loading" 
                :disabled="notificationBlocked"
                @click="turnOnNotification"
                block) {{$t('labels.turn_on_notifications')}}
            v-btn(
                color="secondary"
                class="black--text"
                @click="confirmBlockingNotifications = true"
                plain block) {{$t('labels.not_managing_orders')}}

    confirm-modal(
        v-model="confirmBlockingNotifications"  
        @onApprove="turnOffNotification"
        @onCancel="confirmBlockingNotifications = false"
        :text="$t('texts.notification_confirm_alert') "
        title-center text-center)
</template>

<script>
import { EventBus } from '@/plugins/events'
import { mapGetters } from 'vuex'
import orderType from '@/store/type/orderType'
import authType from '@/store/type/authType'
import restaurantType from '@/store/type/restaurantType'
import NotificationsMixin from '@/components/mixins/NotificationsMixin'
import ConfirmModal from '@/components/confirm/ConfirmModal'

const NEW_ORDER = 'new_order'

export default {
    components: { ConfirmModal },
    mixins: [NotificationsMixin],
    data() {
        return {
            loaded: false,
            width: undefined,
            confirmBlockingNotifications: false,
        }
    },
    computed: {
        ...mapGetters({
            restaurant: restaurantType.getters.ACTIVE_RESTAURANT,
            isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
            newOrdersList: orderType.getters.NEW_ORDERS_LIST,
        }),
    },
    mounted() {
        this.getImage()
        this.width = getComputedStyle(document.body).getPropertyValue(
            '--restaurant-admin-modal-max-width'
        )
        if (!this.notificationOffByUser && this.isRestaurantAdmin) {
            this.$store
                .dispatch(
                    authType.actions.SET_NOTIFICATION_PERMISSION,
                    Notification.permission === 'granted'
                )
                .then(() => {
                    if (this.notificationPermissionGranted) {
                        this.$store
                            .dispatch(authType.actions.REGISTER_NOTIFICATIONS)
                            .then(() => {
                                this.onIncomingOrder()
                            })
                            .catch((e) => {
                                // eslint-disable-next-line no-console
                                console.error(e)
                            })
                    }
                })
            setTimeout(() => {
                this.loaded = true
            }, 1000)
            EventBus.$on('notificationsOn', this.onIncomingOrder)
        } else {
            this.loaded = true
        }
    },
    beforeDestroy() {
        EventBus.$off('notificationsOn', this.onIncomingOrder)
    },
    methods: {
        onIncomingOrder() {
            navigator.serviceWorker.onmessage = ({ data: { data } }) => {
                if (data.type === NEW_ORDER)
                    this.$store.dispatch(
                        orderType.actions.GET_ORDERS_EVENT,
                        JSON.parse(data.order_ids)
                    )
            }
        },
        closeNotificationAlert() {},
    },
}
</script>
