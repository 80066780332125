import apiClient from '@/api/ApiClient'
import Notification from '@/services/Notification/Notification'

class AuthApiClient {
    auth(email, password) {
        return apiClient.post('/auth', { email, password }).catch((error) => {
            if (error.response.status === 500) {
                Notification.requestError(error)
            }

            return Promise.reject(error)
        })
    }

    refresh(refreshToken) {
        return apiClient
            .post('/auth/refresh', { refresh_token: refreshToken })
            .catch((response) => {
                Notification.requestError(response)

                return Promise.reject(response)
            })
    }

    passwordResetRequest(email, lang) {
        return apiClient
            .post('/reset_password/request?lang=' + lang, { email })
            .catch((error) => {
                if (error.response.status === 500) {
                    Notification.requestError(error)
                }

                return Promise.reject(error)
            })
    }

    passwordReset(token, password) {
        return apiClient
            .post('/reset_password', { token, password })
            .catch((error) => {
                if (error.response.status === 500) {
                    Notification.requestError(error)
                }

                return Promise.reject(error)
            })
    }
}

export default new AuthApiClient()
