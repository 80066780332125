import cloneDeep from 'lodash/cloneDeep'

class TagFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)
        if (entity['image'] && entity['image']['@id']) {
            entity['image'] = entity['image']['@id']
        } else {
            entity['image'] = null
        }
        return entity
    }

    fromEntity(entity) {
        const formData = cloneDeep(entity)

        formData['tagGroup'] = formData['tagGroup']['@id']
        // translation obj
        if (
            !formData['translations'] ||
            Array.isArray(formData['translations'])
        ) {
            formData['translations'] = {
                en: {
                    name: '',
                    searchKeywords: '',
                },
                hu: {
                    name: '',
                    searchKeywords: '',
                },
            }
        }
        return formData
    }
}

export default new TagFormMapper()
