export default {
    getters: {
        MODEL: 'model',
        BUSY: 'busy',
        HAS_FORM_ERRORS: 'hasFormErrors',
        FORM_ERRORS: 'formErrors',
        SAVED_DATA: 'savedData',
    },
    mutations: {
        SET_MODEL: 'setModel',
        SET_BUSY: 'setBusy',
        HAS_ERROR: 'hasError',
        ADD_FORM_ERROR: 'addFormError',
        RESET_FORM_ERRORS: 'resetFormErrors',
        SET_BACKUP: 'setBackup',
        SET_VALIDATOR: 'setValidator',
    },
    actions: {
        SAVE: 'save',
        LOAD: 'load',
        DELETE: 'delete',
    },
}
