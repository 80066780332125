<template lang="pug">
v-dialog(:value="openConfirmation" content-class="confirm-order" :max-width="width" overlay-color="black" :overlay-opacity=".5" persistent light)
    v-card.px-3(:loading="loadingConfirm" @change="updateList" height="100%")
        v-carousel(v-model="active" ref="carousel" height="100%" :show-arrows="false" hide-delimiters light hide-delimiter-background)
            v-carousel-item(v-for="(order, index) in ordersNeedConfirmation" :key="`incomingOrder-${index}`")
                v-container.pt-3.mh-100.h-100.d-flex.flex-column.overflow-y-auto(fluid)
                    active-order(:order="order" confirmToPrepare)
        confirm-button(
            :list="ordersNeedConfirmation"
            :index="active"
            :disabled="loadingConfirm || loadingList")
            .total(slot="total") {{active + 1}}/{{ordersNeedConfirmation.length}}

    v-fade-transition
        .navigation-wrapper(v-if="needNavigation")
            v-btn(@click="prev" color="g-f7" icon plain)
                v-icon arrow_back
            v-btn(@click="next" color="g-f7" icon plain)
                v-icon arrow_forward

</template>

<script>
import ActiveOrder from '@/components/pages/restaurantAdmin/ActiveOrder'
import ConfirmButton from '@/components/pages/restaurantAdmin/confirm/ConfirmButton'
import ConfirmMixin from '@/components/mixins/ConfirmMixin'
import orderType from '@/store/type/orderType'
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'

export default {
    components: { ConfirmButton, ActiveOrder },
    mixins: [ConfirmMixin],
    computed: {
        ...mapGetters({
            now: configType.getters.NOW,
        }),
        ordersNeedConfirmation() {
            return (
                this.approvedOrdersList?.filter(
                    (order) =>
                        !order.preparationConfirmed &&
                        Boolean(order.userProfile) && //this is not a CC order (in CC orders the userProfile is null)
                        this.now &&
                        this.$date(this.now)
                            .add(0.5, 'minute')
                            .isSameOrAfter(order.startPrepareAt)
                ) || []
            )
        },
        openConfirmation() {
            return !this.approveOpen && this.ordersNeedConfirmation.length > 0
        },
        needNavigation() {
            return this.ordersNeedConfirmation.length > 1
        },
    },
    watch: {
        openConfirmation: {
            immediate: true,
            handler(openConfirmation) {
                if (!this.approveOpen) {
                    this.$store.commit(
                        orderType.mutations.SET_CONFIRM_STATE,
                        openConfirmation
                    )
                }
            },
        },
    },

    methods: {
        next() {
            this.$refs.carousel.next()
        },
        prev() {
            this.$refs.carousel.prev()
        },
        updateList() {
            if (!this.ordersNeedConfirmation[this.active].meals)
                this.$store.dispatch(orderType.actions.GET_ORDER, [
                    this.ordersNeedConfirmation[this.active].id,
                    orderType.mutations.UPDATE_ACTIVE_ORDERS_LIST,
                ])
        },
    },
}
</script>
