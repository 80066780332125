export default function (router) {
    let propagate = true

    router.afterEach((to) => {
        if (propagate) {
            window.parent.postMessage(
                { event: 'URL_UPDATE', path: encodeURIComponent(to.fullPath) },
                '*'
            )
        }

        propagate = true
    })

    window.addEventListener('message', (event) => {
        if (event.data.event === 'HISTORY_BACK') {
            propagate = false
            router.go(-1)
        }

        if (event.data.event === 'HISTORY_FORWARD') {
            propagate = false
            router.go(1)
        }
    })
}
