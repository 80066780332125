<template>
    <div>
        <div class="pb-2 d-flex justify-space-between">
            <slot></slot>
            <v-btn color="info" small @click="addRule">
                <v-icon left>add</v-icon> {{ $t('actions.add') }}
            </v-btn>
        </div>
        <v-row
            v-for="(rule, index) in form"
            :key="`${rule.cutoffTime}-${index}`"
        >
            <v-col cols="8" class="pb-0 pt-2">
                <relative-date-time-picker-input
                    v-model="rule.cutoffTime"
                    label="labels.cc_cutoff.date"
                    :name="`cutoffTime${validationPrefix}[${index}]`"
                    :reference="referenceDate"
                    :error-messages="
                        errors.collect(
                            `cutoffTime${validationPrefix}[${index}]`
                        )
                    "
                    :data-vv-as="$t('labels.cc_cutoff.date')"
                    :max="latestDate(index)"
                    :show-actual-date="!hideDate"
                    :relative-error="hideDate"
                    invalid-date
                ></relative-date-time-picker-input>
            </v-col>
            <v-col cols="3" class="pb-0 pt-2">
                <text-input
                    v-model="rule.limit"
                    v-validate="'required|integer|min_value:1|max_value:999'"
                    type="number"
                    label="labels.canteen_order_limit"
                    :min="1"
                    :max="999"
                    hide-details="auto"
                    icon="people"
                    prepend
                    :name="`limit${validationPrefix}[${index}]`"
                    :error-messages="
                        errors.collect(`limit${validationPrefix}[${index}]`)
                    "
                    :data-vv-as="$t('labels.canteen_order_limit')"
                >
                </text-input>
            </v-col>
            <v-col cols="1" class="pb-0 pt-3">
                <v-btn icon color="error" @click="removeRule(index)">
                    <v-icon>delete</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { DATE_TIME_FORMAT } from '@/const/DateTimeFormats'
import FormPartMixin from '@/components/mixins/FormPartMixin'
import TextInput from '@/components/form/Inputs/TextInput'
import RelativeDateTimePickerInput from '@/components/form/Inputs/RelativeDateTimePickerInput'

export const defaultCutoff = '2023-08-30 10:00'

export default {
    components: {
        TextInput,
        RelativeDateTimePickerInput,
    },
    mixins: [FormPartMixin],
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        newRule: {
            type: Object,
            default: () => ({
                cutoffTime: defaultCutoff,
                limit: 999,
            }),
        },
        referenceDate: {
            type: String,
            default: defaultCutoff,
        },
        validationPrefix: {
            type: [String, Number],
            default: 1,
        },
        hideDate: Boolean,
    },
    data() {
        return {
            form: [],
        }
    },
    watch: {
        'newRule.cutoffTime'(newVal) {
            if (this.form.length > 0) {
                this.form[this.form.length - 1].cutoffTime = newVal
            }
        },
    },
    methods: {
        latestDate(index) {
            if (index < this.form.length - 1) {
                return this.form[index + 1].cutoffTime
            } else {
                return this.referenceDate
            }
        },
        addRule() {
            const newRule = cloneDeep(this.newRule)
            let earliest = this.form[0]?.cutoffTime
            if (earliest) {
                newRule.cutoffTime = this.$date(earliest)
                    .subtract(1, 'h')
                    .format(DATE_TIME_FORMAT)
            }

            this.form.unshift(newRule)
        },
        removeRule(index) {
            if (index === this.form.length - 1 && index > 0) {
                this.form[index - 1].cutoffTime = this.newRule.cutoffTime
            }
            this.form.splice(index, 1)
        },
    },
}
</script>
