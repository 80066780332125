import cloneDeep from 'lodash/cloneDeep'

class CompanyBalancesFormMapper {
    toEntity(values) {
        return cloneDeep(values)
    }

    fromEntity(entity) {
        return cloneDeep(entity)
    }
}

export default new CompanyBalancesFormMapper()
