<template>
    <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on }">
            <div class="align-top d-inline-block pt-1">
                <template v-for="(definition, field) in value">
                    <v-chip
                        v-if="findFieldDefinition(field)"
                        :key="field"
                        v-model="deleteList[field]"
                        class="mr-2"
                        close
                        outlined
                        small
                        color="brand"
                        @click="edit(field)"
                        @click:close="deleteFilter(field)"
                    >
                        {{ findFieldDefinition(field).label }}:
                        {{ definition.value }}
                    </v-chip>
                </template>
            </div>
            <v-btn icon large :color="filterCount ? 'brand' : ''" v-on="on">
                <v-icon>filter_alt</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                <span class="headline">{{ $t('actions.add_filter') }}</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                            v-model="selectedField"
                            hide-details="auto"
                            :items="tableFields"
                            item-value="key"
                            item-text="label"
                            :label="$t('labels.field')"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                            v-model="selectedType"
                            hide-details="auto"
                            :items="typesForSelectedField"
                            :label="$t('labels.type')"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            v-model="selectedFieldValue"
                            hide-details="auto"
                            :label="$t('labels.value')"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="brandalt" text @click="dialog = false">{{
                    $t('actions.close')
                }}</v-btn>
                <v-btn color="primary" dark @click="addFilter">{{
                    $t('actions.add')
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'

const typeMap = {
    string: ['partial', 'exact', 'start', 'end'],
    number: ['gt', 'lt', 'gte', 'lte'],
    date: ['after', 'before'],
    onlyEquality: ['exact'],
}

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        addIdFilter: {
            type: Boolean,
        },
    },
    data() {
        return {
            dialog: false,
            tableFields: [],
            typesForSelectedField: [],
            selectedField: null,
            selectedType: null,
            selectedFieldValue: null,
            deleteList: {},
        }
    },
    computed: {
        filterCount() {
            return Object.keys(this.value).length
        },
    },
    watch: {
        fields: {
            immediate: true,
            handler(fields) {
                this.tableFields = []

                fields.forEach((field) => {
                    if (!Object.prototype.hasOwnProperty.call(field, 'type')) {
                        return
                    }

                    this.tableFields.push({
                        key: field.value ? field.value : field.key,
                        label: field.text ? field.text : field.label,
                        type: field.type,
                    })
                })

                if (this.addIdFilter) {
                    this.tableFields.unshift({
                        key: 'id',
                        label: 'Id',
                        type: 'onlyEquality',
                    })
                }
            },
        },
        selectedField(selectedField) {
            if (!selectedField) {
                this.typesForSelectedField = []
                this.selectedFieldValue = null
                this.selectedType = null

                return
            }

            const fieldDefinition = this.findFieldDefinition(selectedField)

            this.typesForSelectedField = typeMap[fieldDefinition.type]
            this.selectedType = this.typesForSelectedField[0]
        },
        dialog(dialog) {
            if (dialog === false) {
                this.selectedField = null
            }
        },
        deleteList: {
            deep: true,
            handler() {
                let found = false

                for (const property in this.deleteList) {
                    if (
                        !Object.prototype.hasOwnProperty.call(
                            this.deleteList,
                            property
                        ) ||
                        this.deleteList[property]
                    )
                        continue

                    this.deleteFilter(property)
                    found = true
                }

                if (found) {
                    this.deleteList = {}
                }
            },
        },
    },
    methods: {
        findFieldDefinition(fieldKey) {
            return find(this.tableFields, (item) => item.key === fieldKey)
        },
        addFilter() {
            if (this.selectedFieldValue === '') return

            const filters = cloneDeep(this.value)

            filters[this.selectedField] = {
                type: this.selectedType,
                value: this.selectedFieldValue,
            }

            this.dialog = false
            this.$emit('input', filters)
        },
        deleteFilter(field) {
            const filters = cloneDeep(this.value)

            delete filters[field]

            this.$emit('input', filters)
        },
        edit(field) {
            this.selectedField = cloneDeep(field)
            this.selectedType = cloneDeep(this.value[field].type)
            this.selectedFieldValue = cloneDeep(this.value[field].value)

            this.dialog = true
        },
    },
}
</script>
