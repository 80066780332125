import cloneDeep from 'lodash/cloneDeep'

class DefaultFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)

        return values
    }
}

export default new DefaultFormMapper()
