import cloneDeep from 'lodash/cloneDeep'

class BrandFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        if (entity.logo?.['@id']) {
            entity.logo = entity.logo['@id']
        }

        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)

        return values
    }
}

export default new BrandFormMapper()
