import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import apiClient from '@/api/ApiClient'
import ResponseHandler from '@/api/response/ResponseHandler'
import SelectApiClientType from '@/api/SelectApiClientType'
import restaurantType from '@/store/type/restaurantType'
import MapperCollection from '@/services/mapper/MapperCollection'
import MapperType from '@/services/mapper/MapperType'
import getAdminSource from '@/services/GetAdminSource'

class RestaurantsApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/restaurants'
    }
    get(id) {
        return ResponseHandler.handle(
            super.get(id),
            restaurantType.mutations.SET_ACTIVE_RESTAURANT,
            MapperCollection.get(MapperType.RESTAURANT_FORM)
        )
    }

    activate(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'activate'))
        )
    }

    deactivate(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), { isActive: false })
        )
    }

    inactivate(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), { isActive: false })
        )
    }

    inactivateForToday(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'inactivate-for-today'))
        )
    }

    restaurantAdminEnable(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), {
                isEnabledForRestaurantAdmin: true,
            })
        )
    }

    restaurantAdminDisable(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), {
                isEnabledForRestaurantAdmin: false,
            })
        )
    }

    generateAccessToken(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'access-token'))
        )
    }

    deleteAccessToken(id) {
        return ResponseHandler.handle(
            apiClient.delete(this.getItemIri(id, 'access-token'))
        )
    }

    getRelatedSelectApi() {
        return SelectApiClientType.RESTAURANT
    }

    exportCategories(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'export-categories'))
        )
    }

    exportMeals(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'export-meals'))
        )
    }

    exportOptions(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'export-option-groups'))
        )
    }

    import(id, formData, onProgress) {
        const config = { 'Content-Type': 'multipart/form-data' }
        if (onProgress) config.onUploadProgress = onProgress
        return ResponseHandler.handle(
            apiClient.post(this.getItemIri(id, 'import-menu'), formData, config)
        )
    }

    clone(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'clone'))
        )
    }

    getHighlights() {
        return ResponseHandler.handle(
            apiClient.get(this.getBaseUrl() + '/highlighting')
        )
    }

    updateHighlights(highlights) {
        return ResponseHandler.handle(
            apiClient.put(this.getBaseUrl() + '/highlighting', highlights)
        )
    }

    clearMenu(id) {
        return ResponseHandler.handle(
            apiClient.delete(this.getItemIri(id, 'clear-menu'))
        )
    }

    menuLastupdate(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'menu-lastupdate'))
        )
    }

    sendCanteenNotiConfirm(restaurantId, canteenIdentifier, cutoffTime) {
        return ResponseHandler.handle(
            apiClient.put(
                this.getItemIri(
                    restaurantId,
                    `confirm-canteen-notification/collected_orders_notification/${canteenIdentifier}`
                ),
                {
                    canteenIdentifier,
                    cutoffTime,
                },
                {
                    params: {
                        cutoffTime,
                        ...getAdminSource(),
                    },
                }
            )
        )
    }
}

export default new RestaurantsApiClient()
