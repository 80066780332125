import Vue from 'vue'
import { i18n } from '@/plugins/i18n'
import router from '@/router/router'
import orderType from './type/orderType'
import restaurantType from './type/restaurantType'
import Notifications from '@/services/Notification/Notification'
import OrdersApiClient from '@/api/RestApi/OrdersApiClient'
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'
import { getId } from '@/services/IdFromIri'
import {
    isCanteenOrder,
    isMultiCutoffSuborder,
    isMultiCutoffBaseOrder,
    isActiveOrder,
    subordersPerCutoff,
    nextUpcomingCutoffOrder,
} from '@/services/Orders/CanteenMultiCutoffRuleLogic'
import dayjs from '@/plugins/date'

let blockApproveOrders = false
let blockNewOrders = false

let loaderStates = Object.fromEntries(
    Object.values(orderType.loaders).map((loader) => [loader, false])
)

let loaderGetters = {}
Object.values(orderType.loaders).forEach((value) => {
    loaderGetters[value] = (state) => {
        return state[value]
    }
})

export default {
    state: {
        activeOrder: null,
        newOrdersList: [],
        ordersList: [],
        historyOrdersList: [],
        suborders: [],
        baseOrders: [],
        nextCutoff: {
            timer: null,
            date: null,
            orderId: null,
        },
        approveOpen: false,
        confirmOpen: false,
        ...loaderStates,
    },
    getters: {
        [orderType.getters.ACTIVE_ORDER](state) {
            return state.activeOrder
        },
        [orderType.getters.SUBORDERS](state) {
            return state.suborders
        },
        [orderType.getters.BASE_ORDERS](state) {
            return state.baseOrders
        },
        [orderType.getters.NEW_ORDERS_LIST](state) {
            return state.newOrdersList.concat(
                state.suborders.filter((item) => {
                    return isActiveOrder(item) && !item.isConfirmed
                })
            )
        },
        [orderType.getters.HISTORY_ORDERS_LIST](state) {
            return state.historyOrdersList
        },
        // approved but not confirmed orders
        [orderType.getters.APPROVED_ORDERS_LIST](state) {
            return state.ordersList
                .filter((order) => !order.preparationConfirmed)
                .concat(
                    state.suborders.filter(
                        (item) =>
                            isActiveOrder(item) &&
                            item.isConfirmed &&
                            !item.preparationConfirmed
                    )
                )
                .sort((a, b) => {
                    return Vue.prototype
                        .$date(a.startPrepareAt)
                        .isBefore(b.startPrepareAt)
                        ? -1
                        : 1
                })
        },
        // confirmed orders
        [orderType.getters.CONFIRMED_ORDERS_LIST](state) {
            return state.ordersList
                .filter((order) => order.preparationConfirmed)
                .concat(
                    state.suborders.filter(
                        (item) =>
                            isActiveOrder(item) &&
                            item.isConfirmed &&
                            item.preparationConfirmed
                    )
                )
                .sort((a, b) => {
                    return Vue.prototype
                        .$date(a.startPrepareAt)
                        .isBefore(b.startPrepareAt)
                        ? -1
                        : 1
                })
        },
        [orderType.getters.APPROVE_OPEN](state) {
            return state.approveOpen
        },
        [orderType.getters.CONFIRM_OPEN](state) {
            return state.confirmOpen
        },
        [orderType.getters.ORDER_BY_ID]: (state) => (id) => {
            const isSuborder = id.toString().includes('-')
            const prop = isSuborder ? 'suborders' : 'newOrdersList'
            return state[prop].find((order) => order.id === id)
        },
        ...loaderGetters,
    },
    mutations: {
        [orderType.mutations.SET_ACTIVE_ORDER](state, payload) {
            state.activeOrder = payload
        },
        [orderType.mutations.SET_NEW_ORDERS_LIST](state, payload) {
            state.newOrdersList = payload
        },
        [orderType.mutations.SET_HISTORY_ORDERS_LIST](state, payload) {
            state.historyOrdersList = payload
        },
        [orderType.mutations.SET_INCOMING_CONFIRM_STATE](state, payload) {
            state.approveOpen = payload
        },
        [orderType.mutations.SET_CONFIRM_STATE](state, payload) {
            state.confirmOpen = payload
        },
        [orderType.mutations.UPDATE_NEW_ORDERS_LIST](state, payload) {
            if (!payload) return
            const isSuborder = payload.id?.toString().includes('-')
            if (!(isSuborder || isMultiCutoffBaseOrder(payload))) {
                const index = state.newOrdersList.findIndex(
                    (order) => order.id === payload.id
                )
                if (index > -1) Vue.set(state.newOrdersList, index, payload)
                else state.newOrdersList.push(payload)
            } else if (isSuborder) {
                const index = state.suborder.findIndex(
                    (order) => order.id === payload.id
                )
                if (index > -1) Vue.set(state.suborder, index, payload)
            }
        },
        [orderType.mutations.REMOVE_ORDER_FROM_NEW_ORDERS_LIST](
            state,
            payload
        ) {
            const isSuborder = payload.id.toString().includes('-')
            if (!(isSuborder || isMultiCutoffBaseOrder(payload))) {
                const index = state.newOrdersList.findIndex(
                    (order) => order.id === payload.id
                )
                if (index > -1) state.newOrdersList.splice(index, 1)
            } else {
                const index = state.suborders.findIndex(
                    (order) => order.id === payload.id
                )
                if (index > -1) state.suborders.splice(index, 1)
            }
        },

        [orderType.mutations.SET_ACTIVE_ORDERS_LIST](state, payload) {
            state.ordersList = payload
        },
        [orderType.mutations.UPDATE_ACTIVE_ORDERS_LIST](state, payload) {
            const isSuborder = payload.id.toString().includes('-')
            if (!(isSuborder || isMultiCutoffBaseOrder(payload))) {
                let index = state.ordersList.findIndex(
                    (order) => order.identifier === payload.identifier
                )
                if (index > -1) {
                    if (payload.isActiveForRestaurant)
                        Vue.set(state.ordersList, index, payload)
                    else {
                        state.ordersList.splice(index, 1)
                        if (state.activeOrder?.id === payload.id) {
                            if (state.ordersList[0]) {
                                router.replace({
                                    params: { id: state.ordersList[0].id },
                                })
                            } else {
                                state.activeOrder = null
                            }
                        }
                    }
                } else {
                    if (payload.isActiveForRestaurant)
                        state.ordersList.push(payload)
                }
            }
        },
        [orderType.mutations.UPDATE_ACTIVE_ORDER](state, payload) {
            if (state.activeOrder?.id === payload.id)
                state.activeOrder = { ...state.activeOrder, ...payload }
        },
        [orderType.mutations.SET_LOADING](state, { loader, loading }) {
            state[loader] = loading
        },
        [orderType.mutations.ADD_SUBORDERS](state, payload) {
            payload.forEach((item) => {
                if (isMultiCutoffSuborder(item)) {
                    const idx = state.suborders.findIndex(
                        (suborder) => suborder.id === item.id
                    )
                    if (idx > -1) {
                        Vue.set(state.suborders, idx, {
                            ...state.suborders[idx],
                            ...item,
                        })
                    } else {
                        state.suborders.push(item)
                    }
                }
            })
        },
        [orderType.mutations.ADD_BASE_ORDER](state, payload) {
            if (isMultiCutoffBaseOrder(payload)) {
                const idx = state.baseOrders.findIndex(
                    (item) => item.id === payload.id
                )
                if (idx > -1) {
                    Vue.set(state.baseOrders, idx, {
                        ...state.baseOrders[idx],
                        ...payload,
                    })
                } else {
                    state.baseOrders.push(payload)
                }
            }
        },
        [orderType.mutations.SET_NEXT_CUTOFF](state, payload) {
            if (state.nextCutoff.timer) clearTimeout(state.nextCutoff.timer)
            if (
                !payload ||
                !payload.date ||
                !payload.orderId ||
                !payload.timer
            ) {
                state.nextCutoff = {
                    timer: null,
                    date: null,
                    orderId: null,
                }
            } else {
                state.nextCutoff = {
                    timer: payload.timer,
                    date: payload.date,
                    orderId: payload.orderId,
                }
            }
        },
    },
    actions: {
        [orderType.actions.SET_HISTORY_ORDERS_LIST]({ commit }, payload) {
            commit(orderType.mutations.SET_HISTORY_ORDERS_LIST, payload)
        },
        async [orderType.actions.GET_ORDER](
            { dispatch, commit, getters },
            [id, mutation, setLoader = true]
        ) {
            if (!id) return Promise.reject('No id: ${id}')
            const loader = orderType.loaders.GET_ORDER_LOADING
            if (setLoader)
                commit(orderType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: id,
                })
            try {
                const isSuborder = id.toString().includes('-')
                const orderId = isSuborder ? id.split('-')[0] : id

                let result = await OrdersApiClient.get(orderId)
                if (isCanteenOrder(result)) {
                    const restaurantId = getId(result.restaurant)
                    const managedRestaurant = getters[
                        restaurantType.getters.MANAGED_RESTAURANT
                    ](restaurantId)

                    if (
                        managedRestaurant?.displayCanteenSubOrders &&
                        !result.userProfile
                    ) {
                        dispatch(orderType.actions.GET_NEW_ORDERS)
                        return Promise.resolve(null)
                    }
                }

                if (isSuborder || isMultiCutoffBaseOrder(result)) {
                    commit(orderType.mutations.ADD_BASE_ORDER, result)
                    dispatch(orderType.actions.UPDATE_CUTOFF_TIMER)

                    const suborders = subordersPerCutoff(result)
                    commit(orderType.mutations.ADD_SUBORDERS, suborders)
                    result = suborders.find((item) => item.id === id)
                }
                if (mutation) commit(mutation, result)
                else {
                    commit(orderType.mutations.SET_ACTIVE_ORDER, result)
                }

                commit(orderType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
                return Promise.resolve(result)
            } catch (e) {
                commit(orderType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
                return Promise.reject(e)
            }
        },

        async [orderType.actions.GET_ORDERS](
            { dispatch, getters },
            orderIndex = 0
        ) {
            await Promise.all([
                dispatch(orderType.actions.GET_NEW_ORDERS),
                dispatch(orderType.actions.GET_ACTIVE_ORDERS),
            ])
            dispatch(orderType.actions.UPDATE_CUTOFF_TIMER)

            if (getters[orderType.getters.NEW_ORDERS_LIST]?.length) {
                dispatch(orderType.actions.GET_ORDER, [
                    getters[orderType.getters.NEW_ORDERS_LIST][orderIndex].id,
                    orderType.mutations.UPDATE_NEW_ORDERS_LIST,
                ])
            }

            return getters[orderType.getters.NEW_ORDERS_LIST]
        },

        async [orderType.actions.GET_ORDERS_EVENT](
            { dispatch, getters },
            newOrderEvent
        ) {
            newOrderEvent.forEach((orderId) => {
                if (
                    !getters[orderType.getters.NEW_ORDERS_LIST].find(
                        (order) => orderId === order.id
                    )
                ) {
                    dispatch(orderType.actions.GET_ORDER, [
                        orderId,
                        orderType.mutations.UPDATE_NEW_ORDERS_LIST,
                        false,
                    ])
                }
            })
        },

        async [orderType.actions.GET_NEW_ORDERS]({ commit }) {
            if (!blockNewOrders) {
                blockNewOrders = true
                const loader = orderType.loaders.GET_NEW_ORDERS_LOADING
                commit(orderType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: true,
                })
                try {
                    const result = await OrdersApiClient.list({
                        status: 'new',
                        itemsPerPage: 100,
                    })
                    // remove base /w suborders from list
                    const list = result['hydra:member'].reduce(
                        (list, order) => {
                            if (isMultiCutoffBaseOrder(order)) {
                                commit(
                                    orderType.mutations.ADD_SUBORDERS,
                                    subordersPerCutoff(order)
                                )
                                commit(
                                    orderType.mutations.ADD_BASE_ORDER,
                                    order
                                )
                            } else {
                                list.push(order)
                            }
                            return list
                        },
                        []
                    )

                    commit(orderType.mutations.SET_NEW_ORDERS_LIST, list)
                    commit(orderType.mutations.SET_LOADING, {
                        loader: loader,
                        loading: false,
                    })
                    blockNewOrders = false
                    return Promise.resolve(list)
                } catch (e) {
                    commit(orderType.mutations.SET_LOADING, {
                        loader: loader,
                        loading: false,
                    })
                    blockNewOrders = false
                    return Promise.reject(e)
                }
            }
        },

        async [orderType.actions.GET_ACTIVE_ORDERS]({ commit, state }) {
            if (!blockApproveOrders) {
                blockApproveOrders = true
                const loader = orderType.loaders.GET_ACTIVE_ORDERS_LOADING
                commit(orderType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: true,
                })
                try {
                    const result = await OrdersApiClient.list({
                        activeOrders: true,
                        itemsPerPage: 100,
                    })
                    // remove base /w suborders from list
                    const list = result['hydra:member'].reduce(
                        (list, order) => {
                            if (isMultiCutoffBaseOrder(order)) {
                                commit(
                                    orderType.mutations.ADD_SUBORDERS,
                                    subordersPerCutoff(order)
                                )
                                commit(
                                    orderType.mutations.ADD_BASE_ORDER,
                                    order
                                )
                            } else {
                                list.push(order)
                            }
                            return list
                        },
                        []
                    )
                    commit(orderType.mutations.SET_ACTIVE_ORDERS_LIST, list)

                    const activeOrderIsNotInList =
                        (state.activeOrder &&
                            !list.find(
                                (item) => item.id === state.activeOrder?.id
                            )) ||
                        !list.length
                    if (activeOrderIsNotInList) {
                        commit(orderType.mutations.SET_ACTIVE_ORDER, null)
                    }

                    commit(orderType.mutations.SET_LOADING, {
                        loader: loader,
                        loading: false,
                    })
                    blockApproveOrders = false
                    return Promise.resolve(list)
                } catch (e) {
                    commit(orderType.mutations.SET_LOADING, {
                        loader: loader,
                        loading: false,
                    })
                    blockApproveOrders = false
                    return Promise.reject(e)
                }
            }
        },
        async [orderType.actions.APPROVE_ORDER](
            { commit, getters, dispatch },
            [id, time]
        ) {
            const loader = orderType.loaders.APPROVE_ORDER_LOADING
            commit(orderType.mutations.SET_LOADING, {
                loader: loader,
                loading: true,
            })
            try {
                let order = getters[orderType.getters.ORDER_BY_ID](id)
                let result = true
                if (order) {
                    if (order?.status === 'new') {
                        const orderId = isMultiCutoffSuborder(order)
                            ? order.baseId
                            : order.id

                        result = await OrdersApiClient.approve(orderId, {
                            delivery: {
                                preparation_time: time,
                            },
                        })

                        if (result.already_approved)
                            Notifications.warning(
                                i18n.t('labels.attention'),
                                i18n.t('orders.already_approved')
                            )
                    }

                    if (isMultiCutoffSuborder(order)) {
                        await RestaurantsApiClient.sendCanteenNotiConfirm(
                            getId(order.restaurant),
                            order.cloudcanteen?.identifier ||
                                order.residentialcanteen?.identifier,
                            order.canteenSuborderForCutoff
                        )

                        dispatch(orderType.actions.GET_ORDER, [
                            order.baseId,
                            false,
                        ])
                    } else if (isCanteenOrder(order)) {
                        const orderRestaurant = getters[
                            restaurantType.getters.MANAGED_RESTAURANT
                        ](getId(order.restaurant))

                        if (!orderRestaurant?.displayCanteenSubOrders) {
                            let cutoff = dayjs(
                                order.cloudcanteen?.cutoffTime ||
                                    order.residentialcanteen?.cutoffTime
                            ).format('YYYY-MM-DD HH:mm:ss')

                            await RestaurantsApiClient.sendCanteenNotiConfirm(
                                getId(order.restaurant),
                                order.cloudcanteen?.identifier ||
                                    order.residentialcanteen?.identifier,
                                cutoff
                            )
                        }
                    }
                    commit(
                        orderType.mutations.REMOVE_ORDER_FROM_NEW_ORDERS_LIST,
                        order
                    )
                    await dispatch(orderType.actions.GET_ACTIVE_ORDERS)
                    dispatch(orderType.actions.UPDATE_CUTOFF_TIMER)
                }
                return Promise.resolve(result)
            } catch (e) {
                return Promise.reject(e)
            } finally {
                commit(orderType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
            }
        },
        async [orderType.actions.CONFIRM_ORDER]({ commit }, id) {
            const loader = orderType.loaders.CONFIRM_ORDER_LOADING
            commit(orderType.mutations.SET_LOADING, {
                loader: loader,
                loading: true,
            })
            try {
                const result = await OrdersApiClient.confirm(id)

                commit(orderType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
                return Promise.resolve(result)
            } catch (e) {
                commit(orderType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
                return Promise.reject(e)
            }
        },

        [orderType.actions.UPDATE_NEW_ORDERS_LIST]({ commit }, data) {
            commit(orderType.mutations.UPDATE_NEW_ORDERS_LIST, data)
        },

        [orderType.actions.UPDATE_CUTOFF_TIMER]({ dispatch, commit, state }) {
            const nextCutoff = nextUpcomingCutoffOrder(state.baseOrders)
            if (nextCutoff) {
                const timer = setTimeout(() => {
                    dispatch(orderType.actions.GET_ORDER, [
                        nextCutoff.orderId,
                        false,
                    ])
                }, dayjs(nextCutoff.date).add(20, 's').diff())

                commit(orderType.mutations.SET_NEXT_CUTOFF, {
                    timer,
                    date: nextCutoff.date,
                    orderId: nextCutoff.orderId,
                })
            } else {
                commit(orderType.mutations.SET_NEXT_CUTOFF, null)
            }
        },
    },
}
