import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import SelectApiClientType from '@/api/SelectApiClientType'
import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'
import Store from '@/store/store'
import routeInfoType from '@/store/type/routeInfoType'

class CompaniesApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/companies'
    }

    get(id) {
        return super.get(id).then((data) => {
            Store.commit(routeInfoType.mutations.SET_COMPANY, {
                id: data.id,
                value: {
                    ...data,
                },
            })
            return data
        })
    }

    getRelatedSelectApi() {
        return SelectApiClientType.COMPANY
    }

    clone(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'clone'))
        )
    }
}

export default new CompaniesApiClient()
