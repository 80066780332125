class ErrorHandler {
    error(exception) {
        console.error(exception)
    }
}

const errorHandler = new ErrorHandler()

export default errorHandler

export function ErrorHandlerRegister(Vue) {
    Vue.config.errorHandler = (err) => {
        errorHandler.error(err)
    }

    window.onerror = function (message, source, lineno, colno, error) {
        errorHandler.error({ message, source, lineno, colno, error })
    }
}
