<template>
    <div v-if="selectedIds.length > 0" class="d-inline-block mr-2">
        <table-batch-action
            icon="delete"
            :tooltip="$t('actions.delete_selected_items')"
            color="error"
            approve-color="error"
            confirm-dialog
            :selected-ids="selectedIds"
            :confirm-text="
                $t('texts.count_items_will_be_deleted', {
                    count: selectedIds.length,
                })
            "
            :strong-confirm="strong"
            @execute="deleteSelectedIds"
        ></table-batch-action>

        <modal-dialog
            v-model="dialog"
            max-width="80%"
            :title="$t('batch_delete.title')"
            cancel-button
            @cancel="onCancel"
            @ok="onClose"
        >
            <div class="row">
                <div class="col-md-12 col-lg-8 offset-lg-2 text-center">
                    {{ deleted.length }} / {{ selectedIds.length }}
                    <v-progress-linear
                        v-model="progress"
                        color="error"
                        height="10"
                    ></v-progress-linear>
                    <div v-if="failed.length > 0">
                        {{ $t('batch_delete.cant_delete_ids') }}<br />
                        <v-chip
                            v-for="entityId in failed"
                            :key="entityId"
                            color="brand"
                            text-color="white"
                            >{{ entityId }}</v-chip
                        >
                    </div>
                </div>
            </div>
        </modal-dialog>
    </div>
</template>

<script>
import RestApiCollection from '@/api/RestApiCollection'
import TableBatchAction from '@/components/table/TableBatchAction'
import ModalDialog from '@/components/dialog/ModalDialog'

export default {
    components: {
        TableBatchAction,
        ModalDialog,
    },
    props: {
        selectedIds: {
            type: Array,
            required: true,
        },
        api: {
            type: String,
            required: true,
        },
        strong: Boolean,
    },
    data() {
        return {
            dialog: false,
            deleted: [],
            failed: [],
            close: false,
        }
    },
    computed: {
        confirmText() {
            return this.selectedIds.length + ' items will be deleted'
        },
        progress() {
            return Math.round(
                100 * (this.deleted.length / this.selectedIds.length)
            )
        },
    },
    methods: {
        async deleteSelectedIds(selectedIds) {
            this.dialog = true
            this.deleted = []
            this.failed = []
            this.close = false

            for (const entityId of selectedIds) {
                if (this.dialog === false) {
                    this.$emit('canceled', {
                        deleted: this.deleted,
                        failed: this.failed,
                    })
                    return
                }

                try {
                    await RestApiCollection.get(this.api).delete(entityId)

                    this.deleted.push(entityId)
                } catch (e) {
                    this.failed.push(entityId)
                }
            }

            if (this.failed.length === 0) {
                this.onClose()
            } else {
                this.close = true
            }
        },
        onClose() {
            this.dialog = false
            if (this.failed.length > 0) {
                this.$emit('finishedWithErrors', {
                    deleted: this.deleted,
                    failed: this.failed,
                })
            } else {
                this.$emit('finished', {
                    deleted: this.deleted,
                    failed: this.failed,
                })
            }
        },
        onCancel() {
            this.dialog = false
        },
    },
}
</script>
