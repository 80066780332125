import EditButton from '@/components/table/buttons/EditButton'
import DeleteAction from '@/components/table/actions/DeleteAction'
import CancelDeliveryAction from '@/components/table/actions/CancelDeliveryAction'
import Notification from '@/services/Notification/Notification'
import routeType from '@/router/routeType'
import DeleteBatchAction from '@/components/table/batchActions/DeleteBatchAction'
import RestApiType from '@/api/RestApiType'
import DTable from '@/components/table/DTable'
import TableContainer from '@/components/table/TableContainer'
import TableFilters from '@/components/filter/TableFilters'

// @TODO waiting to be annihilated
import TableFilter from '@/components/filter/TableFilter'
import cloneDeep from 'lodash/cloneDeep'

export default {
    components: {
        TableFilter,
        EditButton,
        DeleteAction,
        DeleteBatchAction,
        DTable,
        TableContainer,
        TableFilters,
        CancelDeliveryAction,
    },
    props: {
        selectedFilters: Object,
        selectedItems: Array,
    },
    data() {
        return {
            // tableName: '',
            // fields: [],
            selectedIds: [],
            routeType: routeType,
            restApiType: RestApiType,
        }
    },
    methods: {
        onRowSelected(selectedIds) {
            this.selectedIds = selectedIds
            this.$emit('update:selectedItems', selectedIds)
        },
        onEntityDeleted(entityName) {
            Notification.entityDeleted(this.tableName, entityName)
            this.updateTableItems()
        },
        onEntityDeleteFailed(entityName) {
            Notification.deleteError(entityName)
            this.updateTableItems()
        },
        onBatchDeleteFinishedWithErrors(result) {
            Notification.batchDeleteFinishedWithErrors(result)
            this.updateTableItems()
        },
        onBatchDeleteFinished(result) {
            Notification.batchDeleteFinished(result)
            this.updateTableItems()
        },
        onBatchDeleteCanceled(result) {
            Notification.batchDeleteCanceled(result)
            this.updateTableItems()
        },
        updateTableItems() {
            this.$refs.iterator.updateItems(true)
        },
        updateFilters(filters) {
            filters = cloneDeep(filters)
            delete filters.itemsPerPage
            delete filters.page
            delete filters.order
            this.$emit('update:selectedFilters', {
                ...this.fixedFilters,
                ...filters,
            })
        },
    },
}
