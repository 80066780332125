<script>
import filter from './filter'
export default {
    mixins: [filter],
}
</script>
<template lang="pug">
    v-chip(
        :input-value="active"
        :class="{ 'border-g-e8 bg-white': !active }"
        @click="setValue()"
        :disabled="blocked"
        filter
        )
        | {{ $t(field.label) }}
</template>
