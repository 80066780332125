import cloneDeep from 'lodash/cloneDeep'

class PromoBannerFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        if (
            entity.bannerImageMedia &&
            typeof entity.bannerImageMedia === 'object'
        ) {
            entity.bannerImageMedia = entity.bannerImageMedia['@id']
        }

        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)

        return values
    }
}

export default new PromoBannerFormMapper()
