import authType from '@/store/type/authType'
import store from '@/store/store'
import configType from '@/store/type/configType'
import router from '@/router/router'

let proxyReady
let loggedIn

export function handleSetJWTTokenEvent() {
    window.addEventListener('message', function (event) {
        if (
            event.data.event === 'SET_REFRESH_TOKEN' &&
            !store.getters[authType.getters.IS_LOGGED_IN]
        ) {
            if (!('refreshToken' in event.data)) {
                throw new Error('Missing refresh token')
            }

            store.dispatch(configType.actions.SET_INLINED)

            store
                .dispatch(
                    authType.actions.LOGIN_WITH_REFRESH_TOKEN,
                    event.data.refreshToken
                )
                .then(() => {
                    loggedIn = setTimeout(() => {
                        clearTimeout(loggedIn)
                        window.parent.postMessage({ event: 'LOGGED_IN' }, '*')
                    }, 200)

                    if (router.currentRoute.name === 'login') {
                        router.back()
                    }
                })
                .catch(() => {
                    // eslint-disable-next-line
                    console.log('authentication error')
                })
        }
    })
}

export function fireReadyEvent() {
    router.afterEach(() => {
        if (!store.getters[authType.getters.IS_LOGGED_IN]) {
            proxyReady = setTimeout(() => {
                clearTimeout(proxyReady)
                window.parent.postMessage({ event: 'PROXY_READY' }, '*')
            }, 200)
        } else {
            loggedIn = setTimeout(() => {
                clearTimeout(loggedIn)
                window.parent.postMessage({ event: 'LOGGED_IN' }, '*')
            }, 200)
        }
    })
}
