<script>
export default {
    props: {
        order: null,
    },
    computed: {
        discount() {
            return (
                (this.order?.priceCouponDiscount
                    ? parseInt(this.order?.priceCouponDiscount)
                    : 0) +
                this.order?.userDiscount +
                this.order?.firmDiscount
            )
        },
    },
}
</script>

<template lang="pug">
.discount(v-if="order && discount > 0")
    v-row
        v-col(align-self="baseline" offset="1")
            .font-weight-bold {{ $t('labels.discount') }}
        v-col.text-right(cols="2" align-self="baseline") {{ discount | currency }}

</template>

<style lang="scss">
.delivery-fee {
    border-top: 1px solid var(--v-g-e8-base);
}
</style>
