<script>
import Breadcrumbs from '@/components/layout/components/Breadcrumbs'
export default {
    components: { Breadcrumbs },
    props: {
        value: Boolean,
        absolute: Boolean,
    },
    data() {
        return {
            hasTitle: false,
            hasBreadcrumbs: false,
        }
    },
    computed: {
        hideNavBar() {
            return this.$vuetify.breakpoint.mdAndUp
        },
        maxHeight() {
            return this.$vuetify.breakpoint.mdAndUp ? 100 : '100%'
        },
        leftOffset() {
            return this.$vuetify.breakpoint.mdAndUp ? 0 : '36px'
        },
    },
    methods: {
        toggleDrawer() {
            this.$emit('input', !this.value)
        },
        setTitle(val) {
            this.hasTitle = val
        },
    },
}
</script>

<template lang="pug">
v-app-bar.px-md-2.pt-2(:height="hasBreadcrumbs ? '30' : '1'" :extension-height="hasBreadcrumbs ? '50' : '70'" :absolute='absolute' :max-height="maxHeight" app flat dense)
    v-app-bar-nav-icon.z99(v-if="!hideNavBar" @click.stop='toggleDrawer' :style="hasBreadcrumbs ? 'margin-top: 15px;' : 'margin-top: 45px; z-index: 999'")
    breadcrumbs(v-model="hasBreadcrumbs")
    portal-target.w-100.flex-shrink-0.d-flex.justify-space-between.align-center.header-title(name='header' @change="setTitle" multiple :style="hasBreadcrumbs ? 'margin-top: -26px; margin-left:'+leftOffset : 'margin-top: 0px; margin-left:'+leftOffset" slot="extension")
</template>

<style lang="scss">
.header-title {
    //margin-top: -26px;
    line-height: 1rem;
}
.z99 {
    z-index: 99;
}
.v-toolbar__content {
    z-index: 99;
}
</style>
