import cloneDeep from 'lodash/cloneDeep'
import promotionType from '@/enum/promotionType'

class PromotionsFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        entity.datePeriod = entity.dateFrom != null
        entity.timePeriod = entity.timeFrom != null
        entity.selectedRestaurantsOnly = entity.restaurants.length > 0

        if (entity.promotionType === promotionType.user) {
            entity.allowances = []
        }

        if (entity.promotionType === promotionType.company) {
            entity.users = []
            entity.allowances = []
        }

        if (entity.promotionType === promotionType.allowance) {
            entity.users = []
        }

        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)

        if (values.users && values.users.length > 0) {
            const users = []

            values.users.forEach((user) => {
                users.push(user['@id'])
            })

            values.users = users
        }

        if (values.company && values.company['@id']) {
            values.company = values.company['@id']
        }

        if (values.allowances && values.allowances.length > 0) {
            const allowances = []

            values.allowances.forEach((allowance) => {
                allowances.push(allowance['@id'])
            })

            values.allowances = allowances
        }

        if (values.dateFrom) {
            values.dateFrom = values.dateFrom.substr(0, 10)
        }

        if (values.dateTo) {
            values.dateTo = values.dateTo.substr(0, 10)
        }

        if (values.timeTo) {
            values.timeTo = values.timeTo.substr(11, 5)
        }

        if (values.timeFrom) {
            values.timeFrom = values.timeFrom.substr(11, 5)
        }

        return values
    }
}

export default new PromotionsFormMapper()
