import selectapiType from './type/selectapiType'
import SelectApiClient from '@/api/SelectApiClient'

const getKey = (query) => JSON.stringify(query || {})

export default function (apiClientType) {
    return {
        namespaced: true,
        state: () => ({
            values: {},
            loaded: {},
            loader: {},
        }),
        getters: {
            [selectapiType.getters.VALUES]: (state) => (query) =>
                state.values[getKey(query)] || [],
        },
        mutations: {
            [selectapiType.mutations.VALUES]: (state, payload) => {
                const key = getKey(payload?.query)
                state.values[key] = payload?.value || []

                if (state.values[key]?.length) {
                    state.loaded[key] = true
                }
            },
            [selectapiType.mutations.LOADER]: (state, payload) => {
                const key = getKey(payload?.query)
                state.loader[key] = payload?.value || null
            },
            [selectapiType.mutations.INVALIDATE]: (state) => {
                const keys = Object.keys(state.loaded)
                for (let key of keys) {
                    state.loaded[key] = false
                }
            },
        },
        actions: {
            async [selectapiType.actions.LOAD](
                { state, getters, commit },
                payload
            ) {
                const query = payload?.query || {}
                const key = getKey(query)

                if (state.loaded[key]) {
                    return getters[selectapiType.getters.VALUES](query)
                } else {
                    return new Promise((resolve, reject) => {
                        const fetchPromise =
                            state.loader[key] ||
                            SelectApiClient.getByEntityName(
                                apiClientType,
                                query
                            )

                        if (!state.loader[key]) {
                            commit(selectapiType.mutations.LOADER, {
                                value: fetchPromise,
                                query,
                            })
                        }

                        fetchPromise
                            .then((data) => {
                                commit(selectapiType.mutations.VALUES, {
                                    value: data,
                                    query,
                                })

                                resolve(data)

                                commit(selectapiType.mutations.LOADER, {
                                    value: null,
                                    query,
                                })
                            })
                            .catch(() => {
                                reject()
                            })
                    })
                }
            },
            [selectapiType.actions.REFRESH]({ commit }) {
                commit(selectapiType.mutations.INVALIDATE)
            },
        },
    }
}
