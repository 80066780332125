<template>
    <div>
        <v-tooltip v-for="(msg, index) in error" :key="`error-${index}`" bottom>
            <template v-slot:activator="{ on }">
                <v-chip
                    class="rounded px-2 py-2 mb-1 white--text w-full"
                    color="brandalt"
                    small
                    v-on="on"
                >
                    {{ $t('labels.deliverect_error') }}
                </v-chip>
            </template>
            <span>{{ msg }}</span>
        </v-tooltip>

        <v-tooltip
            v-for="(msg, index) in cancel"
            :key="`cancel-${index}`"
            bottom
        >
            <template v-slot:activator="{ on }">
                <v-chip
                    class="rounded px-2 py-2 mb-1 white--text w-full"
                    color="brandalt"
                    small
                    v-on="on"
                >
                    {{ $t('labels.deliverect_cancel') }}
                </v-chip>
            </template>
            <span>{{ msg }}</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        error() {
            return this.fieldArr.filter((el) => !el.startsWith('_'))
        },
        cancel() {
            return this.fieldArr
                .filter((el) => el.startsWith('_cancel_'))
                .map((el) => el.replace('_cancel_ ', ''))
        },
        fieldArr() {
            if (!this.item.deliverectError) {
                return []
            }

            return this.item.deliverectError.at(0) === '['
                ? JSON.parse(this.item.deliverectError)
                : [this.item.deliverectError]
        },
    },
}
</script>
