import configType from './type/configType'
import ConfigApiClient from '@/api/ConfigApiClient'
import { loadLocalization } from '@/plugins/i18n'
import Cookies from 'js-cookie'

export default {
    state: {
        inlined: false,
        currencyCode: '',
        currencyLocale: '',
        configIsLoaded: false,
        translationsAreLoaded: false,
        locales: [],
        currentLocale: Cookies.get('ordit.locale')
            ? Cookies.get('ordit.locale')
            : 'en',
        defaultLocale: 'en',
        localeConfigs: [],
        googleMapsApiKey: null,
        googleMapsDefaultCenter: null,
        country: null,
        latestTermsVersion: null,
        latestPrivacyVersion: null,
        timer: null,
        now: null,
    },
    getters: {
        [configType.getters.INLINED](state) {
            return state.inlined
        },
        [configType.getters.CURRENCY_CODE](state) {
            return state.currencyCode
        },
        [configType.getters.CURRENCY_LOCALE](state) {
            return state.currencyLocale
        },
        [configType.getters.CONFIG_IS_LOADED](state) {
            return state.configIsLoaded
        },
        [configType.getters.TRANSLATIONS_ARE_LOADED](state) {
            return state.translationsAreLoaded
        },
        [configType.getters.LOCALES](state) {
            return state.locales
        },
        [configType.getters.CURRENT_LOCALE](state) {
            return state.currentLocale
        },
        [configType.getters.DEFAULT_LOCALE](state) {
            return state.defaultLocale
        },
        [configType.getters.LOCALE_CONFIGS](state) {
            return state.localeConfigs
        },
        [configType.getters.GOOGLE_MAPS_API_KEY](state) {
            return state.googleMapsApiKey
        },
        [configType.getters.GOOGLE_MAPS_DEFAULT_CENTER](state) {
            return state.googleMapsDefaultCenter
        },
        [configType.getters.COUNTRY](state) {
            return state.country
        },
        [configType.getters.LATEST_TERMS_VERSION](state) {
            return state.latestTermsVersion
        },
        [configType.getters.LATEST_PRIVACY_VERSION](state) {
            return state.latestPrivacyVersion
        },
        [configType.getters.NOW](state) {
            return state.now
        },
    },
    mutations: {
        [configType.mutations.INLINED](state, isInlined) {
            state.inlined = isInlined
        },
        [configType.mutations.CURRENCY_CODE](state, currencyCode) {
            state.currencyCode = currencyCode
        },
        [configType.mutations.CURRENCY_LOCALE](state, currencyLocale) {
            state.currencyLocale = currencyLocale
        },
        [configType.mutations.CONFIG_IS_LOADED](state, configIsLoaded) {
            state.configIsLoaded = configIsLoaded
        },
        [configType.mutations.TRANSLATIONS_ARE_LOADED](state, transAreLoaded) {
            state.translationsAreLoaded = transAreLoaded
        },
        [configType.mutations.LOCALES](state, locales) {
            state.locales = locales
        },
        [configType.mutations.CURRENT_LOCALE](state, currentLocale) {
            if (state.configIsLoaded) {
                Cookies.set('ordit.locale', currentLocale)
                state.currentLocale = currentLocale
            } else {
                if (!Cookies.get('ordit.locale'))
                    state.currentLocale = currentLocale
            }
        },
        [configType.mutations.DEFAULT_LOCALE](state, defaultLocale) {
            state.defaultLocale = defaultLocale
        },
        [configType.mutations.LOCALE_CONFIGS](state, localConfigs) {
            state.localeConfigs = localConfigs
        },
        [configType.mutations.GOOGLE_MAPS_API_KEY](state, googleMapsApiKey) {
            state.googleMapsApiKey = googleMapsApiKey
        },
        [configType.mutations.GOOGLE_MAPS_DEFAULT_CENTER](
            state,
            googleMapsDefaultCenter
        ) {
            state.googleMapsDefaultCenter = googleMapsDefaultCenter
        },
        [configType.mutations.COUNTRY](state, country) {
            state.country = country
        },
        [configType.mutations.LATEST_TERMS_VERSION](state, latestTermsVersion) {
            state.latestTermsVersion = latestTermsVersion
        },
        [configType.mutations.LATEST_PRIVACY_VERSION](
            state,
            latestPrivacyVersion
        ) {
            state.latestPrivacyVersion = latestPrivacyVersion
        },
        [configType.mutations.START_TIMER](state, interval) {
            if (!state.timer) {
                state.now = new Date()
                state.timer = setInterval(() => {
                    state.now = new Date()
                }, interval)
            }
        },
        [configType.mutations.STOP_TIMER](state) {
            if (state.timer) {
                clearInterval(state.timer)
                state.timer = null
                state.now = null
            }
        },
    },
    actions: {
        [configType.actions.START_TIMER]({ commit }, interval = 20000) {
            commit(configType.mutations.START_TIMER, interval)
        },

        [configType.actions.STOP_TIMER]({ commit }) {
            commit(configType.mutations.STOP_TIMER)
        },

        [configType.actions.SET_INLINED]({ commit }) {
            commit(configType.mutations.INLINED, true)
        },

        [configType.actions.LOAD_CONFIGS]({ commit, getters }) {
            if (getters[configType.getters.CONFIG_IS_LOADED]) {
                return Promise.resolve()
            }

            return ConfigApiClient.get().then((config) => {
                commit(configType.mutations.CURRENCY_CODE, config.currencyCode)
                commit(
                    configType.mutations.CURRENCY_LOCALE,
                    config.currencyLocale
                )
                commit(
                    configType.mutations.LOCALES,
                    Object.keys(config.availableLocales)
                )
                commit(
                    configType.mutations.CURRENT_LOCALE,
                    config.defaultLocale
                )
                commit(
                    configType.mutations.DEFAULT_LOCALE,
                    config.defaultLocale
                )
                commit(
                    configType.mutations.LOCALE_CONFIGS,
                    config.availableLocales
                )
                commit(
                    configType.mutations.GOOGLE_MAPS_API_KEY,
                    config.googleMapsApiKey
                )
                commit(
                    configType.mutations.GOOGLE_MAPS_DEFAULT_CENTER,
                    config.googleMapsDefaultCenter
                )
                commit(configType.mutations.COUNTRY, config.country)
                commit(
                    configType.mutations.LATEST_TERMS_VERSION,
                    config.latestTermsVersion
                )
                commit(
                    configType.mutations.LATEST_PRIVACY_VERSION,
                    config.latestPrivacyVersion
                )
                commit(configType.mutations.CONFIG_IS_LOADED, true)
            })
        },

        async [configType.actions.LOAD_TRANSLATIONS]({ commit, getters }) {
            if (getters[configType.getters.TRANSLATIONS_ARE_LOADED]) {
                return Promise.resolve()
            }

            const localeConfigs = getters[configType.getters.LOCALE_CONFIGS]
            await Promise.all(
                Object.keys(localeConfigs).map((lang) =>
                    loadLocalization(lang, localeConfigs[lang].version)
                )
            )
            commit(configType.mutations.TRANSLATIONS_ARE_LOADED, true)
        },

        [configType.actions.INIT]({ dispatch }) {
            if (window.self !== window.top) {
                dispatch(configType.actions.SET_INLINED)
            }

            dispatch(configType.actions.LOAD_CONFIGS).then(() => {
                dispatch(configType.actions.LOAD_TRANSLATIONS)
            })
        },
    },
}
